import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Partner details
const GENERATE_RESULTS = 'GENERATE_RESULTS';
const GENERATE_RESULTS_SUCCESS = 'GENERATE_RESULTS_SUCCESS';
const GENERATE_RESULTS_FAILURE = 'GENERATE_RESULTS_FAILURE';

export const generateResultsADCOutputAction = createAction(GENERATE_RESULTS);

const generateResultsSuccess = createAction(GENERATE_RESULTS_SUCCESS);

const generateResultsFaliure = createAction(GENERATE_RESULTS_FAILURE);
export const generateADCOutputResponseEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_RESULTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_adc}/v0/adcTriplets?${action.payload}`, 'get')).pipe(
    map((res) => generateResultsSuccess(res)),
    catchError((err) => of(generateResultsFaliure(err))),
  )),
);

const generateADCOutputResponseReducer = createReducer(
  {
    data: '',
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateResultsADCOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(generateResultsSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateResultsFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default generateADCOutputResponseReducer;
