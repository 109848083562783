// /* eslint-disable */
import axios from 'axios';
import Rcookie from 'react-cookies';

const downloadXLSFile = async ({
  isZip, jobId, setIsPendingDownload, setIsPendingZipDownload,
}) => {
  if (isZip) {
    setIsPendingZipDownload(true);
  } else {
    setIsPendingDownload(true);
  }
  // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
  const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
  const apiUrl = isZip ? `${process.env.apiUrl_sar}/scoreZipDownload?job_id=${jobId}` : `${process.env.apiUrl_sar}/scoreResultDownload?job_id=${jobId}`;

  try {
    const response = await axios({
      method: 'GET',
      url: apiUrl,
      responseType: 'arraybuffer',
      headers,
    });
    setIsPendingDownload(false);
    setIsPendingZipDownload(false);
    const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    throw Error(error);
  }
};

export default downloadXLSFile;
