import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { NOTIFICATION_CANCEL } from './logic';
import useAppSelector from '../../../utils/redux-hook';

function Notification() {
  const {
    type, message, notificationType, showNotification,
  } = useAppSelector((state) => state.notification);

  useEffect(() => {
    if (type !== NOTIFICATION_CANCEL && showNotification) {
      toast(message, {
        position: 'top-center',
        autoClose: 2000,
        pauseOnHover: false,
        draggable: true,
        newestOnTop: true,
        type: notificationType,
        hideProgressBar: true,
        closeButton: false,
      });
    }
  }, [type]);
  return (
    <ToastContainer />
  );
}

export default Notification;
