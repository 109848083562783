import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get input suggest details
const GET_INPUT_SUGGEST_DR = 'GET_INPUT_SUGGEST_DR';
const GET_INPUT_SUGGEST_SUCCESS_DR = 'GET_INPUT_SUGGEST_SUCCESS_DR';
const GET_INPUT_SUGGEST_FAILURE_DR = 'GET_INPUT_SUGGEST_FAILURE_DR';

export const getInputSuggestDRAction = createAction(GET_INPUT_SUGGEST_DR);

const getInputSuggestDRSuccess = createAction(GET_INPUT_SUGGEST_SUCCESS_DR);

const getInputSuggestDRFailure = createAction(GET_INPUT_SUGGEST_FAILURE_DR);
export const getInputSuggestDREpic = (actions$) => actions$.pipe(
  ofType(GET_INPUT_SUGGEST_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_dr}/inputSuggest?${action.payload}`, 'get')).pipe(
    map((res) => getInputSuggestDRSuccess(res)),
    catchError((err) => of(getInputSuggestDRFailure(err))),
  )),
);

export const getInputSuggestDRReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getInputSuggestDRAction, (state) => {
        state.loading = true;
      })
      .addCase(getInputSuggestDRSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getInputSuggestDRFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
