import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// generate insights
const GENERATE_INSIGHTS = 'GENERATE_INSIGHTS';
const GENERATE_INSIGHTS_SUCCESS = 'GENERATE_INSIGHTS_SUCCESS';
const GENERATE_INSIGHTS_FAILURE = 'GENERATE_INSIGHTS_FAILURE';

export const generateInsightsAction = createAction(GENERATE_INSIGHTS);

const generateInsightsSuccess = createAction(GENERATE_INSIGHTS_SUCCESS);

const generateInsightsFaliure = createAction(GENERATE_INSIGHTS_FAILURE);
export const generateInsightsEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_INSIGHTS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/generateInsight?${action.payload}`, 'POST')).pipe(
    map((res) => generateInsightsSuccess(res)),
    catchError((err) => of(generateInsightsFaliure(err))),
  )),
);

export const generateInsightsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateInsightsAction, (state) => {
        state.loading = true;
      })
      .addCase(generateInsightsSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateInsightsFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// check status details
const CHECK_STATUS = 'CHECK_STATUS';
const CHECK_STATUS_SUCCESS = 'CHECK_STATUS_SUCCESS';
const CHECK_STATUS_FAILURE = 'CHECK_STATUS_FAILURE';
const CHECK_STATUS_RESET = 'CHECK_STATUS_RESET';

export const checkStatusAction = createAction(CHECK_STATUS);
export const checkStatusResetAction = createAction(CHECK_STATUS_RESET);

const checkStatussSuccess = createAction(CHECK_STATUS_SUCCESS);
const checkStatusFailure = createAction(CHECK_STATUS_FAILURE);

export const checkStatusEpic = (actions$) => actions$.pipe(
  ofType(CHECK_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_ctp}/allInsights`, 'get')).pipe(
    map((res) => checkStatussSuccess(res)),
    catchError((err) => of(checkStatusFailure(err))),
  )),
);

export const checkStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(checkStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(checkStatussSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
