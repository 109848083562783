import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_DRUG_DEVELOPMENT_OUTPUT = 'GET_DRUG_DEVELOPMENT_OUTPUT';
const GET_DRUG_DEVELOPMENT_OUTPUT_SUCCESS = 'GET_DRUG_DEVELOPMENT_OUTPUT_SUCCESS';
const GET_DRUG_DEVELOPMENT_OUTPUT_FAILURE = 'GET_DRUG_DEVELOPMENT_OUTPUT_FAILURE';

export const getDrugDevelopmentOutputAction = createAction(GET_DRUG_DEVELOPMENT_OUTPUT);

const getDrugDevelopmentOutputSuccess = createAction(GET_DRUG_DEVELOPMENT_OUTPUT_SUCCESS);

const getDrugDevelopmentOutputFaliure = createAction(GET_DRUG_DEVELOPMENT_OUTPUT_FAILURE);

export const getDrugDevelopmentOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_DRUG_DEVELOPMENT_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ti}/drugDevelopmentFeasibilityData?${action.payload}`, 'get')).pipe(
    map((res) => getDrugDevelopmentOutputSuccess(res)),
    catchError((err) => of(getDrugDevelopmentOutputFaliure(err))),
  )),
);
export const getDrugDevelopmentOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getDrugDevelopmentOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getDrugDevelopmentOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getDrugDevelopmentOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
