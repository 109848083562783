import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get synonyms
const GET_SYNONYMS = 'GET_SYNONYMS';
const GET_SYNONYMS_SUCCESS = 'GET_SYNONYMS_SUCCESS';
const GET_SYNONYMS_FAILURE = 'GET_SYNONYMS_FAILURE';
const GET_SYNONYMS_RESET = 'GET_SYNONYMS_RESET';

export const getSynonymsAction = createAction(GET_SYNONYMS);
export const getSynonymsResetAction = createAction(GET_SYNONYMS_RESET);

const getSynonymsSuccess = createAction(GET_SYNONYMS_SUCCESS);
const getSynonymsFailure = createAction(GET_SYNONYMS_FAILURE);

export const getSynonymsEpic = (actions$) => actions$.pipe(
  ofType(GET_SYNONYMS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/synonyms?${action.payload}`, 'get')).pipe(
    map((res) => getSynonymsSuccess(res)),
    catchError((err) => of(getSynonymsFailure(err))),
  )),
);

export const getSynonymsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getSynonymsAction, (state) => {
        state.loading = true;
      })
      .addCase(getSynonymsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getSynonymsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(getSynonymsResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// check status
const IEP_ADVANCE_SEARCH_CHECK_STATUS = 'IEP_ADVANCE_SEARCH_CHECK_STATUS';
const IEP_ADVANCE_SEARCH_CHECK_STATUS_SUCCESS = 'IEP_ADVANCE_SEARCH_CHECK_STATUS_SUCCESS';
const IEP_ADVANCE_SEARCH_CHECK_STATUS_FAILURE = 'IEP_ADVANCE_SEARCH_CHECK_STATUS_FAILURE';
const IEP_ADVANCE_SEARCH_CHECK_STATUS_RESET = 'IEP_ADVANCE_SEARCH_CHECK_STATUS_RESET';

export const iepAdvanceSearchCheckStatusAction = createAction(IEP_ADVANCE_SEARCH_CHECK_STATUS);
export const iepAdvanceSearchCheckStatusResetAction = createAction(IEP_ADVANCE_SEARCH_CHECK_STATUS_RESET);

const iepAdvanceSearchCheckStatusSuccess = createAction(IEP_ADVANCE_SEARCH_CHECK_STATUS_SUCCESS);
const iepAdvanceSearchCheckStatusFailure = createAction(IEP_ADVANCE_SEARCH_CHECK_STATUS_FAILURE);

export const iepAdvanceSearchCheckStatusEpic = (actions$) => actions$.pipe(
  ofType(IEP_ADVANCE_SEARCH_CHECK_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_iep}/userIndicationList`, 'get')).pipe(
    map((res) => iepAdvanceSearchCheckStatusSuccess(res)),
    catchError((err) => of(iepAdvanceSearchCheckStatusFailure(err))),
  )),
);

export const iepAdvanceSearchCheckStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(iepAdvanceSearchCheckStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(iepAdvanceSearchCheckStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepAdvanceSearchCheckStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepAdvanceSearchCheckStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
