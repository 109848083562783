import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputCard from '../../components/InputCard';

function SelectService({ inputHandler, service }) {
  const [card, setCard] = useState([-1, -1, -1]);
  const services = ['PREDICT_CLINICAL_TRIALS', 'CHECK_TRIAL_FEASIBILITY', 'OPTIMISE_TRIALS'];

  useEffect(() => {
    services.forEach((item, index) => {
      if (service === item) {
        setCard((prev) => {
          const newCard = prev;
          newCard[index] = 1;
          return newCard;
        });
        inputHandler({ card: services[index] });
      }
    });
  }, [service]);

  const handleCard = (index) => {
    setCard((prev) => {
      const newCard = prev.map((item, i) => {
        if (index === i) {
          return 1;
        }
        return 0;
      });
      return newCard;
    });
    inputHandler({ card: services[index] });
  };
  return (
    <div className="service-container">
      <div className="cards-container">
        <div className="cards-container-title">Select an Use Case</div>
        <div className="cards-list">
          <InputCard index={0} heading=" For disease only" cardHandler={handleCard} isSelected={card[0]} colorClass="card-one" img="disease-only" status="Open" />
          <InputCard index={1} heading=" For disease and single drug" cardHandler={handleCard} isSelected={card[1]} colorClass="card-two" img="disease-one" status="Coming soon" />
          <InputCard index={2} heading=" For disease and two drugs" cardHandler={handleCard} isSelected={card[2]} colorClass="card-three" img="disease-two" status="Coming soon" />
        </div>
      </div>
    </div>
  );
}

SelectService.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
};

export default SelectService;
