/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getInputSuggestProtacAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';

const { Search } = Input;

function InputDropdown({
  changeHandler, proteinName, isDisabled, setProteinName,
}) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const getInputSuggestProtacResponse = useAppSelector((reduxState) => reduxState.getInputSuggestProtacResponse || {});

  useEffect(() => {
    if (proteinName !== '') {
      setSearchText(proteinName);
      changeHandler(proteinName);
      setProteinName('');
    }
  }, [proteinName]);

  useEffect(() => {
    if (getInputSuggestProtacResponse.flag) {
      setAutosuggestOptions(getInputSuggestProtacResponse?.data?.suggestions);
    }
  }, [queryString.stringify(getInputSuggestProtacResponse)]);

  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0 && !isDisabled) {
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      const request = {
        input_text: e.target.value.trim(),
      };
      dispatch(getInputSuggestProtacAction(queryString.stringify(request)));
    } else {
      setSearchText('');
      changeHandler('');
      setDisplayDropdown(false);
    }
  };

  const renderAutosuggestOptions = () => {
    const options = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Math.min(10, autosuggestOptions?.length); i += 1) {
      const itm = autosuggestOptions[i];
      options.push(
        <div
          key={crypto.randomUUID()}
          onClick={() => {
            changeHandler(itm.gene_name);
            setSearchText(itm?.gene_name);
            setDisplayDropdown(false);
          }}
        >
          {itm?.gene_name}
        </div>,
      );
    }
    return options;
  };

  const noDataFound = () => <div className="no-data-found">No data found</div>;

  return (
    <div className="searchbar-dashboard-content-search joyride-searchBar">
      <Search placeholder="Enter Target name, Gene name or UniProt ID" value={searchText} allowClear size="large" maxLength="256" onChange={onChangeInput} />
      <div className="search-dropdown-result-content">
        {displayDropdown ? (
          <div className="search-dropdown-result">
            <div className="search-dropdown-result-list">
              <Loader loading={getInputSuggestProtacResponse.loading}>{renderAutosuggestOptions().length === 0 ? noDataFound() : renderAutosuggestOptions()}</Loader>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

InputDropdown.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  proteinName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setProteinName: PropTypes.func.isRequired,
};

export default InputDropdown;
