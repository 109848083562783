const optionsTags = [
  {
    value: 'Meta CTP',
    label: 'ctp',
  },
  {
    value: 'Biomarker',
    label: 'biomarker',
  },
  {
    value: 'ADC',
    label: 'adc',
  },
  {
    value: 'DC',
    label: 'dc',
  },
  {
    value: 'DR',
    label: 'dr',
  },
  {
    value: 'IEP',
    label: 'iep',
  },
  {
    value: 'SAR',
    label: 'sar',
  },
  {
    value: 'Target Id',
    label: 'target-id',
  },
  {
    value: 'Protac',
    label: 'protac',
  },
];

export default optionsTags;
