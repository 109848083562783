import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';
import { drCheckStatusAction, generateIndicationsDRAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import PendingStatusModal from '../../components/PendingStatusModal';
import Loader from '../../../../common/components/Loader';

function DRInputPage() {
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    card: '',
    drug_id: '',
    preferred_name: '',
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const drugName = searchParams.get('drugName');
  const drugId = searchParams.get('drugId');
  const drCheckStatusResponse = useAppSelector((state) => state.drCheckStatusResponse);
  const [isPending, setIsPending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClick = () => {
    const request = {
      drug_type: inputs.card,
      drug_id: inputs.drug_id,
      preferred_name: inputs.preferred_name,
    };
    dispatch(generateIndicationsDRAction(queryString.stringify(request)));
    setIsPending(true);
    setIsSubmitted(true);
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };
  const checkBtnDissabledForAccess = inputs.card === '' || inputs.card === undefined || inputs.drug_id === '' || inputs.drug_id === undefined || inputs.preferred_name === '' || inputs.preferred_name === undefined;

  useEffect(() => {
    if (drCheckStatusResponse.flag && drCheckStatusResponse.data.length > 0) {
      if (drCheckStatusResponse.data[0]?.status === 'PENDING') {
        setIsPending(true);
      } else if (drCheckStatusResponse.data[0]?.status === 'COMPLETED' && drCheckStatusResponse.data[0]?.is_read !== true) {
        navigate(`${terminalsPath.metad3_dr_output}?jobId=${drCheckStatusResponse.data[0]?.job_id}`);
      } else if (drCheckStatusResponse.data[0]?.status === 'REJECTED') {
        setIsPending(false);
      }
    }
  }, [JSON.stringify(drCheckStatusResponse)]);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(drCheckStatusAction());
  }, []);

  useEffect(() => {
    setIsDisabled(checkBtnDissabledForAccess);
  }, [inputs]);

  useEffect(() => {
    let interval = '';
    if (isSubmitted) {
      interval = setInterval(() => {
        dispatch(drCheckStatusAction());
      }, 2000);
    }
    return () => {
      if (interval !== '') {
        clearInterval(interval);
      }
    };
  }, [isSubmitted]);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home dr-header" logo="dr-logo" />
      <Loader loading={drCheckStatusResponse.loading && !isSubmitted} error={drCheckStatusResponse.error}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Unlocking New Possibilities for Existing Drugs</div>
            <div className="main-description">Exploring New Avenues to Transform Existing Medications into Tomorrow Solutions</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} />
          <InputBox inputHandler={getInputs} drugName={drugName !== null ? [drugName, drugId] : ''} isDisabled={inputs.card === '' || inputs.card === undefined} />
          <div className="ct-home-page-footer">
            <button onClick={() => handleClick()} className="generate-in-btn" type="button" disabled={isDisabled}>
              GENERATE INDICATIONS LIST
            </button>
          </div>
        </div>
        <PendingStatusModal open={isPending} />
      </Loader>
    </div>
  );
}

export default DRInputPage;
