import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_TOP_ORGAN_AFFECTED_OUTPUT = 'GET_TOP_ORGAN_AFFECTED_OUTPUT';
const GET_TOP_ORGAN_AFFECTED_OUTPUT_SUCCESS = 'GET_TOP_ORGAN_AFFECTED_OUTPUT_SUCCESS';
const GET_TOP_ORGAN_AFFECTED_OUTPUT_FAILURE = 'GET_TOP_ORGAN_AFFECTED_OUTPUT_FAILURE';

export const getTopOrganAffectedOutputAction = createAction(GET_TOP_ORGAN_AFFECTED_OUTPUT);

const getTopOrganAffectedOutputSuccess = createAction(GET_TOP_ORGAN_AFFECTED_OUTPUT_SUCCESS);

const getTopOrganAffectedOutputFaliure = createAction(GET_TOP_ORGAN_AFFECTED_OUTPUT_FAILURE);

export const getTopOrganAffectedOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_TOP_ORGAN_AFFECTED_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ti}/topOrganAffectedData?${action.payload}`, 'get')).pipe(
    map((res) => getTopOrganAffectedOutputSuccess(res)),
    catchError((err) => of(getTopOrganAffectedOutputFaliure(err))),
  )),
);
export const getTopOrganAffectedOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getTopOrganAffectedOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getTopOrganAffectedOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getTopOrganAffectedOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
