import { center } from '@turf/center';
import L from 'leaflet';

export function getColor(d) {
  return d > 2500 ? '#FF9800' : d > 2000 ? '#E31A1C' : d > 1500 ? '#FD8D3C' : d > 1000 ? '#FEB24C' : d > 500 ? '#FED976' : '#FFEDA0';
}

export function getCenterOfGeoJson(geoJson) {
  return center(geoJson).geometry.coordinates.reverse();
}

export function layersUtils(geoJsonRef, mapRef) {
  function highlightOnClick(e) {
    const layer = e.target;

    layer.setStyle({
      weight: 2,
      color: '#f90303',
      dashArray: '',
      fillOpacity: 0.7,
    });

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  }

  function resetHighlight(e) {
    geoJsonRef.current.leafletElement.resetStyle(e.target);
  }

  function zoomToFeature(e) {
    mapRef.current.leafletElement.fitBounds(e.target.getBounds());
  }

  return { highlightOnClick, resetHighlight, zoomToFeature };
}
