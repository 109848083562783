import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getGraphDataAction } from '../../../pages/TopIndicationsPage/logic';
import BubbleChart from '../../BubbleGraph';
import useAppSelector from '../../../../../utils/redux-hook';

function IndicationsLandscapeView({ jobId }) {
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState([]);
  const iepGraphDataResponse = useAppSelector((state) => state.iepGraphDataResponse);
  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getGraphDataAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (iepGraphDataResponse.flag) {
      setGraphData(iepGraphDataResponse.data.data);
    }
  }, [iepGraphDataResponse.data.data]);
  return (
    <div className="indications-landscape-main">
      <div className="indications-landscape-title-row">
        <div className="indications-landscape-title card-content-ctp-title">Indications Landscape</div>
      </div>
      <div className="indications-landscape-graph">
        <BubbleChart data={graphData} />
      </div>
    </div>
  );
}

IndicationsLandscapeView.propTypes = {
  jobId: PropTypes.string.isRequired,
};
export default IndicationsLandscapeView;
