/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ReferenceLine, Label, ResponsiveContainer } from 'recharts';
import useAppSelector from '../../../../utils/redux-hook';
import { XFilled } from '@ant-design/icons';

const COLORS = ['#3B76EFB2', '#A6A6A6B2', '#A66DD4B2'];

export default function VolcanoPlot() {
  const [count, setCount] = useState({});
  const [data, setData] = useState([]);
  const outputResponse = useAppSelector((state) => state.volcanoGraphBiomarkerOutputResponse);

  useEffect(() => {
    if (outputResponse?.data?.data) {
      setData(outputResponse?.data?.data);
      setCount(outputResponse?.data?.count);
    }
  }, [outputResponse]);

  const scientificNotation = (x) => {
    let str = x.toExponential(2);
    const arr = x.toString().split('e');
    let p = 0;
    if (arr.length > 1) {
      p = parseInt(arr[1]);
      str = parseFloat(arr[0]).toExponential(2);
    }
    for (let i = 0; i < str.length; i++) {
      if (str[i] === 'e') {
        const l = str.substring(i + 1);
        const back = parseInt(l) + p;
        str = `${str.substring(0, i)}E${back.toString()}`;
      }
    }

    if (str[0] === '0') {
      return 0;
    }
    const n = str.length;
    if (n > 2 && str[n - 1] === '0' && str[n - 2] === 'E') {
      return str.substring(0, n - 2);
    }
    return str;
  };

  const customToolTip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="custom-tooltip-content">
            <div className="label-header">
              <div className="gene-name">{`Gene Name:  `}</div>
              <div className="pVal">{`p Value:  `}</div>
              <div className="foldChange">{`log2(Fold Change):  `}</div>
            </div>
            <div className="label-content">
              <div className="gene-name">{`${payload[0].payload['Symbol']}`}</div>
              <div className="pVal">{`${scientificNotation(payload[1].value)}`}</div>
              <div className="foldChange">{`${scientificNotation(payload[0].value)}`}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="volcano-plot" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
      <div className="volcano-plot-labels">
        <div className="volcano-plot-label-box" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="volcano-plot-label">
            <XFilled />
            <div className="volcano-plot-text">{`Down Regulated (${count['downRegulated']})  `}</div>
          </div>
          <div className="volcano-plot-label">
            <XFilled />
            <div className="volcano-plot-text">{`Not Significant (${count['unsigned']})  `}</div>
          </div>
          <div className="volcano-plot-label">
            <XFilled />
            <div className="volcano-plot-text">{`Up Regulated (${count['upRegulated']})  `}</div>
          </div>
        </div>
      </div>
      <div style={{ height: '100%', width: '100%' }}>
        <ResponsiveContainer>
          <ScatterChart
            width={600}
            height={600}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis
              type="number"
              dataKey="x"
              name="fold"
              label={{
                value: `log2(Fold Change)`,
                style: { textAnchor: 'middle' },
                angle: 0,
                fill: '#000000',
                position: 'bottom',
                offset: 0,
              }}
            ></XAxis>
            <YAxis
              type="number"
              dataKey="y"
              name="pval"
              label={{
                value: `-log10(Pvalue)`,
                style: { textAnchor: 'middle' },
                angle: -90,
                fill: '#000000',
                position: 'left',
                offset: 0,
              }}
            />
            <Tooltip cursor={{ strokeDasharray: '3 3' }} content={customToolTip} />
            <Scatter name="Volcano Plot" data={data} fill="#8884d8">
              {data.map((entry, index) => {
                let color = COLORS[1];
                if (entry.y > 1.3 && entry.x < -2) {
                  color = COLORS[0];
                }
                if (entry.y > 1.3 && entry.x > 2) {
                  color = COLORS[2];
                }
                return <Cell key={`cell-${index}`} fill={color} />;
              })}
            </Scatter>
            <ReferenceLine
              y={1.3}
              label={{
                position: 'left',
                value: '1.30',
              }}
              strokeDasharray="5 5"
            />
            <ReferenceLine x={2} strokeDasharray="5 5" />
            <ReferenceLine x={-2} strokeDasharray="5 5" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
