import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import SearchDatasets from '../../container/BiomarkerInputComponents/SearchDatasets';
import StepIndicator from '../../components/StepIndicator';
import SelectPreferredStudies from '../../container/BiomarkerInputComponents/SelectPreferredStudies';
import ViewSamples from '../../container/BiomarkerInputComponents/ViewSamples';
import GroupSamples from '../../container/BiomarkerInputComponents/GroupSamples';
import useAppSelector from '../../../../utils/redux-hook';
import { getFiltersAction } from './logic';
import PendingStatusModal from '../../components/PendingStatusModal';

function BiomarkerInputPage() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [studiesSelected, setStudiesSelected] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState({});
  const [isTryExample, setIsTryExample] = useState(false);
  const [isSemantic, setIsSemantic] = useState(false);
  const [groupA, setGroupA] = useState([]);
  const [groupB, setGroupB] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const getFiltersResponse = useAppSelector((state) => state.getFiltersResponse);
  useEffect(() => {
    const body = {
      filters: {
        organism: [
          {
            operator: 'OR',
            value: 'Homo sapiens',
          },
        ],
      },
    };
    const request = {
      params: queryString.stringify({
        search_query: filtersSelected.indicationName,
      }),
      body,
    };
    dispatch(getFiltersAction(request));
  }, []);
  const tabs = [<SearchDatasets setCurrentIndex={setCurrentIndex} filters={getFiltersResponse?.data} setFiltersSelected={setFiltersSelected} setIsTryExample={setIsTryExample} setIsSemantic={setIsSemantic} setStudiesSelected={setStudiesSelected} setGroupA={setGroupA} setGroupB={setGroupB} />, <SelectPreferredStudies setCurrentIndex={setCurrentIndex} setSelectedStudies={setStudiesSelected} studiesSelected={studiesSelected} filters={getFiltersResponse?.data} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} isSemantic={isSemantic} />, <ViewSamples currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} studiesSelected={studiesSelected} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} isTryExample={isTryExample} groupA={groupA} groupB={groupB} setGroupA={setGroupA} setGroupB={setGroupB} setOpenModal={setOpenModal} />, <GroupSamples setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} studiesSelected={studiesSelected} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} />, <GroupSamples setCurrentIndex={setCurrentIndex} currentIndex={currentIndex} studiesSelected={studiesSelected} />];

  return (
    <div className={`main-page main-page-modules ${currentIndex === 0 ? 'biomarker-main-page' : 'biomarker-main-page biomarker-main-page-process'}`}>
      <Header className="header-home adc-header" logo="biomarker-logo" width={660} />
      <StepIndicator currentIndex={currentIndex} />
      {currentIndex === 0 ? tabs[0] : currentIndex === 1 ? tabs[1] : tabs[2]}
      <PendingStatusModal open={openModal} />
    </div>
  );
}
export default BiomarkerInputPage;
