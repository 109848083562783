import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from 'antd';
import colorObj from './color';
import LazyLoadDiv from '../../../../../../common/components/LazyLoadDiv';

function SamplesListView({
  sampleData, displayCategories, setDisplayCategories, selectedSample, setSelectedSample, setSamplesSelected, samplesSelected, isGrouping, isTryExample, loadMoreSamples, totalCount,
}) {
  const updateSelectedSamplesList = (sampleId) => {
    if (samplesSelected.includes(sampleId)) {
      setSamplesSelected(samplesSelected.filter((item) => item !== sampleId));
    } else {
      setSamplesSelected([...samplesSelected, sampleId]);
    }
  };
  const displaytags = (temp) => (
    <div className="sample-tag-container">
      {temp.length
        && temp?.map((item) => {
          const str = item.toLowerCase();
          return (
            <div key={item} className="sample-tag-item" style={{ marginLeft: '5px', color: (colorObj[str] !== undefined) ? colorObj[str][0] : colorObj.cases[0], backgroundColor: (colorObj[str] !== undefined) ? colorObj[str][1] : colorObj.cases[1] }}>
              {item}
            </div>
          );
        })}
    </div>
  );
  const sampleDetailsColumn = (key1, value1, key2, value2) => (
    <div className="sample-details-column">
      <div className="details-key-value">
        <div className="sample-details-key">{key1}</div>
        <Tooltip title={value1} placement="topLeft">
          <div className="sample-details-value">{value1}</div>
        </Tooltip>
      </div>
      <div className="details-key-value">
        <div className="sample-details-key">{key2}</div>
        <Tooltip title={value2} placement="topLeft">
          <div className="sample-details-value">{value2}</div>
        </Tooltip>
      </div>
    </div>
  );

  /* eslint-disable */
  const parseCharacteristics = (characteristics) => {
    let str = '';
    if (characteristics) {
      for (let i = 0; i < characteristics?.length ?? 0; i++) {
        const item = characteristics[i];
        if (i == characteristics?.length - 1) str += `${item.key} : ${item.value}`;
        else str += `${item.key} : ${item.value} | `;
      }
    }
    return str;
  };
  /* eslint-enable */

  const sampleListItem = (sample1) => (
    <div className={`card-list-item-main${displayCategories ? ' inactive' : ''}`}>
      <div
        key={sample1.accession}
        className={`card-list-item sample-list-item${selectedSample?.accession === sample1.accession && !displayCategories ? ' active' : ''}`}
        onClick={() => {
          setDisplayCategories(false);
          setSelectedSample(sample1);
        }}
        aria-hidden
      >
        <div className="checkbox-row card-list-item-checkbox">
          {isGrouping ? (
            <div role="presentation" onClick={(e) => e.stopPropagation()} onKeyDown={null}>
              <Checkbox
                defaultChecked={samplesSelected?.includes(sample1.accession)}
                onChange={() => {
                  updateSelectedSamplesList(sample1?.accession);
                }}
                disabled={isTryExample}
              >
                {sample1?.accession}
              </Checkbox>
            </div>
          ) : (
            <div className="sample-id">{sample1?.accession}</div>
          )}
          {sample1?.classification_groups?.length === 0 ? null : displaytags(sample1?.classification_groups)}
        </div>
        <div className="sample-title card-list-item-value">{sample1.title}</div>
        <div className="sample-key-value card-list-item-value">
          {sampleDetailsColumn('Samples Type', sample1.sample_type, 'Source Name', sample1.source_name)}
          {sampleDetailsColumn('Organism', sample1.organism, 'Characteristics', parseCharacteristics(sample1.characteristics))}
          {/* {sampleDetailsColumn('Cell Type', sample1.sample_cell_type, 'Cell Subtype', sample1.sample_cell_sub_type)} */}
        </div>
      </div>
    </div>
  );
  const displaySampleList = () => sampleData?.map((item) => sampleListItem(item));

  return <div className={`sample-list-container${displayCategories ? ' parent' : ' child'}`}>{sampleData?.length === 0 ? <div className="no-studies-found">No samples found</div> : <LazyLoadDiv className="sample-list-lazy-loading" id="sample-list" total={totalCount} currentTotal={(sampleData || []).length} height="calc(100vh - 206)" loadMoreRows={loadMoreSamples}>{displaySampleList()}</LazyLoadDiv>}</div>;
}
SamplesListView.propTypes = {
  sampleData: PropTypes.arrayOf.isRequired,
  displayCategories: PropTypes.bool.isRequired,
  setDisplayCategories: PropTypes.func.isRequired,
  selectedSample: PropTypes.objectOf.isRequired,
  setSelectedSample: PropTypes.func.isRequired,
  setSamplesSelected: PropTypes.func.isRequired,
  samplesSelected: PropTypes.arrayOf.isRequired,
  isGrouping: PropTypes.bool.isRequired,
  isTryExample: PropTypes.bool.isRequired,
  loadMoreSamples: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};
export default SamplesListView;
