import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import SvgLoader from '../../../../common/components/SvgLoader';

function InputCard({
  heading, cardHandler, isSelected, index, colorClass, img, status,
}) {
  const content = "Our team is working on this service. We can't wait to unveil its beauty, and we'll update you as soon as it's ready. Stay tuned!";

  const onCardSelect = () => {
    if (status === 'Coming soon') {
      return;
    }
    cardHandler(index);
  };

  const cardFront = (
    <div className={`card ${isSelected === 1 ? 'active' : isSelected === 0 ? 'small-box' : ''}`} onClick={onCardSelect} role="presentation">
      <div className={`selection-container  ${colorClass}`}>
        <div className="selection-container-content">
          <Radio className="radio-btn" checked={isSelected === 1} />
          <div className="card-content">{heading}</div>
        </div>
        <div className="video-background">
          <div className="video-background-img">
            <SvgLoader width={280} height={140} svgName={img} />
          </div>
        </div>
      </div>
    </div>
  );

  const cardBack = (
    <div className="card " role="presentation">
      <div className={`selection-container ${colorClass}`}>
        <div className="selection-container-content no-data-content">
          <div className="card-content coming-soon-title">Coming soon!</div>
          <div className="card-body coming-soon-desc">{content}</div>
        </div>
      </div>
    </div>
  );

  return status === 'Coming soon' ? (
    <div className={`card-no-data card-flip ${isSelected === 1 ? 'active' : isSelected === 0 ? 'small-box' : ''} ${colorClass}`}>
      <div className="side front">{cardFront}</div>
      <div className="side back">{cardBack}</div>
    </div>
  ) : (
    cardFront
  );
}

InputCard.propTypes = {
  heading: PropTypes.func.isRequired,
  cardHandler: PropTypes.func.isRequired,
  isSelected: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  colorClass: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default InputCard;
