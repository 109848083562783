/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

function BubbleChart({ data }) {
  const svgRef = useRef(null);
  const tooltip = d3.select('body').append('div').style('position', 'absolute').style('padding', '10px').style('background', '#fff').style('border', '1px solid #000').style('border-radius', '5px').style('opacity', 0).style('pointer-events', 'none');

  const showTooltip = function (d) {
    tooltip.transition().duration(200).style('opacity', 0.9);
    tooltip
      .html(
        '<span style="color:grey">Indication: </span><span style="color:black">' +
          `${d?.data?.key
            ?.split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}<br>
      <span style="color:grey">Therapeutic Area: </span><span style="color:black">` +
          `${d?.data?.ta
            ?.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
            .split(' ')
            .join(' ')}<br>
      <span style="color:grey">Overall Score: </span><span style="color:black">` +
          `${d.data.score} | <span style="color:${d.data.score > 0.6 ? '#0A7201' : d.data.score > 0.4 && d.data.score <= 0.6 ? '#A28309' : '#EA3913'}; background-color: ${d.data.score > 0.6 ? '#C3F2BC' : d.data.score > 0.4 && d.data.score <= 0.6 ? '#FEEEB1' : '#FEE5DF'}; padding: 5px; border-radius: 5px;">${d.data.score > 0.6 ? 'High' : d.data.score > 0.4 && d.data.score <= 0.6 ? 'Medium' : 'Low'}</span>
      `,
      )
      .style('left', `${d3.event.pageX}px`)
      .style('top', `${d3.event.pageY - 28}px`);
  };

  const hideTooltip = function () {
    tooltip.transition().duration(200).style('opacity', 0);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const width = 1000;
    const height = 800;

    const bubble = d3.pack().size([width, height]).padding(5);

    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height).style('stroke-width', 0);
    const root = d3.hierarchy({ children: data }).sum((d) => d.score);
    const nodes = bubble(root).descendants();
    svg
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      /* eslint no-underscore-dangle: 0 */
      .attr('id', (d) => d.data?.key)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => d.r)
      .attr('label', (d) => d.data?.score)
      .style('fill', (d) => d?.data?.color)
      .style('stroke', 'none')
      .style('stroke-width', 0)
      .style('opacity', 0.9)
      .on('mouseover', (d) => {
        if (d?.data?.key != null && d?.data?.ta != null && d?.data?.score != null) {
          showTooltip(d);
        }
      })
      .on('mouseout', hideTooltip);
  }, [data]);

  return <svg ref={svgRef} />;
}

BubbleChart.propTypes = {
  data: PropTypes.arrayOf.isRequired,
};

export default BubbleChart;
