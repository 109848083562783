/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Checkbox, Tag, Tooltip } from 'antd';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import Loader from '../../../../common/components/Loader';
import SvgLoader from '../../../../common/components/SvgLoader';

function NewDataTable({ data, loadMoreData, outputResponse, updateParams, updateFilters }) {
  const [totalCount, setTotalCount] = useState(0);
  const [activeSort, setActiveSort] = useState('');
  const [filters, setFilters] = useState({
    'upRegulated': true,
    'downRegulated': true,
  });
  useEffect(() => {
    setTotalCount(outputResponse?.data?.data?.total_count);
  }, []);

  const updateParameters = (type, order, key) => {
    updateParams(order, type, filters);
    setActiveSort(key);
  };

  const updatedFilters = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
    updateFilters({
      ...filters,
      [e.target.name]: e.target.checked,
    });
  };

  const filterContent = (
    <div className="filters-tooltip" style={{ padding: 10 }}>
      <Checkbox className="filter-item" name="upRegulated" onChange={updatedFilters} checked={filters['upRegulated']}>
        Up Regulated
      </Checkbox>
      <Checkbox className="filter-item" name="downRegulated" onChange={updatedFilters} checked={filters['downRegulated']}>
        Down Regulated
      </Checkbox>
    </div>
  );

  const tableRow = (symbol, log2FoldChange, pValue, padj, correlation) => (
    <div className="table-row">
      <div className="table-cell">{symbol}</div>
      <div className="table-cell">{log2FoldChange}</div>
      <div className="table-cell">{pValue}</div>
      <div className="table-cell">{padj}</div>
      <div className="table-cell">
        <Tag icon={<SvgLoader svgName={correlation === 'Up Regulated' ? 'upward-arrow' : 'down-arrow'} width={20} />} color={correlation === 'Up Regulated' ? 'purple' : 'blue'}>
          {correlation}
        </Tag>
      </div>
    </div>
  );

  const scientificNotation = (x) => {
    let str = x.toExponential(2);
    const arr = x.toString().split('e');
    let p = 0;
    if (arr.length > 1) {
      p = parseInt(arr[1]);
      str = parseFloat(arr[0]).toExponential(2);
    }
    for (let i = 0; i < str.length; i++) {
      if (str[i] === 'e') {
        const l = str.substring(i + 1);
        const back = parseInt(l) + p;
        str = `${str.substring(0, i)}E${back.toString()}`;
      }
    }

    if (str[0] === '0') {
      return 0;
    }
    const n = str.length;
    if (n > 2 && str[n - 1] === '0' && str[n - 2] === 'E') {
      return str.substring(0, n - 2);
    }
    return str;
  };

  const tableRowList = () => {
    const rows = data.map((item) => <div className="table-row-container">{tableRow(item.GeneSymbol, scientificNotation(item.log2FoldChange), scientificNotation(item.pvalue), scientificNotation(item.padj), item.log2FoldChange < 0 ? 'Down Regulated' : 'Up Regulated')}</div>);
    return rows;
  };

  return (
    <div className="table-container" style={{ color: 'black' }}>
      <div className="table-header">
        <div className="table-cell">Gene ID</div>
        <div className="table-cell">
          <div className="title-with-sort">
            <div className="table-cell-title">
              log
              <sub>2</sub>
              (Fold Change)
            </div>
            <div className="up-down-btns" style={{ fontSize: '12px' }}>
              <div
                className={`up-btn ${activeSort === 'asc-log2FoldChange' && 'active'}`}
                onClick={() => {
                  updateParameters('log2FoldChange', 'asc', 'asc-log2FoldChange');
                }}
              />
              <div
                className={`down-btn ${activeSort === 'desc-log2FoldChange' && 'active'}`}
                onClick={() => {
                  updateParameters('log2FoldChange', 'desc', 'desc-log2FoldChange');
                }}
              />
            </div>
          </div>
        </div>
        <div className="table-cell">
          <div className="title-with-sort">
            <div className="table-cell-title">p Value</div>
            <div className="up-down-btns" style={{ fontSize: '12px' }}>
              <div
                className={`up-btn ${activeSort === 'asc-pValue' && 'active'}`}
                onClick={() => {
                  updateParameters('pvalue', 'asc', 'asc-pValue');
                }}
              />
              <div
                className={`down-btn ${activeSort === 'desc-pValue' && 'active'}`}
                onClick={() => {
                  updateParameters('pvalue', 'desc', 'desc-pValue');
                }}
              />
            </div>
          </div>
        </div>
        <div className="table-cell">
          <div className="title-with-sort">
            <div className="table-cell-title">p Adjusted</div>
            <div className="up-down-btns" style={{ fontSize: '12px' }}>
              <div
                className={`up-btn ${activeSort === 'asc-padj' && 'active'}`}
                onClick={() => {
                  updateParameters('padj', 'asc', 'asc-padj');
                }}
              />
              <div
                className={`down-btn ${activeSort === 'desc-padj' && 'active'}`}
                onClick={() => {
                  updateParameters('padj', 'desc', 'desc-padj');
                }}
              />
            </div>
          </div>
        </div>
        <div className="table-cell">
          <div className="title-with-sort">
            <div className="table-cell-title"
            >
              Correlation
            </div>
            <Tooltip overlayClassName="correlation-filter-content" title={filterContent}>
              <div className="filters-btn" />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="table-body-container">
        <Loader loading={outputResponse.loading} error={outputResponse.error} height="calc(100vh - 206px)">
          <LazyLoadDiv className="datatable-container-inner" id="table-list" total={totalCount} currentTotal={(data || []).length} height="calc(100vh - 400px)" loadMoreRows={loadMoreData}>
            {tableRowList()}
          </LazyLoadDiv>
        </Loader>
      </div>
    </div>
  );
}

NewDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  loadMoreData: PropTypes.func.isRequired,
  outputResponse: PropTypes.object.isRequired,
  updateParams: PropTypes.func.isRequired,
  updateFilters: PropTypes.func.isRequired,
};

export default NewDataTable;
