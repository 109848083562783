import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Partner details
const GENERATE_LINKERS = 'GENERATE_LINKERS';
const GENERATE_LINKERS_SUCCESS = 'GENERATE_LINKERS_SUCCESS';
const GENERATE_LINKERS_FAILURE = 'GENERATE_LINKERS_FAILURE';

export const generateLinkersAction = createAction(GENERATE_LINKERS);

const generateLinkersSuccess = createAction(GENERATE_LINKERS_SUCCESS);

const generateLinkersFaliure = createAction(GENERATE_LINKERS_FAILURE);
export const generateLinkersEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_LINKERS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_dc}/diseases`, 'get')).pipe(
    map((res) => generateLinkersSuccess(res)),
    catchError((err) => of(generateLinkersFaliure(err))),
  )),
);

const generateLinkersReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateLinkersAction, (state) => {
        state.loading = true;
      })
      .addCase(generateLinkersSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateLinkersFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default generateLinkersReducer;
