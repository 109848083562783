import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Partner details
const GENERATE_LINKERS_OUTPUT = 'GENERATE_LINKERS_OUTPUT';
const GENERATE_LINKERS_OUTPUT_SUCCESS = 'GENERATE_LINKERS_OUTPUT_SUCCESS';
const GENERATE_LINKERS_OUTPUT_FAILURE = 'GENERATE_LINKERS_OUTPUT_FAILURE';

export const generateLinkersOutputAction = createAction(GENERATE_LINKERS_OUTPUT);

const generateLinkersOutputSuccess = createAction(GENERATE_LINKERS_OUTPUT_SUCCESS);

const generateLinkersOutputFaliure = createAction(GENERATE_LINKERS_OUTPUT_FAILURE);
export const generateLinkersOutputEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_LINKERS_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_dc}/combinationScore?${action.payload}`, 'get')).pipe(
    map((res) => generateLinkersOutputSuccess(res)),
    catchError((err) => of(generateLinkersOutputFaliure(err))),
  )),
);

const generateLinkersOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateLinkersOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(generateLinkersOutputSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateLinkersOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default generateLinkersOutputReducer;
