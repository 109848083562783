/* eslint-disable */
import { Flex, Tag } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function GroupedSamplesView({ groupA, groupB, currentIndex, updateProceedBtnStatus, samplesSelected, setSamplesSelected, setFiltersSelected, updateSampleListName, updateSamplesList, isTryExample }) {
  const [pipeline, setPipeline] = useState('');
  const pipelinesList = ['RNA_SEQ', 'SCRNA_SEQ'];
  const [groupTitle, setGroupTitle] = useState(['Group 1', 'Group 2']);
  const [isEditing, setIsEditing] = useState([false, false]);

  useEffect(() => {
    updateSampleListName();
    if (isTryExample) {
        setPipeline(pipelinesList[0]);
    }
  }, []);

  useEffect(() => {
    updateProceedBtnStatus(groupA, groupB, pipeline);
  }, [groupA, groupB, pipeline, currentIndex]);
  const handleClose = (removedTag, groupName) => {
    if (groupName === 'A') {
      const newTags = groupA.filter((tag) => tag !== removedTag);
      updateSamplesList(newTags, 'A', true);
    }
    if (groupName === 'B') {
      const newTags = groupB.filter((tag) => tag !== removedTag);
      updateSamplesList(newTags, 'B', true);
    }
  };

  const handleEdit = (ind) => {
    const arr = isEditing;
    arr[ind] = !arr[ind];
    setIsEditing([...arr]);
  };
  return (
    <div className="tags-group-box-before-search">
      <div className="tags-group-box-before-search-title">Grouped the above samples</div>
      <div className="tags-group-box-row">
        <div className="tags-group-box-before-search-card">
          <div className="tags-group-box-before-search-card-content">
            <div className="tags-group-box-before-search-card-content-title">
              {isEditing[0] ? (
                <input
                  type="text"
                  value={groupTitle[0]}
                  onChange={(e) =>
                    setGroupTitle((prev) => {
                      let arr = prev;
                      arr[0] = e.target.value;
                      return [...arr];
                    })
                  }
                  onKeyDown={(e) =>
                    e.key === 'Enter'
                      ? () => {
                          handleEdit(0);
                          updateSampleListName(groupTitle[0], 'A');
                        }
                      : ''
                  }
                />
              ) : (
                groupTitle[0]
              )}
              <div className="edit-btn" role="presentation" onClick={() => handleEdit(0)} aria-disabled />
            </div>
            <div className="add-card-id">
              <div
                className="add-btn"
                onClick={() => {
                  if (samplesSelected.length !== 0) {
                    updateSamplesList([...new Set(samplesSelected)], 'A');
                    setSamplesSelected([]);
                  }
                }}
                aria-hidden
              />
            </div>
            <div className="tags-group-box-before-search-card-content-desc">
              <div className="tags-group">
                <Flex gap="4px 0" wrap="wrap">
                  {groupA?.map((item) => (
                    <Tag className="sample-tags" closable={!isTryExample} onClose={() => handleClose(item, 'A')}>
                      {item}
                    </Tag>
                  ))}
                </Flex>
              </div>
            </div>
          </div>
        </div>
        <div className="tags-group-box-before-search-card">
          <div className="tags-group-box-before-search-card-content">
            <div className="tags-group-box-before-search-card-content-title">
              {isEditing[1] ? (
                <input
                  type="text"
                  value={groupTitle[1]}
                  onChange={(e) =>
                    setGroupTitle((prev) => {
                      let arr = prev;
                      arr[1] = e.target.value;
                      return [...arr];
                    })
                  }
                  onKeyDown={(e) =>
                    e.key === 'Enter'
                      ? () => {
                          handleEdit(1);
                          updateSampleListName(groupTitle[1], 'B');
                        }
                      : ''
                  }
                />
              ) : (
                groupTitle[1]
              )}
              <div className="edit-btn" role="presentation" onClick={() => handleEdit(1)} />
            </div>
            <div className="add-card-id">
              <div
                className="add-btn"
                onClick={() => {
                  if (samplesSelected.length !== 0) {
                    updateSamplesList([...new Set(samplesSelected)], 'B');
                    setSamplesSelected([]);
                  }
                }}
                aria-hidden
              />
            </div>
            <div className="tags-group-box-before-search-card-content-desc">
              <div className="tags-group">
                <Flex gap="4px 0" wrap="wrap">
                  {groupB?.map((item) => (
                    <Tag key={item} className="sample-tags" closable={!isTryExample} onClose={() => handleClose(item, 'B')}>
                      {item}
                    </Tag>
                  ))}
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentIndex === 4 ? (
        <div className="select-pipeline-container">
          <div className="select-pipeline-title">Select a Pipeline</div>
          <div className="select-pipeline-btns-row">
            <div
              className="select-pipeline-btn-container"
              onClick={() => {
                setPipeline(pipelinesList[0]);
                setFiltersSelected((prev) => {
                  prev.service = pipelinesList[0];
                  return prev;
                });
              }}
              aria-hidden
            >
              <div className={`select-pipeline-btn-text${pipeline === pipelinesList[0] ? ' active' : ''}`}>RNA Sequencing Analysis</div>
            </div>
            <div
              className="select-pipeline-btn-container"
              onClick={() => {
                setPipeline(pipelinesList[1]);
                setFiltersSelected((prev) => {
                  prev.service = pipelinesList[1];
                  return prev;
                });
              }}
              aria-hidden
            >
              <div className={`select-pipeline-btn-text${pipeline === pipelinesList[1] ? ' active' : ''}`}>scRNA Sequencing Analysis</div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}
GroupedSamplesView.propTypes = {
  groupA: PropTypes.arrayOf.isRequired,
  groupB: PropTypes.arrayOf.isRequired,
  samplesSelected: PropTypes.arrayOf.isRequired,
  setSamplesSelected: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  updateProceedBtnStatus: PropTypes.func.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
  updateSampleListName: PropTypes.func.isRequired,
  updateSamplesList: PropTypes.func.isRequired,
};
export default GroupedSamplesView;
