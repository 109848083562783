import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import BubbleChart from '../../components/BubbleChartTI';
import useAppSelector from '../../../../utils/redux-hook';
import { getSafetyProfileOutputAction } from './logic';
import Loader from '../../../../common/components/Loader';

function SafetyProfileView({ jobId }) {
  const getSafetyProfileResponse = useAppSelector((state) => state.getSafetyProfileResponse);
  const dispatch = useDispatch();
  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getSafetyProfileOutputAction(queryString.stringify(request)));
  }, []);
  const eachLegend = (className, name) => (
    <div className={className} style={{ display: 'flex' }}>
      <div className="legend-symbol" />
      <div className="legend-name">{name}</div>
    </div>
  );
  const legendsRow = (className1, name1, className2, name2) => (
    <div className="legend-row" style={{ display: 'flex' }}>
      {eachLegend(className1, name1)}
      {eachLegend(className2, name2)}
    </div>
  );
  return (
    <div className="safety-profile-view-main">
      <div className="safety-profile-view-title graph-card-title">Safety Profile of Targets</div>
      <div className="safety-profile-view-legends">
        {legendsRow('strong-safety-legend', 'Strong Safety', 'weak-safety-legend', 'Weak Safety')}
        {legendsRow('no-safety-legend', 'No Safety', 'total-targets-legend', 'Total Targets')}
      </div>
      <div className="safety-profile-bubble-chart">
        <Loader loading={getSafetyProfileResponse.loading}>
          <BubbleChart data={getSafetyProfileResponse.data.data} />
        </Loader>
      </div>
    </div>
  );
}

SafetyProfileView.propTypes = {
  jobId: PropTypes.string.isRequired,
};
export default SafetyProfileView;
