/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import Header from '../../../../common/components/Header';
import dummyData from './data';
import SvgLoader from '../../../../common/components/SvgLoader';
import { InfoCircleOutlined } from '@ant-design/icons';
import ImageWithLoader from '../../components/ImageWithLoader';
function ViewDetails({ individualItem, handleBack }) {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text) => {
    setIsCopied(true);
    navigator.clipboard.writeText(text);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home protac-header" logo="protac" />
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button className="back-btn-icon" onClick={handleBack}>
              Back
            </Button>
          </div>
          <div className="main-title">PROTAC Details</div>
        </div>
        <div className="dr-output-list protac-inside-view">
          <div key={crypto.randomUUID()} className="combinations-output-item">
            <div className="item-title"></div>
            <div className="combinations-item-content">
              <div className="combinations-drugs-list">
                <div className="combinations-drugs-info">
                  <div className="drugs-img-container">
                    <div className="drugs-img-title">Warhead</div>
                    <div className="drugs-img-output">{individualItem.warhead_image_url === '' ? 'No Image Available' : <ImageWithLoader src={individualItem.warhead_image_url} desc="Warhead" />}</div>
                  </div>
                  <div className="drugs-img-container">
                    <div className="drugs-img-title">Linker</div>
                    <div className="drugs-img-output">{individualItem.linker_image_url === '' ? 'No Image Available' : <ImageWithLoader src={individualItem.linker_image_url} desc="Linker" />}</div>
                  </div>
                  <div className="drugs-img-container">
                    <div className="drugs-img-title">E3 Ligands</div>
                    <div className="drugs-img-output">{individualItem.linker_image_url === '' ? 'No Image Available' : <ImageWithLoader src={individualItem.e3_ligand_image_url} desc="E3 Ligands" />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dr-output-list inside-view-details">
          <div key={crypto.randomUUID()} className="combinations-output-item">
            <div className="item-title">Physicochemical properties </div>
            <div className="combinations-item-content">
              <div className="combinations-drugs-list">
                <div className="combinations-drugs-info">
                  <div className="info-component-row">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-title">
                          <div className="info-component-heading info-add">
                            Mol Wt
                            <span className="info-add-icon" />
                          </div>
                        </div>

                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={`${individualItem.chemical_data?.molecular_weight.toFixed(2) ?? 0} g/mol`}>{`${individualItem.chemical_data?.molecular_weight.toFixed(2) ?? 0} g/mol`}</Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-title">
                          <div className="info-component-heading info-add">
                            Num of Heavy Atoms <span className="info-add-icon" />
                          </div>
                        </div>

                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.no_of_heavy_atoms}>
                            {individualItem.chemical_data?.no_of_heavy_atoms ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          LogP
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.log_p.toFixed(2)}>
                            {individualItem.chemical_data?.log_p.toFixed(2) ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          Fraction of SP3 Carbons
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.fraction_of_sp3_carbons.toFixed(2)}>
                            {individualItem.chemical_data?.fraction_of_sp3_carbons.toFixed(2) ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-component-row">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          TPSA
                          <span className="info-add-icon" />
                        </div>
                        <div className="value-combine-text">
                          <div className="info-component-value">
                            <Tooltip placement="topLeft" title={`${individualItem.chemical_data?.topological_polar_surface_area.toFixed(2)} Å`}>
                              {`${individualItem.chemical_data?.topological_polar_surface_area.toFixed(2) ?? 0} `}
                              <span className="angstrom-text">&#8491;</span>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          QED
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.qed.toFixed(2)}>
                            {individualItem.chemical_data?.qed.toFixed(2) ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dr-output-list inside-view-details">
          <div key={crypto.randomUUID()} className="combinations-output-item">
            <div className="item-title">Molecular structure details </div>
            <div className="combinations-item-content">
              <div className="combinations-drugs-list">
                <div className="combinations-drugs-info">
                  <div className="info-component-row info-component-row-full">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          PROTAC smile
                          <span className="info-add-icon" />
                        </div>
                        <div className="smile-component">
                          <Tooltip placement="topLeft" title={isCopied ? 'copied' : 'copy'}>
                            <div className="smile-copy-sec">
                              <div className="info-copy-btn" style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(individualItem.chemical_data?.smile ?? '-')} />
                            </div>
                          </Tooltip>
                          <div className="info-component-value">
                            <Tooltip placement="topLeft" title={individualItem.chemical_data?.smile}>
                              {individualItem.chemical_data?.smile ?? '-'}
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-component-row">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          Num of Heteroatoms
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.no_of_heteroatoms}>
                            {individualItem.chemical_data?.no_of_heteroatoms ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          Hydrogen Bond Donors
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.hydrogen_bonds_donors}>
                            {individualItem.chemical_data?.hydrogen_bonds_donors ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          Hydrogen Bond Acceptors
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.hydrogen_bonds_acceptors}>
                            {individualItem.chemical_data?.hydrogen_bonds_acceptors ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          Num of Ring Counts
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.no_of_rings_counts}>
                            {individualItem.chemical_data?.no_of_rings_counts ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-component-row">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">
                          Rotatable Bonds
                          <span className="info-add-icon" />
                        </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.rotatable_bonds}>
                            {individualItem.chemical_data?.rotatable_bonds ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Aromatic Rings</div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.aromatic_rings}>
                            {individualItem.chemical_data?.aromatic_rings ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Molecular Refractivity </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.molecular_refractivity.toFixed(2)}>
                            {individualItem.chemical_data?.molecular_refractivity.toFixed(2) ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Num of Radical Electrons </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.no_of_radical_electrons}>
                            {individualItem.chemical_data?.no_of_radical_electrons ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-component-row">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Num of Valence Electrons</div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.no_of_valence_electrons}>
                            {individualItem.chemical_data?.no_of_valence_electrons ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Aliphatic Carbocycles</div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.aliphatic_carbocycles}>
                            {individualItem.chemical_data?.aliphatic_carbocycles ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Aromatic Carbocycles</div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.aromatic_carbocycles}>
                            {individualItem.chemical_data?.aromatic_carbocycles ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Aliphatic Heterocycles </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.aliphatic_heterocycles}>
                            {individualItem.chemical_data?.aliphatic_heterocycles ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info-component-row">
                    <div className="info-component">
                      <div className="info-component-main">
                        <div className="info-component-heading info-add">Aromatic Heterocycles </div>
                        <div className="info-component-value">
                          <Tooltip placement="topLeft" title={individualItem.chemical_data?.aromatic_heterocycles}>
                            {individualItem.chemical_data?.aromatic_heterocycles ?? 0}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ViewDetails.propTypes = {
  handleBack: PropTypes.func.isRequired,
  individualItem: PropTypes.objectOf.isRequired,
};

export default ViewDetails;
