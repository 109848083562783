import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import * as $3Dmol from '3dmol/build/3Dmol';
import { CloseOutlined } from '@ant-design/icons';

function ExpandingModal({ closeModal }) {
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current != null) {
      const viewer = $3Dmol.createViewer(cardRef.current);
      $3Dmol.download('pdb:1IVO', viewer, {}, () => {
        viewer.setBackgroundColor(0x000000);
        viewer.setStyle({}, { stick: {} });
        viewer.vibrate(10, 1);
        viewer.animate({ loop: 'forward', reps: 1 });

        viewer.zoomTo();
        viewer.render();
      });
    }
  }, [cardRef.current]);

  return (
    <Modal footer={null} closeIcon={null} maskClosable={false} open className="pending-status-modal threed-content-modal">
      <div className="threed-content">
        <div className="threed-content-close-btn">
          <Button icon={<CloseOutlined />} onClick={closeModal} />
        </div>
        <div ref={cardRef} style={{ width: '100%', height: '100%', position: 'absolute' }} />
      </div>
    </Modal>
  );
}

ExpandingModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default ExpandingModal;
