import { sendNotification, NOTIFICATION, cancelNotification } from '../common/components/Notification/logic';

export default ({ dispatch }) => (next) => (action) => {
  next(action);
  if (typeof action.type === 'string' && action.type.includes('_SUCCESS')) {
    dispatch(sendNotification({
      type: action.type,
      notificationType: 'success',
      // eslint-disable-next-line no-nested-ternary
      message: action.message ? action.message : (action.payload && action.payload.response && action.payload.response.message) ? action.payload.response.message : 'Successfull',
      showNotification: action.showNotification,
      status: action.payload.status,
    }));
  }
  if (typeof action.type === 'string' && action.type.indexOf('_FAILURE') > -1) {
    dispatch(sendNotification({
      type: action.type,
      notificationType: 'error',
      // eslint-disable-next-line no-nested-ternary
      showNotification: action.payload.status === 401 ? action.hideNotification : !action.hideNotification,
      message: action.payload.status === 401
        ? 'Your current session has expired'
        // eslint-disable-next-line no-nested-ternary
        : action.payload.status === 409 && action.customErrMessage
          ? action.customErrMessage
          // eslint-disable-next-line no-nested-ternary
          : action.message
            ? action.message
            : (action.payload && action.payload.response && action.payload.response)
              ? (action.payload.response.message || action.payload.response.error || 'Oops! There has been an issue. Re-try in some time.')
              : 'Oops! There has been an issue. Re-try in some time.',
      status: action.payload.status,
    }));
  }
  if (action.type === NOTIFICATION) {
    dispatch(cancelNotification());
  }
};
