import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDropdown from '../../components/InputDropdown';

function InputBox({ inputHandler, disease, isDisabled }) {
  const [values, setValues] = useState({});
  const [tryExample, setTryExample] = useState('');

  useEffect(() => {
    inputHandler(values);
  }, [values]);

  const handleChange = (value) => {
    setValues((prev) => ({ ...prev, id: value }));
  };

  return (
    <div className="provide-input-container dc-provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">
          <div className="dropdown-container">
            <InputDropdown index={0} changeHandler={handleChange} tryExample={tryExample} setTryExample={setTryExample} disease={disease} isDisabled={isDisabled} />
          </div>
        </div>
        <div className="input-fields-value try-example-section">
          <text className="try-example-section-title">Try Example: </text>
          <text
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              if (!isDisabled) {
                setTryExample('Prostate cancer');
              }
            }}
          >
            Prostate cancer
          </text>
          <text style={{ color: 'blue' }}> | </text>

          <text
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              if (!isDisabled) {
                setTryExample('Acute myeloid leukemia');
              }
            }}
          >
            Acute Myeloid Leukemia
          </text>
          <text style={{ color: 'blue' }}> | </text>

          <text
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              if (!isDisabled) {
                setTryExample('Glioblastoma');
              }
            }}
          >
            Glioblastoma
          </text>
        </div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  disease: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputBox;
