/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import jobsData from './data';
import Header from '../../../../common/components/Header';
import Loader from '../../../../common/components/Loader';
import { iepWorkspaceStatusAction, iepWorkspaceStatusResetAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { generateIndicationsResetAction } from '../IEPInputPage/logic';
import { terminalsPath } from '../../../../app/components/Card/data';
import { getCookie } from '../../../../utils';
import { isReadIEPOutputAction } from '../IEPOutputPage/logic';
// import PropTypes from 'prop-types';

function MyWorkspacePage() {
  const navigate = useNavigate();
  const [currentJob, setCurrentJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [jobList, setJobList] = useState([]);
  const iepWorkspaceStatusResponse = useAppSelector((state) => state.iepWorkspaceStatusResponse);
  const isReadIEPOutputResponse = useAppSelector((state) => state.isReadIEPOutputResponse);
  const dispatch = useDispatch();
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const userEmailId = getCookie('usersEmailId');
  const onChangedJob = (job) => {
    setCurrentJob(job);
  };
  useEffect(() => {
    dispatch(iepWorkspaceStatusAction());
    setIsLoading(false);
    const intervalId = setInterval(() => {
      if (iepWorkspaceStatusResponse.data) {
        dispatch(iepWorkspaceStatusAction());
      }
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (iepWorkspaceStatusResponse.flag) {
      setJobList(iepWorkspaceStatusResponse.data);
      if (!currentJob?.status) {
        setCurrentJob(iepWorkspaceStatusResponse.data[0]);
      }
    }
  }, [iepWorkspaceStatusResponse]);
  useEffect(() => {
    if (isReadIEPOutputResponse.flag) {
      dispatch(iepWorkspaceStatusAction());
    }
  }, [isReadIEPOutputResponse]);
  const formatDate = (epoch) => {
    const date = new Date(epoch * 1000);

    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });

    return `${day} ${month}`;
  };
  const formatTime = (epoch) => {
    const date = new Date(epoch * 1000);

    const time = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return time;
  };
  const onViewOutput = (job) => {
    if (job?.status.toLowerCase() === 'completed') {
      navigate(`${terminalsPath.metad3_iep_output}?jobId=${job.job_id}`);
    } else {
      navigate(terminalsPath.metad3_iep);
    }
  };
  const onEditName = (job) => {
    const request = {
      job_id: job.job_id,
      job_name: editing.editingName,
    };
    dispatch(isReadIEPOutputAction(queryString.stringify(request)));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
  };

  const eachApproachWeightage = (title, weightage) => (
    <div className="each-approach-weightage">
      <div className="weightage-title">{title}</div>
      <div className="weightage-value">{`${weightage}%`}</div>
    </div>
  );

  const tagsGroup = (list1) => (
    <div className="tags-group">
      <div className="tags">
        {list1.map((item) => (
          <Tag key={item} className="sample-tags" closable={false}>
            {item}
          </Tag>
        ))}
      </div>
    </div>
  );

  const jobsList = () => (
    <div className="jobs-list">
      {jobList?.map((job, index) => (
        <div className={`job-item${job.job_id === currentJob.job_id ? ' active' : ''}`} key={job.job_id} style={{ display: 'flex', cursor: 'pointer' }} onClick={() => onChangedJob(job)} aria-hidden>
          <div className="job-info">
            {editing.isEditing && editing.editingId === index ? (
              <div>
                <Input
                  placeholder="Job Name"
                  value={editing.editingName ?? job.job_name ?? job.user_input.protein_name}
                  onChange={(e) => setEditing({ ...editing, editingName: e.target.value })}
                  onPressEnter={() => onEditName(job)}
                  //   onBlur={() => setEditing({ isEditing: false, editingId: '' })}
                />
              </div>
            ) : (
              <div className="job-title">{job.job_name ?? job.user_input.protein_name}</div>
            )}
            <div className="job-created-at">
              {formatDate(job.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job.created_date_time_ts)}
            </div>
          </div>
          <div className="job-status-main">
            <div className={`job-status${job?.status.toLowerCase() === 'completed' ? ' completed' : job?.status.toLowerCase() === 'rejected' ? ' failed' : ' inprogress'}`}>{job?.status === 'REJECTED' ? 'Failed' : job?.status === 'PENDING' ? 'In Progress' : ''}</div>
          </div>
          <div className="job-name-edit-btn">
            <Button className="rename-edit-icon" onClick={() => setEditing({ isEditing: true, editingId: index })} />
          </div>
        </div>
      ))}
    </div>
  );

  const jobDetails = (job) => (
    <div className="job-details-main">
      {job?.status.toLowerCase() !== 'completed' && <div className={`job-pending-warning ${job?.status.toLowerCase() === 'pending' ? ' pending' : 'failed'}`}>{`${job?.status.toLowerCase() === 'pending' ? `Output generation is in progress. Once it is generated, we will notify you over your email, “${userEmailId}”` : 'Due to a technical error, the output generation failed. Please click the “Resubmit Input” button to try again.'}`}</div>}
      <div className="job-details-title">Input Details</div>
      <div className="job-details-content">
        <div className="job-name-row" style={{ display: 'flex' }}>
          <div className="job-name-group" style={{ display: 'flex' }}>
            <div className="job-name-group-title">{job?.user_input.protein_name}</div>
            <div className="job-created-at">
              {formatDate(job?.created_date_time_ts)}
              <Divider type="vertical" />
              {formatTime(job?.created_date_time_ts)}
            </div>
          </div>
          <div className="view-output-btn">
            <Button
              disabled={job?.status.toLowerCase() === 'pending'}
              onClick={() => {
                onViewOutput(job);
                dispatch(iepWorkspaceStatusResetAction({}));
                dispatch(generateIndicationsResetAction({}));
              }}
            >
              {`${job?.status.toLowerCase() === 'rejected' ? 'Resubmit Input' : 'View Output'}`}
            </Button>
          </div>
        </div>
        {job?.user_input?.synonyms_added?.length || job?.user_input?.synonyms_removed?.length ? (
          <div className="synonyms-changes">
            <div className="synonyms-changes-title">Synonyms Changes</div>
            <div className="synonyms-changes-content" style={{ display: 'flex' }}>
              <div className="added-synonyms">
                <div className="added-synonyms-title">Added Synonyms</div>
                <div className="added-synonyms-list">{job?.user_input.synonyms_added ? tagsGroup(job?.user_input.synonyms_added) : 'No added synonyms'}</div>
              </div>
              <div className="removed-synonyms">
                <div className="removed-synonyms-title">Removed Synonyms</div>
                <div className="removed-synonyms-list">{job?.user_input.synonyms_removed ? tagsGroup(job?.user_input.synonyms_removed) : 'No removed synonyms'}</div>
              </div>
            </div>
          </div>
        ) : null}
        {job?.user_input.approach_weightage !== undefined && Object.keys(job?.user_input?.approach_weightage).length ? (
          <div className="approach-weightage">
            <div className="approach-weightage-title">Approach Weightages</div>
            <div className="approach-weightage-row" style={{ display: 'flex' }}>
              {eachApproachWeightage('Network Approach', job?.user_input.approach_weightage.network_disease_similarity + job.user_input.approach_weightage.network_molecular_function + job.user_input.approach_weightage.network_ppi + job.user_input.approach_weightage.network_literature_mining)}
              {eachApproachWeightage('Literature Mining', job?.user_input.approach_weightage?.literature_mining)}
              {eachApproachWeightage('Clinical Trial', job?.user_input.approach_weightage?.clinical_trial)}
            </div>
            <div className="approach-weightage-row" style={{ display: 'flex' }}>
              {eachApproachWeightage('Protein-Protein Interactions', job?.user_input.approach_weightage?.network_ppi)}
              {eachApproachWeightage('Network Literature Mining', job?.user_input.approach_weightage?.network_literature_mining)}
              {eachApproachWeightage('Disease Similarity', job?.user_input.approach_weightage?.network_disease_similarity)}
              {eachApproachWeightage('Molecular Function Similarity', job?.user_input.approach_weightage?.network_molecular_function)}
            </div>
          </div>
        ) : null}
        {job.user_input.proteins_added?.length || job.user_input.proteins_removed?.length ? (
          <div className="associated-protein">
            <div className="associated-protein-title">Associated Protein Changes</div>
            <div className="associated-protein-changes" style={{ display: 'flex' }}>
              <div className="added-proteins">
                <div className="added-proteins-title">Added Proteins</div>
                <div className="added-proteins-list">{tagsGroup(job.user_input.proteins_added?.map((p) => p.preferred_name) || [])}</div>
              </div>
              <div className="added-proteins">
                <div className="added-proteins-title">Removed Proteins</div>
                <div className="added-proteins-list">{tagsGroup(job.user_input.proteins_removed?.map((p) => p.preferred_name) || [])}</div>
              </div>
            </div>
          </div>
        ) : null}
        {job.user_input.proteins_added?.length || job.user_input.proteins_removed?.length ? <div className="input-details-footer">* 1- Disease Similarity Score, 2-Protein protein score, 3-Molecular function score, 4-Literature mining score</div> : null}
      </div>
    </div>
  );

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="iep-logo" />
      <Loader loading={iepWorkspaceStatusResponse.loading && isLoading}>
        <div className="ct-home-page my-workspace-page">
          <div className="my-workspace-main">
            <div className="jobs-list-view">
              <div className="btns-row">
                <div className="back-btn">
                  <Button
                    onClick={() => {
                      navigate(terminalsPath.metad3_iep);
                      dispatch(iepWorkspaceStatusResetAction({}));
                    }}
                    className="back-btn-icon"
                  >
                    Back
                  </Button>
                </div>
                <div className="grouped-btns">
                  <div className="search-btn">
                    <div className="search-btn-icon" />
                  </div>
                  <div className="sort-btn">
                    <div className="sort-btn-icon" />
                  </div>
                </div>
              </div>
              {jobsList()}
            </div>

            { currentJob && <div className="job-details-view">{currentJob?.user_input && jobDetails(currentJob)}</div>}
          </div>
        </div>
      </Loader>
    </div>
  );
}

// MyWorkspacePage.propTypes = {};

export default MyWorkspacePage;
