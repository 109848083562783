import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get prtac modlecules details
const GET_PROTAC_OUTPUT = 'GET_PROTAC_OUTPUT';
const GET_PROTAC_OUTPUT_SUCCESS = 'GET_PROTAC_OUTPUT_SUCCESS';
const GET_PROTAC_OUTPUT_FAILURE = 'GET_PROTAC_OUTPUT_FAILURE';
const GET_MORE_PROTAC_OUTPUT = 'GET_MORE_PROTAC_OUTPUT';
const GET_MORE_PROTAC_OUTPUT_SUCCESS = 'GET_MORE_PROTAC_OUTPUT_SUCCESS';

export const getProtacOutputAction = createAction(GET_PROTAC_OUTPUT);

const getProtacOutputSuccess = createAction(GET_PROTAC_OUTPUT_SUCCESS);

const getProtacOutputFaliure = createAction(GET_PROTAC_OUTPUT_FAILURE);

export const getMoreProtacOutputAction = createAction(GET_MORE_PROTAC_OUTPUT);

const getMoreProtacOutputSuccess = createAction(GET_MORE_PROTAC_OUTPUT_SUCCESS);

export const getProtacOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_PROTAC_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_protac}/protacMoleculesData?${action.payload}`, 'get')).pipe(
    map((res) => getProtacOutputSuccess(res)),
    catchError((err) => of(getProtacOutputFaliure(err))),
  )),
);
export const getMoreProtacOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_PROTAC_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_protac}/protacMoleculesData?${action.payload}`, 'get')).pipe(
    map((res) => getMoreProtacOutputSuccess(res)),
    catchError((err) => of(getProtacOutputFaliure(err))),
  )),
);
export const getProtacOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getProtacOutputAction, (state) => {
      state.loading = true;
    })
      .addCase(getProtacOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreProtacOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreProtacOutputSuccess, (state, action) => {
        state.data = {
          totalCount: action.payload.response.data.totalCount,
          data: {
            ...state.data.data,
            geneData: [...state.data.data.geneData, ...action.payload.response.data.geneData],
          },
        };
      })
      .addCase(getProtacOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// isRead IEP

const IS_READ_PROTAC_OUTPUT = 'IS_READ_PROTAC_OUTPUT';
const IS_READ_PROTAC_OUTPUT_SUCCESS = 'IS_READ_PROTAC_OUTPUT_SUCCESS';
const IS_READ_PROTAC_OUTPUT_FAILURE = 'IS_READ_PROTAC_OUTPUT_FAILURE';

export const isReadProtacOutputAction = createAction(IS_READ_PROTAC_OUTPUT);

const isReadProtacOutputSuccess = createAction(IS_READ_PROTAC_OUTPUT_SUCCESS);

const isReadProtacOutputFailure = createAction(IS_READ_PROTAC_OUTPUT_FAILURE);

export const isReadProtacOutputEpic = (actions$) => actions$.pipe(
  ofType(IS_READ_PROTAC_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_protac}/readStatus?${action.payload}`, 'PUT')).pipe(
    map((res) => isReadProtacOutputSuccess(res)),
    catchError((err) => of(isReadProtacOutputFailure(err))),
  )),
);
export const isReadProtacOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(isReadProtacOutputAction, (state) => {
      state.loading = true;
    })
      .addCase(isReadProtacOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(isReadProtacOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
