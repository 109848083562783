import { Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../../../utils/redux-hook';
import { getAutoSuggestAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import { getStudyGroupsAction } from '../../SelectStudy/logic';

const { Search } = Input;
function SearchDatasets({
  setCurrentIndex, filters, setFiltersSelected, setIsTryExample, setIsSemantic, setStudiesSelected, setGroupA, setGroupB,
}) {
  const [searchText, setSearchText] = useState('');
  const [indicationName, setIndicationName] = useState('');
  const [organism, setOrganism] = useState('');
  const [biospecimen, setBiospecimen] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const dispatch = useDispatch();
  const getAutoSuggestResponse = useAppSelector((state) => state.getAutoSuggestResponse);
  const [subCategories, setSubCategories] = useState([]);
  const studyGroupsResult = useAppSelector((state) => state.getStudyGroupResponse);

  useEffect(() => {
    if (indicationName !== '') {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [indicationName]);

  useEffect(() => {
    const request = {
      service: 'RNA_SEQ',
    };
    dispatch(getStudyGroupsAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getAutoSuggestResponse.flag) {
      setAutosuggestOptions(getAutoSuggestResponse?.data?.suggestions);
    }
  }, [JSON.stringify(getAutoSuggestResponse)]);

  const onTryExampleClicked = () => {
    setIsTryExample(true);
    setCurrentIndex(4);
    setStudiesSelected([{ study_id: studyGroupsResult.data[2]?.id }]);
    setGroupA(studyGroupsResult.data[2]?.groups[0].groups_associated);
    setGroupB(studyGroupsResult.data[2]?.groups[1].groups_associated);
  };

  const advancedSearchComponent = (title, placeholder, options, isMultiSelect, isDisabled, parameterToUpdate, dependency) => (
    <div className="advanced-search-component" style={{ color: '#000' }}>
      <div className="advanced-search-component-title">{title}</div>
      <Select
        placeholder={placeholder}
        style={{
          width: '100%',
        }}
        mode={isMultiSelect ? 'multiple' : 'default'}
        showSearch
        dropdownAlign={{ offset: [0, 10] }}
        onChange={(val) => {
          if (parameterToUpdate === 'organism') {
            setOrganism(val);
          } else if (parameterToUpdate === 'biospecimen') {
            setBiospecimen(val);
          } else if (parameterToUpdate === 'category') {
            setCategory(val);
            setSubCategory('');
            const updatedSubCategories = filters.techincal_categories.find((item) => item.display_name === val)?.sub_technologies;
            setSubCategories(updatedSubCategories);
            setFiltersSelected((prev) => ({ ...prev, subcategory: '' }));
          } else {
            setSubCategory(val);
          }
          setFiltersSelected((prev) => ({ ...prev, [parameterToUpdate]: parameterToUpdate === 'category' ? filters.techincal_categories.find((item) => item.display_name === val)?.name : val }));
        }}
        disabled={isDisabled}
        value={dependency !== '' ? dependency : undefined}
      >
        {options?.map((item) => (
          <Select.Option key={item.display_name}>{item.display_name}</Select.Option>
        ))}
      </Select>
    </div>
  );
  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0) {
      setAutosuggestOptions([]);
      if (e.target.value.trim().length > 2) {
        const request = {
          indication_name: e.target.value,
        };
        dispatch(getAutoSuggestAction(queryString.stringify(request)));
        setDisplayDropdown(true);
      } else {
        setDisplayDropdown(false);
      }
      setIndicationName(e.target.value);
    } else {
      setIndicationName('');
      setDisplayDropdown(false);
    }
  };

  const renderAutosuggestOptions = () => {
    const options = [];

    /* eslint-disable */
    for (let i = 0; i < Math.min(10, autosuggestOptions.length); i++) {
      const itm = autosuggestOptions[i];
      options.push(
        <div
          key={crypto.randomUUID()}
          onClick={() => {
            setIndicationName(itm?.preferred_name);
            setDisplayDropdown(false);
            setFiltersSelected((prev) => ({ ...prev, indicationName: itm?.preferred_name }));
          }}
        >
          {itm?.preferred_name}
        </div>,
      );
    }
    /* eslint-enable */
    return options;
  };

  const noDataFound = () => <div className="no-data-found">No data found</div>;
  return (
    <div className="search-datasets-main">
      <div className="main-heading" style={{ color: '#000' }}>
        <div className="main-title">Samples Differential Expression Generation</div>
        <div className="main-description">Easily compare study samples in 6 steps: search, select, organize, and compare for insightful analysis through our AI pipelines. Streamline your research process with intuitive tools.</div>
      </div>
      <div className="input-fields-value">
        {isAdvancedSearch ? (
          <div className="search-auto-suggest">
            <Search value={indicationName} size="large" maxLength="256" onChange={onChangeInput} enterButton={null} placeholder="Enter Indication Name" />
            <div className="search-dropdown-result-content">
              {displayDropdown ? (
                <div className="search-dropdown-result">
                  <div className="search-dropdown-result-list">
                    <Loader loading={getAutoSuggestResponse.loading}>{renderAutosuggestOptions().length === 0 ? noDataFound() : renderAutosuggestOptions()}</Loader>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="search-bar">
            <Search
              placeholder="Example: Transcriptomic atopic dermatitis studies in human macrophages"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setFiltersSelected((prev) => ({ ...prev, indicationName: e.target.value }));
              }}
              onSearch={() => {
                setCurrentIndex(1);
                setIsSemantic(true);
              }}
            />
          </div>
        )}

        <div
          className="advanced-search-btns-row"
          style={{
            ariaHidden: 'true',
          }}
        >
          <div className="try-example-btn" style={{ cursor: 'pointer' }} aria-hidden onClick={() => onTryExampleClicked()}>
            Try Example
          </div>
          {!isAdvancedSearch && (
            <div className="advanced-search-btn" aria-hidden onClick={() => setIsAdvancedSearch(true)}>
              Advanced Search
            </div>
          )}
        </div>
        {isAdvancedSearch && (
          <div className="advanced-search-options">
            <div className="advanced-search-component-row">
              {advancedSearchComponent('Organism', 'Select one organism type', filters.organism, false, false, 'organism', organism)}
              {advancedSearchComponent('Biospecimens', 'Choose multiple options', filters.biospecimen, true, false, 'biospecimen', biospecimen)}
            </div>
            <div className="advanced-search-component-row">
              {advancedSearchComponent('Technical categories of the study', 'Select one category type', filters.techincal_categories, false, false, 'category', category)}
              {advancedSearchComponent('Subcategories of the selected category', 'Select one subcategory type', subCategories, false, category === '', 'subcategory', subCategory)}
            </div>
            <div className="search-footer-btn">
              <button
                onClick={() => {
                  setCurrentIndex(1);
                  setIsSemantic(false);
                }}
                className="submit-btn"
                type="button"
                disabled={isSubmitDisabled}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
      <div />
    </div>
  );
}
SearchDatasets.propTypes = {
  setCurrentIndex: PropTypes.func.isRequired,
  filters: PropTypes.objectOf.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
  setIsTryExample: PropTypes.func.isRequired,
  setIsSemantic: PropTypes.func.isRequired,
  setStudiesSelected: PropTypes.func.isRequired,
  setGroupA: PropTypes.func.isRequired,
  setGroupB: PropTypes.func.isRequired,
};
export default SearchDatasets;
