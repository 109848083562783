import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_LITERATURE_MINING = 'GET_LITERATURE_MINING';
const GET_LITERATURE_MINING_SUCCESS = 'GET_LITERATURE_MINING_SUCCESS';
const GET_LITERATURE_MINING_FAILURE = 'GET_LITERATURE_MINING_FAILURE';

export const getLiteratureMiningAction = createAction(GET_LITERATURE_MINING);
const getLiteratureMiningSuccess = createAction(GET_LITERATURE_MINING_SUCCESS);
const getLiteratureMiningFailure = createAction(GET_LITERATURE_MINING_FAILURE);

export const getLiteratureMiningEpic = (actions$) => actions$.pipe(
  ofType(GET_LITERATURE_MINING),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/literatureMiningGraphData?${action.payload}`, 'get')).pipe(
    map((res) => getLiteratureMiningSuccess(res)),
    catchError((err) => of(getLiteratureMiningFailure(err))),
  )),
);

export const getLiteratureMiningReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getLiteratureMiningAction, (state) => {
      state.loading = true;
    })
      .addCase(getLiteratureMiningSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getLiteratureMiningFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
