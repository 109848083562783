import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function InputBox({ inputHandler, allInputs, isDisabled }) {
  const [nctId, setNcdId] = useState('');
  const [dataset, setDataset] = useState([0, 0]);
  const [isEmptyId, setIsEmptyId] = useState(true);

  const dataSets = ['COMPLETE_TRAINING_DATA_CORPUS', 'PLACEBO_CONTROLLED_TRIALS_ONLY'];

  useEffect(() => {
    setNcdId(allInputs.id ?? '');
    dataSets.forEach((item, index) => {
      if (item === allInputs.dataset) {
        setDataset((prev) => {
          const newDataSet = prev;
          newDataSet[index] = 1;
          return newDataSet;
        });
      }
    });
  }, []);
  const handleIdValidation = () => {
    setIsEmptyId(nctId !== '');
  };

  useEffect(() => {
    const inputs = {
      id: nctId,
      dataset: dataset[0] === 1 ? dataSets[0] : dataset[1] === 1 ? dataSets[1] : '',
    };
    inputHandler(inputs);
  }, [nctId, dataset]);

  return (
    <div className="provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">
          <div className="input-fields-value">
            <input className="input-text" placeholder="Enter clinical trial NCT ID" value={nctId} onChange={(e) => setNcdId(e.target.value)} onBlur={handleIdValidation} disabled={isDisabled} />
            <span className="not-valid" style={{ color: 'red' }}>
              {`${isEmptyId ? '' : 'Please enter NCT Id'}`}
            </span>
          </div>
          <div className="input-fields-value try-example-section">
            <text className="try-example-section-title">Try Examples: </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                if (!isDisabled) {
                  setNcdId('NCT05743075');
                  setIsEmptyId(true);
                }
              }}
            >
              NCT05743075
            </text>
            <text style={{ color: 'blue' }}> | </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                if (!isDisabled) {
                  setNcdId('NCT04155749');
                  setIsEmptyId(true);
                }
              }}
            >
              NCT04155749
            </text>
            <text style={{ color: 'blue' }}> | </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                if (!isDisabled) {
                  setNcdId('NCT04408755');
                  setIsEmptyId(true);
                }
              }}
            >
              NCT04408755
            </text>
          </div>
          <div className="select-dataset">
            <div className="select-title">Select a Dataset</div>
            <div className="select-dataset-footer">
              <div className="select-dataset-footer-btn">
                <button type="button" className={`select-button ${dataset[0] === 1 ? 'active' : ''}`} onClick={() => setDataset([1, 0])} disabled={isDisabled}>
                  Complete training data corpus
                </button>
              </div>
              <div className="select-dataset-footer-btn">
                <button type="button" className={`select-button ${dataset[1] === 1 ? 'active' : ''}`} onClick={() => setDataset([0, 1])} disabled={isDisabled}>
                  Placebo-controlled trials only
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  allInputs: PropTypes.shape({
    indication: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    dataset: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputBox;
