import { React, useState, useEffect } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Rcookie from 'react-cookies';
import { getCTPOutputAction, isReadCTPOutputAction } from './logic';
import Header from '../../../../common/components/Header';
import useAppSelector from '../../../../utils/redux-hook';
import { terminalsPath } from '../../../../app/components/Card/data';
import Loader from '../../../../common/components/Loader';
import { checkStatusResetAction } from '../CTPInputPage/logic';
import ClinicalSuccessOutputPage from '../ClinicalSuccessOutputPage';
import ClinicalFeasibilityOutputPage from '../ClinicalFeasibilityOutputPage';

function CTPOutputPage() {
  const dispatch = useDispatch();
  const ctpOutput = useAppSelector((state) => state.ctpOutput);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const service = searchParams.get('service');
  const nctId = searchParams.get('nctId');
  const indication = searchParams.get('indication');
  const dataset = searchParams.get('dataset');
  const navigate = useNavigate();
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const title = {
    PREDICT_CLINICAL_TRIAL_SUCCESS: ['Clinical Trial Success Probability Analysis', 'Explore the Intricacies of Feature Relationships in the Generated Insights'],
    CHECK_TRIAL_FEASIBILITY: [`Clinical Trial Feasibility Analysis for "${indication}"`, 'Explore the Intricacies of Feature Relationships in the Generated Insights'],
  };

  useEffect(() => {
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      const request = {
        job_id: jobId,
        is_read: true,
      };
      dispatch(isReadCTPOutputAction(queryString.stringify(request)));
    }
  }, []);
  useEffect(() => {
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      const request = {
        job_id: jobId,
      };
      setTimeout(() => {
        dispatch(getCTPOutputAction(queryString.stringify(request)));
      }, 50);
      dispatch(checkStatusResetAction());
    }
  }, []);

  const downloadXLSFile = async () => {
    setIsPendingDownload(true);
    let downloadUrl = '';
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      downloadUrl = `${process.env.apiUrl_ctp}/scoreResultDownload?job_id=${jobId}`;
    }
    if (service === 'CHECK_TRIAL_FEASIBILITY') {
      downloadUrl = `${process.env.apiUrl_ctp}/trialResultDownload?indication_name=${indication}`;
    }
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };

    try {
      const response = await axios({
        method: 'GET',
        url: downloadUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      const outputFilename = `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const handleBackClick = () => {
    navigate(`${terminalsPath.metad3_ctp}?service=${service}&nctId=${nctId}&dataset=${dataset}&indication=${indication}`);
  };

  const outputType = () => {
    if (service === 'PREDICT_CLINICAL_TRIAL_SUCCESS') {
      return <ClinicalSuccessOutputPage />;
    }
    if (service === 'CHECK_TRIAL_FEASIBILITY') {
      return <ClinicalFeasibilityOutputPage indicationName={indication} jobId={jobId} />;
    }
    return <ClinicalSuccessOutputPage />;
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home ctp-header" logo="meta-ctp" />
      <Loader loading={ctpOutput?.loading} error={ctpOutput?.error} width="100%" height="1000px">
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="back-btn">
              <Button className="back-btn-icon" onClick={() => handleBackClick()}>
                Back
              </Button>
            </div>
            <div className="main-title">{title[service][0]}</div>
            <div className="main-description">{title[service][1]}</div>
            <div className="main-download-btn">
              <Button
                onClick={() => {
                  setIsPendingDownload(true);
                  downloadXLSFile();
                }}
                disabled={isPendingDownload}
              >
                {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
              </Button>
            </div>
          </div>
          {outputType()}
        </div>
      </Loader>
    </div>
  );
}

export default CTPOutputPage;
