import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { getCookie } from '../../../../utils';

function PendingStatusModal({ open }) {
  return (
    <Modal footer={null} closeIcon={null} maskClosable={false} open={open} className="pending-status-modal">
      <div className="pending-status-content">
        <div className="pending-status-content-status">
          <div className="pending-status-content-status-icon" />
        </div>
        <div className="pending-status-title">Input Successfully Submitted!</div>
        <div className="pending-status-description">Your input is submitted successfully, our system is generating output for your provided input. Once it is generated we will notify you over your mail</div>
        <div className="pending-status-email">{`"${getCookie('usersEmailId')}"`}</div>
      </div>
    </Modal>
  );
}

PendingStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default PendingStatusModal;
