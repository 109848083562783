import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const CTP_WORD_CLOUD = 'CTP_WORD_CLOUD';
const CTP_WORD_CLOUD_SUCCESS = 'CTP_WORD_CLOUD_SUCCESS';
const CTP_WORD_CLOUD_FAILURE = 'CTP_WORD_CLOUD_FAILURE';
const CTP_WORD_CLOUD_RESET = 'CTP_WORD_CLOUD_RESET';

export const ctpWordCloudAction = createAction(CTP_WORD_CLOUD);
export const ctpWordCloudResetAction = createAction(CTP_WORD_CLOUD_RESET);

const ctpWordCloudsSuccess = createAction(CTP_WORD_CLOUD_SUCCESS);
const ctpWordCloudFailure = createAction(CTP_WORD_CLOUD_FAILURE);

export const ctpWordCloudEpic = (actions$) => actions$.pipe(
  ofType(CTP_WORD_CLOUD),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/wordCloud?${action.payload}`, 'get')).pipe(
    map((res) => ctpWordCloudsSuccess(res)),
    catchError((err) => of(ctpWordCloudFailure(err))),
  )),
);

export const ctpWordCloudReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(ctpWordCloudAction, (state) => {
        state.loading = true;
      })
      .addCase(ctpWordCloudsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(ctpWordCloudFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ctpWordCloudResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
