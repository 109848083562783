import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// isRead IEP

const IS_READ_IEP_OUTPUT = 'IS_READ_IEP_OUTPUT';
const IS_READ_IEP_OUTPUT_SUCCESS = 'IS_READ_IEP_OUTPUT_SUCCESS';
const IS_READ_IEP_OUTPUT_FAILURE = 'IS_READ_IEP_OUTPUT_FAILURE';

export const isReadIEPOutputAction = createAction(IS_READ_IEP_OUTPUT);

const isReadIEPOutputSuccess = createAction(IS_READ_IEP_OUTPUT_SUCCESS);

const isReadIEPOutputFailure = createAction(IS_READ_IEP_OUTPUT_FAILURE);

export const isReadIEPOutputEpic = (actions$) => actions$.pipe(
  ofType(IS_READ_IEP_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/readStatus?${action.payload}`, 'PUT')).pipe(
    map((res) => isReadIEPOutputSuccess(res)),
    catchError((err) => of(isReadIEPOutputFailure(err))),
  )),
);
export const isReadIEPOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(isReadIEPOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(isReadIEPOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(isReadIEPOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
