import { combineReducers } from '@reduxjs/toolkit';
import { sessionReducer } from './common/container/Status/logic';
import { customNotificationReducer, notificationReducer } from './common/components/Notification/logic';
import rootCommonReducer from './common/container/reducer';
import rootAppReducer from './app/container/reducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  notification: notificationReducer,
  customNotification: customNotificationReducer,
  ...rootCommonReducer,
  ...rootAppReducer,
});

export default rootReducer;
