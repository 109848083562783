import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SamplesListView from '../ViewSamples/SamplesListView';
import CategoriesViewSamples from '../ViewSamples/CategoriesViewSamples';
import SampleDetails from '../ViewSamples/SampleDetails';
import sampleData from '../ViewSamples/data';
import GroupedSamplesView from './GroupedSamplesView';

function GroupSamples({
  setCurrentIndex, currentIndex, studiesSelected, filtersSelected, setFiltersSelected,
}) {
  const [displayCategories, setDisplayCategories] = useState(true);
  const [selectedSample, setSelectedSample] = useState(sampleData[0]);
  const [samplesSelected, setSamplesSelected] = useState([]);
  const [groupA, setGroupA] = useState([]);
  const [groupB, setGroupB] = useState([]);
  const [isProceedDisabled, setIsProceedDisabled] = useState(true);
  const updateSamplesList = (samples) => {
    setSamplesSelected(samples);
    setGroupA(samples);
    setGroupB(samples);
  };

  const updateProceedBtnStatus = (list1, list2, pipeline) => {
    if (currentIndex === 3) {
      if (list1.length > 0 && list2.length > 0) {
        setIsProceedDisabled(false);
      } else {
        setIsProceedDisabled(true);
      }
    } else if (pipeline !== '') {
      setIsProceedDisabled(false);
    } else {
      setIsProceedDisabled(true);
    }
  };
  return (
    <div className="select-preferred-studies-container">
      <div className="back-btn-row">
        <div className="back-btn-select-study" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => setCurrentIndex(2)} aria-hidden>
          {'<-- Back'}
        </div>
        <div className="back-btn-beside-title">{`${sampleData.length} samples form all 3 selected studies`}</div>
      </div>
      <div className="select-preferred-studies-main back-to-filter-btn">
        {displayCategories && <CategoriesViewSamples studiesSelected={studiesSelected} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} />}
        <SamplesListView sampleData={sampleData} displayCategories={displayCategories} setDisplayCategories={setDisplayCategories} selectedSample={selectedSample} setSelectedSample={setSelectedSample} updateSelectedSamples={updateSamplesList} samplesSelected={samplesSelected} isGrouping />
        {!displayCategories && <SampleDetails sample={selectedSample} />}
      </div>
      <GroupedSamplesView groupA={groupA} groupB={groupB} setSamplesSelected={setSamplesSelected} currentIndex={currentIndex} updateProceedBtnStatus={updateProceedBtnStatus} />
      <div className="proceed-footer-btn">
        <button
          onClick={() => {
            setCurrentIndex(currentIndex + 1);
          }}
          className="submit-btn"
          type="button"
          disabled={isProceedDisabled}
        >
          {currentIndex === 3 ? 'Proceed to Pipeline Selection' : 'Generate DEGs'}
        </button>
      </div>
    </div>
  );
}
GroupSamples.propTypes = {
  setCurrentIndex: PropTypes.func.isRequired,
  currentIndex: PropTypes.number.isRequired,
  studiesSelected: PropTypes.arrayOf.isRequired,
  filtersSelected: PropTypes.objectOf.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
};
export default GroupSamples;
