import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get job id details
const GET_JOB_ID = 'GET_JOB_ID';
const GET_JOB_ID_SUCCESS = 'GET_JOB_ID_SUCCESS';
const GET_JOB_ID_FAILURE = 'GET_JOB_ID_FAILURE';
const GET_JOB_ID_RESET = 'GET_JOB_ID_RESET';

export const getJobIdAction = createAction(GET_JOB_ID);
export const getJobIdResetAction = createAction(GET_JOB_ID_RESET);

const getJobIdSuccess = createAction(GET_JOB_ID_SUCCESS);
const getJobIdFailure = createAction(GET_JOB_ID_FAILURE);

export const getBiomarkerJobIdEpic = (actions$) => actions$.pipe(
  ofType(GET_JOB_ID),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/rnaSequencing?${action.payload}`, 'get')).pipe(
    map((res) => getJobIdSuccess(res)),
    catchError((err) => of(getJobIdFailure(err))),
  )),
);

export const getJobIdReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getJobIdAction, (state) => {
        state.loading = true;
      })
      .addCase(getJobIdSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getJobIdFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(getJobIdResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// get job id status details
const CHECK_BIOMARKER_STATUS = 'CHECK_BIOMARKER_STATUS';
const CHECK_BIOMARKER_STATUS_SUCCESS = 'CHECK_BIOMARKER_STATUS_SUCCESS';
const CHECK_BIOMARKER_STATUS_FAILURE = 'CHECK_BIOMARKER_STATUS_FAILURE';
const CHECK_BIOMARKER_STATUS_RESET = 'CHECK_BIOMARKER_STATUS_RESET';

export const checkBiomarkerStatusAction = createAction(CHECK_BIOMARKER_STATUS);
export const checkBiomarkerStatusResetAction = createAction(CHECK_BIOMARKER_STATUS_RESET);

const checkBiomarkerStatussSuccess = createAction(CHECK_BIOMARKER_STATUS_SUCCESS);
const checkBiomarkerStatusFailure = createAction(CHECK_BIOMARKER_STATUS_FAILURE);

export const checkBiomarkerStatusEpic = (actions$) => actions$.pipe(
  ofType(CHECK_BIOMARKER_STATUS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/jobs?${action.payload}`, 'get')).pipe(
    map((res) => checkBiomarkerStatussSuccess(res)),
    catchError((err) => of(checkBiomarkerStatusFailure(err))),
  )),
);

export const checkBiomarkerStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(checkBiomarkerStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(checkBiomarkerStatussSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkBiomarkerStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkBiomarkerStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
