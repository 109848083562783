import React from 'react';
import PropTypes from 'prop-types';

function NoDataFound({ style, className, message }) {
  // eslint-disable-next-line no-unneeded-ternary
  return (
    <div style={style} className={className || 'no-data'}>
      {message || 'Data unavailable'}
    </div>
  );
}

NoDataFound.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

NoDataFound.defaultProps = {
  message: '',
  className: '',
  style: {},
};

export default NoDataFound;
