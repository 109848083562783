import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import Rcookie from 'react-cookies';
import axios from 'axios';
import Header from '../../../../common/components/Header';
import { terminalsPath } from '../../../../app/components/Card/data';
import {
  getBiomakerOutputAction, isReadBiomarkerOutputAction, getMoreBiomarkerOutputAction, volcanoGraphBiomarkerOutputAction,
} from './logic';
import { checkBiomarkerStatusResetAction } from '../BiomarkerInputPage/logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';
import NewDataTable from '../../components/Table/index1';
import VolcanoPlot from '../../components/VolcanoPlot';
import ImageWithLoader from '../../components/ImageWithLoader';

function BiomarkerOutputPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outputResponse = useAppSelector((state) => state.getBiomarkerOutputResponse);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const studyGroup = searchParams.get('studyGroup');
  const service = searchParams.get('service');
  const [heatMap64, setHeatMap64] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isEmptyList, setIsEmptyList] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortKey, setSortKey] = useState('padj');
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const [updatedFilters, setUpdatedFilters] = useState({
    upRegulated: true,
    downRegulated: true,
  });

  const updateParams = (order, key) => {
    setSortOrder(order);
    setSortKey(key);
    setCurrentPage(1);
    const request = {
      path: jobId,
      query: queryString.stringify({
        from: 1,
        service: 'RNA_SEQ',
        sort_order: order,
        sort_key: key,
        filters: updatedFilters.upRegulated === updatedFilters.downRegulated ? undefined : JSON.stringify({
          regulated: updatedFilters.upRegulated ? 'upRegulated' : 'downRegulated',
        }),
      }),
    };
    dispatch(getBiomakerOutputAction(request));
  };

  const updateFilters = (value) => {
    setUpdatedFilters(value);
    setCurrentPage(1);
    const request = {
      path: jobId,
      query: queryString.stringify({
        from: 1,
        service: 'RNA_SEQ',
        sort_order: sortOrder,
        sort_key: sortKey,
        filters: value.upRegulated && value.downRegulated ? undefined : JSON.stringify({
          regulated: value.upRegulated ? 'upRegulated' : 'downRegulated',
        }),
      }),
    };
    dispatch(getBiomakerOutputAction(request));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const request = {
      path: jobId,
      query: queryString.stringify({
        service: 'RNA_SEQ',
      }),
    };
    dispatch(isReadBiomarkerOutputAction(request));
    dispatch(checkBiomarkerStatusResetAction());
    dispatch(volcanoGraphBiomarkerOutputAction({ path: jobId }));
  }, []);

  useEffect(() => {
    const request = {
      path: jobId,
      query: queryString.stringify({
        from: currentPage,
        service: 'RNA_SEQ',
        sort_order: sortOrder,
        sort_key: sortKey,
        filters: updatedFilters.upRegulated === updatedFilters.downRegulated ? undefined : JSON.stringify({
          regulated: updatedFilters.upRegulated ? 'upRegulated' : 'downRegulated',
        }),
      }),
    };
    dispatch(getBiomakerOutputAction(request));
  }, []);

  useEffect(() => {
    if (outputResponse?.flag && outputResponse?.data) {
      setTableData(outputResponse?.data?.data?.data);
      setIsEmptyList(outputResponse.data?.data?.data.length === 0);
      setHeatMap64(outputResponse.data?.data?.heatmap_plot_base64);
    }
  }, [JSON.stringify(outputResponse)]);

  const loadMoreTableData = () => {
    const request = {
      path: jobId,
      query: queryString.stringify({
        from: currentPage + 1,
        service: 'RNA_SEQ',
        sort_order: sortOrder,
        sort_key: sortKey,
        filters: updatedFilters.upRegulated === updatedFilters.downRegulated ? undefined : JSON.stringify({
          regulated: updatedFilters.upRegulated ? 'upRegulated' : 'downRegulated',
        }),
      }),
    };
    setCurrentPage(currentPage + 1);
    dispatch(getMoreBiomarkerOutputAction(request));
  };
  const handleBack = () => {
    navigate(`${terminalsPath.metad3_biomarker}?studyGroup=${studyGroup}&service=${service}`);
  };
  function convertUnderscoreToSpace(str) {
    return str.replace(/_/g, ' ').replace(/(\d+)/, ' $1');
  }
  const downloadXLSFile = async ({ isZip }) => {
    if (isZip) {
      setIsPendingZipDownload(true);
    } else {
      setIsPendingDownload(true);
    }
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = isZip ? `${process.env.apiUrl_biomarker}/jobs/zip/${jobId}` : `${process.env.apiUrl_biomarker}/resultDownload/${jobId}?service=${service}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      setIsPendingZipDownload(false);
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home adc-header" logo="biomarker-logo" width={660} />
      <Loader loading={outputResponse?.loading && isEmptyList} error={outputResponse?.error} width="100%" height="1000px">
        <div className="ct-home-page bio-home-page">
          <div className="main-heading">
            <div className="back-btn">
              <Button className="back-btn-icon" onClick={handleBack}>
                Back
              </Button>
            </div>
            <div className="main-title">{`Differential Expression for "${convertUnderscoreToSpace(studyGroup)}"`}</div>
            <div className="main-description">{`Volcano Plot, Heatmap and List for DEGs in "${convertUnderscoreToSpace(studyGroup)}"`}</div>
            <div className="download-btns">
              <div className="main-download-btn">
                <Button onClick={() => downloadXLSFile({ isZip: false })} disabled={isPendingDownload}>
                  {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                </Button>
              </div>
              <div className="main-download-btn">
                <Button onClick={() => downloadXLSFile({ isZip: true })} disabled={isPendingZipDownload}>
                  {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                </Button>
              </div>
            </div>
          </div>
          <div className="graphs-container">
            <div className="graph">
              <div className="graph-card">
                <div className="graph-card-content">
                  <div className="graph-card-content-title">Volcano Plot of DEGs</div>
                  <div className="graph-card-content-graph-data">
                    <VolcanoPlot />
                  </div>
                </div>
              </div>
              <div className="graph-card">
                <div className="graph-card-content">
                  <div className="graph-card-content-title">Expression Heatmap of DEGs</div>
                  <div className="graph-card-content-graph-data">
                    <ImageWithLoader src={heatMap64} desc="Heatmap Image" />
                    {/* <img src={heatMap64} alt="Heatmap Image" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="datatable-container">
            <NewDataTable data={tableData} outputResponse={outputResponse} loadMoreData={loadMoreTableData} updateParams={updateParams} updateFilters={updateFilters} />
          </div>
        </div>
      </Loader>
    </div>
  );
}

export default BiomarkerOutputPage;
