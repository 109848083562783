import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../../../utils/redux-hook';
import NewHumanBodySVG from '../../components/HumanBodySVG';
import { getTopOrganAffectedOutputAction } from './logic';

function TopOrganAffectedView({ jobId }) {
  const getTopOrganAffectedResponse = useAppSelector((state) => state.getTopOrganAffectedResponse);
  const dispatch = useDispatch();
  const [oragansArray, setOrgansArray] = useState([]);
  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getTopOrganAffectedOutputAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (getTopOrganAffectedResponse.flag) {
      setOrgansArray(getTopOrganAffectedResponse.data.data.male);
    }
  });
  const eachLegend = (organ, count) => (
    <div className="top-organ-affected-view-details-content-card">
      <div className="top-organ-affected-view-details-content-card-img">
        <div className={`top-organ-affected-view-details-content-card-img-icon ${organ}`} />
      </div>
      <div className="top-organ-affected-view-details-content-card-data">
        <div className="top-organ-affected-view-details-content-card-data-name">{organ.charAt(0).toUpperCase() + organ.slice(1)}</div>
        <div className="top-organ-affected-view-details-content-card-data-count">{count}</div>
        <div className="top-organ-affected-view-details-content-card-data-count-name">Target Count</div>
      </div>
    </div>
  );
  return (
    <div className="top-organ-affected-view">
      <div className="graph-card-title">Top Organs Affected in Colorectal Cancer</div>
      <div className="top-organ-affected-view-details">
        <div className="top-organ-affected-view-details-content">{oragansArray.map((item) => eachLegend(item.organ, item.score))}</div>
      </div>
      <div className="target-count-legend">
        <div className="target-count-legend-content">
          <div className="target-count-legend-content-bar">
            <div className="target-count-legend-content-bar-slide" />
          </div>
          <div className="target-count-legend-content-value">
            <div className="target-count-legend-content-value-high">High</div>
            <div className="target-count-legend-content-value-title">Target Count</div>
            <div className="target-count-legend-content-value-low">Low</div>
          </div>
        </div>
      </div>
      <div
        className="chart-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          maxWidth: '1200px',
          margin: '20px auto',
        }}
      >
        <div className="svg-container" style={{ flex: '1' }}>
          <NewHumanBodySVG />
        </div>
      </div>
    </div>
  );
}

TopOrganAffectedView.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default TopOrganAffectedView;
