import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FloatButton } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import FiltersSelectStudy from './FiltersSelectStudy';
import StudiesListView from './StudiesListView';
import StudyDetails from './StudyDetails';
import useAppSelector from '../../../../../utils/redux-hook';
import Loader from '../../../../../common/components/Loader';
import { getMoreStudiesAction, getStudiesAction } from './logic';
import { getFiltersAction } from '../../../pages/NewBiomarkerInputPage/logic';

function SelectPreferredStudies({
  setCurrentIndex, setSelectedStudies, studiesSelected, filters, filtersSelected, setFiltersSelected, isSemantic,
}) {
  const [displayFilters, setDisplayFilters] = useState(true);
  const [selectedStudy, setSelectedStudy] = useState({});
  const [isProceedDisabled, setIsProceedDisabled] = useState(true);
  const [studyData, setStudyData] = useState([]);
  const dispatch = useDispatch();
  const getStudiesResponse = useAppSelector((state) => state.getStudiesResponse);
  const getFiltersResponse = useAppSelector((state) => state.getFiltersResponse);
  const [totalStudiesCount, setTotalStudiesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (studiesSelected.length > 0) {
      setIsProceedDisabled(false);
    } else {
      setIsProceedDisabled(true);
    }
  }, [studiesSelected]);
  useEffect(() => {
    if (getFiltersResponse) {
      setCurrentIndex(1);
      if (isSemantic) {
        const body = {
          filters: {
            organism: [
              {
                operator: 'OR',
                value: 'Homo sapiens',
              },
            ],
          },
        };
        const request = {
          params: queryString.stringify({
            search_query: filtersSelected.indicationName,
          }),
          body,
        };
        dispatch(getStudiesAction(request));
        dispatch(getFiltersAction(request));
      } else {
        const body = {
          filters: {},
        };
        body.filters.organism = filtersSelected.organism !== undefined
          ? [
            {
              operator: 'OR',
              value: filtersSelected.organism,
            },
          ]
          : [
            {
              operator: 'OR',
              value: 'Homo sapiens',
            },
          ];
        body.filters.biospecimen = filtersSelected.biospecimen !== undefined
          ? filtersSelected.biospecimen?.map((item) => ({ operator: 'OR', value: item }))
          : [];
        body.filters[filtersSelected.category] = filtersSelected.category !== undefined
          ? [
            {
              operator: 'OR',
              value: filtersSelected.subcategory,
            },
          ]
          : undefined;
        const request = {
          params: queryString.stringify({
            from: 1,
            search_query: filtersSelected.indicationName,
          }),
          body,
        };
        dispatch(getStudiesAction(request));
        dispatch(getFiltersAction(request));
      }
    }
  }, []);

  useEffect(() => {
    if (getStudiesResponse.loading === false) {
      setStudyData(getStudiesResponse?.data);
      setTotalStudiesCount(getStudiesResponse?.total);
    }
  }, [getStudiesResponse]);

  const loadMoreData = () => {
    setCurrentPage(currentPage + 1);
    const body = {
      filters: {},
    };
    body.filters.organism = filtersSelected.organism !== undefined
      ? [
        {
          operator: 'OR',
          value: filtersSelected.organism,
        },
      ]
      : [
        {
          operator: 'OR',
          value: 'Homo sapiens',
        },
      ];
    body.filters.biospecimen = filtersSelected.biospecimen !== undefined
      ? filtersSelected.biospecimen?.map((item) => ({ operator: 'OR', value: item }))
      : [];
    body.filters[filtersSelected.category] = filtersSelected.category !== undefined
      ? [
        {
          operator: 'OR',
          value: filtersSelected.subcategory,
        },
      ]
      : undefined;
    const request = {
      params: queryString.stringify({
        from: currentPage + 1,
        search_query: filtersSelected.indicationName,
      }),
      body,
    };
    dispatch(getMoreStudiesAction(request));
  };

  return (
    <div className="select-preferred-studies-container">
      <div className="back-btn-row">
        <div className="back-btn-select-study" style={{ cursor: 'pointer', color: 'blue' }} onClick={() => setCurrentIndex(0)} aria-hidden>
          {'<-- Back'}
        </div>
        <div className="back-btn-beside-title">{`${totalStudiesCount === undefined ? 0 : totalStudiesCount} studies found for “${filtersSelected.indicationName}”`}</div>
      </div>
      <div className="select-preferred-studies-main back-to-filter-btn">
        {!displayFilters && <FloatButton onClick={() => setDisplayFilters(true)} />}
        <FiltersSelectStudy filters={filters} filtersSelected={filtersSelected} setFiltersSelected={setFiltersSelected} displayFilters={displayFilters} isSemantic={isSemantic} setCurrentPage={setCurrentPage} />
        <Loader loading={getStudiesResponse?.loading || getFiltersResponse?.loading}><StudiesListView studyData={studyData} displayFilters={displayFilters} setDisplayFilters={setDisplayFilters} selectedStudy={selectedStudy} setSelectedStudy={setSelectedStudy} setSelectedStudies={setSelectedStudies} selectedStudies={studiesSelected} loadMoreStudies={loadMoreData} totalStudies={totalStudiesCount} /></Loader>
        <StudyDetails study={selectedStudy} displayFilters={displayFilters} />
      </div>
      <div className="proceed-footer-btn">
        <button
          onClick={() => {
            setCurrentIndex(2);
          }}
          className="submit-btn"
          type="button"
          disabled={isProceedDisabled}
        >
          Proceed to Sample Details
        </button>
      </div>
    </div>
  );
}
SelectPreferredStudies.propTypes = {
  setCurrentIndex: PropTypes.func.isRequired,
  setSelectedStudies: PropTypes.func.isRequired,
  studiesSelected: PropTypes.arrayOf.isRequired,
  filters: PropTypes.objectOf.isRequired,
  filtersSelected: PropTypes.objectOf.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
  isSemantic: PropTypes.bool.isRequired,
};
export default SelectPreferredStudies;
