import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const IEP_WORKSPACE_STATUS = 'IEP_WORKSPACE_STATUS';
const IEP_WORKSPACE_STATUS_SUCCESS = 'IEP_WORKSPACE_STATUS_SUCCESS';
const IEP_WORKSPACE_STATUS_FAILURE = 'IEP_WORKSPACE_STATUS_FAILURE';
const IEP_WORKSPACE_STATUS_RESET = 'IEP_WORKSPACE_STATUS_RESET';

export const iepWorkspaceStatusAction = createAction(IEP_WORKSPACE_STATUS);
export const iepWorkspaceStatusResetAction = createAction(IEP_WORKSPACE_STATUS_RESET);

const iepWorkspaceStatusSuccess = createAction(IEP_WORKSPACE_STATUS_SUCCESS);
const iepWorkspaceStatusFailure = createAction(IEP_WORKSPACE_STATUS_FAILURE);

export const iepWorkspaceStatusEpic = (actions$) => actions$.pipe(
  ofType(IEP_WORKSPACE_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_iep}/userIndicationList`, 'get')).pipe(
    map((res) => iepWorkspaceStatusSuccess(res)),
    catchError((err) => of(iepWorkspaceStatusFailure(err))),
  )),
);

export const iepWorkspaceStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(iepWorkspaceStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(iepWorkspaceStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepWorkspaceStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepWorkspaceStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
