import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';
import useAppSelector from '../../../../utils/redux-hook';
import { protacCheckStatusAction, generateDesignProtacAction } from './logic';
import Loader from '../../../../common/components/Loader';
import PendingStatusModal from '../../components/PendingStatusModal';
import { sendNotification } from '../../../../common/components/Notification/logic';

function ProtacInputPage() {
  const protacCheckStatusResponse = useAppSelector((state) => state.protacCheckStatusResponse);
  const generateDesignProtacResponse = useAppSelector((state) => state.generateDesignProtacResponse);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const proteinName = searchParams.get('proteinName');
  const [inputs, setInputs] = useState({
    id: '',
    card: '',
    protein_name: '',
  });
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(protacCheckStatusAction());
  }, []);

  useEffect(() => {
    if (protacCheckStatusResponse.flag && protacCheckStatusResponse.data.length > 0) {
      if (protacCheckStatusResponse.data[0]?.status === 'PENDING') {
        setIsPending(true);
      } else if (protacCheckStatusResponse.data[0]?.status === 'COMPLETED' && protacCheckStatusResponse.data[0]?.is_read !== true) {
        navigate(`${terminalsPath.metad3_protac_output}?jobId=${protacCheckStatusResponse.data[0].job_id}&service=${inputs.card}&proteinName=${inputs.protein_name}`);
      } else if (protacCheckStatusResponse.data[0]?.status === 'REJECTED') {
        setIsPending(false);
      }
    }
  }, [JSON.stringify(protacCheckStatusResponse)]);
  const handleClick = () => {
    const request = {
      service: inputs.card,
      protein_name: inputs.protein_name,
    };
    dispatch(generateDesignProtacAction(queryString.stringify(request)));
    setIsPending(true);
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (generateDesignProtacResponse.flag) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: generateDesignProtacResponse.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(generateDesignProtacResponse)]);
  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  const checkBtnDissabledForAccess = inputs.protein_name === '' || inputs.protein_name === null || inputs.card === '' || inputs.card === null;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setDisableButton(checkBtnDissabledForAccess);
  }, [inputs]);

  useEffect(() => {
    let interval = '';
    if (isSubmitted) {
      interval = setInterval(() => {
        dispatch(protacCheckStatusAction());
      }, 2000);
    }
    return () => {
      if (interval !== '') {
        clearInterval(interval);
      }
    };
  }, [isSubmitted]);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home protac-header" logo="protac" />
      <Loader loading={protacCheckStatusResponse.loading && !isSubmitted} error={protacCheckStatusResponse.error} height="800px" width="100%">
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Discover potential PROTACs with deep neural network model</div>
            <div className="main-description">Using our AI model you can discover and prioritise list of Protacs by providing the protein information.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service !== '' ? service : ''} />
          <InputBox inputHandler={getInputs} proteinName={proteinName} isDisabled={inputs.card === ''} />
          <div className="ct-home-page-footer">
            <button onClick={() => handleClick()} disabled={disableButton} className="generate-in-btn" type="button">
              DESIGN PROTAC
            </button>
          </div>
        </div>
        <PendingStatusModal open={isPending} />
      </Loader>
    </div>
  );
}

export default ProtacInputPage;
