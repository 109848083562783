import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer, Cell, PieChart, Pie,
} from 'recharts';

function CircularGauge({ value }) {
  const width = 200;
  const height = 160;
  const chartValue = Number(value);
  const slices = [
    {
      value: chartValue,
      color: '#3B76EF',
    },
    {
      value: 1 - chartValue,
      color: '#D8D8D8',
    },
  ];

  const pieProps = {
    startAngle: 225,
    endAngle: -45,
    cx: width / 2,
    cy: width / 2,
    isAnimationActive: false,
  };

  const labelAtCenter = () => (
    <div className="content-at-center">
      <div className="content-at-center-title">Overall Score</div>
      <div className="content-at-center-value">{chartValue}</div>
      <div className={value > 0.6 ? 'content-at-center-tag high' : value <= 0.6 && value > 0.4 ? 'content-at-center-tag medium' : 'content-at-center-tag low'}>{value > 0.6 ? 'High' : value <= 0.6 && value > 0.4 ? 'Medium' : 'Low'}</div>
    </div>
  );

  return (
    <div style={{ width, height: 160 }}>
      <ResponsiveContainer>
        <div className="pie-chart">
          <PieChart width={width} height={height} layout="centric">
            {labelAtCenter()}
            <Pie
              stroke="none"
              data={slices}
              innerRadius={(width / 2) * 0.6}
              outerRadius={(width / 2) * 0.7}
              markerStart="0"
              markerEnd="1"
              markerMid="5"
              fill="56"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...pieProps}
            >
              {slices.map((each, i) => (
                <Cell key={crypto.randomUUID()} fill={slices[i].color} stroke="none" />
              ))}
            </Pie>
          </PieChart>
          <div className="pie-chart-indicators" style={{ display: 'flex', width: '100px', justifyContent: 'space-between' }}>
            <div className="pie-chart-indicators-start">0</div>
            <div className="pie-chart-indicators-end">1</div>
          </div>
        </div>
        <div className="overall-score-label">{labelAtCenter()}</div>
      </ResponsiveContainer>
    </div>
  );
}

CircularGauge.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularGauge;
