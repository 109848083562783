import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_PATHWAY_ENRICHMENT = 'GET_PATHWAY_ENRICHMENT';
const GET_PATHWAY_ENRICHMENT_SUCCESS = 'GET_PATHWAY_ENRICHMENT_SUCCESS';
const GET_PATHWAY_ENRICHMENT_FAILURE = 'GET_PATHWAY_ENRICHMENT_FAILURE';

export const getPathwayEnrichmentAction = createAction(GET_PATHWAY_ENRICHMENT);
const getPathwayEnrichmentSuccess = createAction(GET_PATHWAY_ENRICHMENT_SUCCESS);
const getPathwayEnrichmentFailure = createAction(GET_PATHWAY_ENRICHMENT_FAILURE);

export const getPathwayEnrichmentEpic = (actions$) => actions$.pipe(
  ofType(GET_PATHWAY_ENRICHMENT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/pathwayGraphData?${action.payload}`, 'get')).pipe(
    map((res) => getPathwayEnrichmentSuccess(res)),
    catchError((err) => of(getPathwayEnrichmentFailure(err))),
  )),
);

export const getPathwayEnrichmentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getPathwayEnrichmentAction, (state) => {
      state.loading = true;
    })
      .addCase(getPathwayEnrichmentSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getPathwayEnrichmentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
