import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDropdown from '../../components/InputDropdown';

function InputBox({ inputHandler, diseaseName, isDisabled }) {
  const [values, setValues] = useState({});
  const [defaultDiseaseName, setDefaultDiseaseName] = useState('');

  useEffect(() => {
    inputHandler(values);
  }, [values]);

  useEffect(() => {
    setDefaultDiseaseName(diseaseName || '');
  }, [diseaseName]);

  const handleChange = (value) => {
    setValues((prev) => ({ ...prev, disease_name: value }));
  };

  const dropdownRenderer = () => (
    <div className="dropdown-container">
      <InputDropdown index={0} changeHandler={handleChange} diseaseName={defaultDiseaseName} isDisabled={isDisabled} setDiseaseName={setDefaultDiseaseName} />
      <div className="input-fields-value try-example-section">
        <text className="try-example-section-title">Try Example: </text>
        <text
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() => {
            setDefaultDiseaseName('colorectal neoplasm');
          }}
        >
          Colorectal Neoplasm
        </text>
      </div>
    </div>
  );

  return (
    <div className="provide-input-container ti-provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">{dropdownRenderer()}</div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  diseaseName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputBox;
