import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputCard from '../../components/InputCard';

function SelectService({ inputHandler, service }) {
  const [card, setCard] = useState([-1, -1]);
  const services = ['APPROVED_DRUG', 'PHASE_1_OR_2_CLINICAL_DRUG'];

  useEffect(() => {
    services.forEach((item, index) => {
      if (item === service) {
        setCard((prev) => {
          const newCard = prev;
          newCard[index] = 1;
          return newCard;
        });
        inputHandler({ card: services[index] });
      }
    });
  }, []);

  const handleCard = (index) => {
    setCard((prev) => {
      const newCard = prev.map((item, i) => {
        if (index === i) {
          return 1;
        }
        return 0;
      });
      return newCard;
    });
    inputHandler({ card: services[index] });
  };
  return (
    <div className="service-container service-container-dr">
      <div className="cards-container">
        <div className="cards-container-title">Select a Drug Type</div>
        <div className="cards-list">
          <InputCard index={0} heading=" Approved Drug" cardHandler={handleCard} isSelected={card[0]} colorClass="card-one" img="approved_drug" status="Open" />
          <InputCard index={1} heading=" Phase 1/2 Clinical Drug" cardHandler={handleCard} isSelected={card[1]} colorClass="card-two" img="clinical_drug" status="Coming soon" />
        </div>
      </div>
    </div>
  );
}

SelectService.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
};

export default SelectService;
