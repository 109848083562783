/* eslint-disable */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
// import { isReadIEPOutputAction } from './logic';
import { iepCheckStatusAction } from '../IEPInputPage/logic';
import TopIndicationsPage from '../TopIndicationsPage';

function IEPOutputPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');

  useEffect(() => {
    // const request = {
    //   job_id: jobId,
    //   is_read: true,
    // };
    // dispatch(isReadIEPOutputAction(queryString.stringify(request)));
    dispatch(iepCheckStatusAction());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="iep-logo" isIEP />
      <TopIndicationsPage jobId={jobId} />
    </div>
  );
}

export default IEPOutputPage;
