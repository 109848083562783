import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import optionsTags from './data';
import useAppSelector from '../../../utils/redux-hook';
import { sendNotification } from '../../../common/components/Notification/logic';
import { requestDemoAction } from './logic';

function ModalRequestDemo({
  setOpen, open, isAccess, module,
}) {
  const dispatch = useDispatch();
  const [currentRequirement, setCurrentRequirement] = useState(
    isAccess ? [`${module}`] : [],
  );
  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validCompany, setValidCompany] = useState(true);
  const [validDesignation, setValidDesignation] = useState(true);
  const [requestDemoButtonDissabled, setRequestDemoButtonDissabled] = useState(true);

  const statusRequestDemo = useAppSelector((state) => state.statusRequestDemo);

  const [userInfo, setUserDetails] = useState({
    userName: '',
    companyName: '',
    emailId: '',
    designation: '',
    requirement: '',
  });

  useEffect(() => {
    if (
      statusRequestDemo.flag
      && statusRequestDemo.data === 'Demo Requested Successfully'
    ) {
      setOpen(false);
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: statusRequestDemo.data,
          showNotification: true,
        }),
      );
    }

    if (statusRequestDemo.error) {
      setOpen(false);
    }
  }, [statusRequestDemo]);

  const checkBtnDissabled = !validName
    || !validEmail
    || !validDesignation
    || !validCompany
    || currentRequirement.length === 0;

  const checkBtnDissabledForAccess = !validName || !validEmail || !validDesignation || !validCompany;

  useEffect(() => {
    setRequestDemoButtonDissabled(
      isAccess ? !checkBtnDissabledForAccess : !checkBtnDissabled,
    );
  }, [
    validName,
    validEmail,
    validDesignation,
    currentRequirement,
    validCompany,
  ]);

  const handleCancel = () => {
    const temp = {
      userName: '',
      companyName: '',
      emailId: '',
      designation: '',
      requirement: '',
    };
    setUserDetails({ ...temp });
    setCurrentRequirement([]);
    setValidEmail(true);
    setValidName(true);
    setValidCompany(true);
    setValidDesignation(true);
    setOpen(false);
  };

  useEffect(() => {
    const emptyCheck = userInfo.userName === '' || userInfo.companyName === '' || userInfo.designation === '' || userInfo.emailId === '' || currentRequirement.length === 0;
    setRequestDemoButtonDissabled(checkBtnDissabled || emptyCheck);
  }, [validName, validEmail, validDesignation, JSON.stringify(currentRequirement), JSON.stringify(userInfo)]);

  const handleChange = (e, type) => {
    const temp = userInfo;
    switch (type) {
      case 'name':
        temp.userName = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'company':
        temp.companyName = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'email':
        temp.emailId = e.target.value;
        setUserDetails({ ...temp });
        break;
      case 'designation':
        temp.designation = e.target.value;
        setUserDetails({ ...temp });
        break;
      default:
        break;
    }
  };

  const callApi = () => {
    dispatch(
      requestDemoAction({
        params: {
          user_name: userInfo.userName,
          email: userInfo.emailId,
          organization_name: userInfo.companyName,
          designation: userInfo.designation,
          requirements: userInfo.requirement,
        },
      }),
    );
    handleCancel();
  };

  const updateAll = () => {
    const temp = userInfo;
    currentRequirement.forEach((requirement) => {
      temp.requirement += `${requirement},`;
    });
    setUserDetails({ ...temp });
    setTimeout(() => callApi(), 50);
  };

  const handleClick = (value) => {
    if (currentRequirement.indexOf(value) > -1) {
      const temp = currentRequirement.filter((item) => item !== value);
      setCurrentRequirement([...temp]);
    } else {
      setCurrentRequirement([...currentRequirement, value]);
    }
  };

  const renderRequirements = () => optionsTags.map((requirement) => {
    if (currentRequirement.indexOf(requirement.value) > -1) {
      return (
        <div
          key={requirement.label}
          aria-hidden
          onClick={() => handleClick(requirement.value)}
          className="tag active"
        >
          {requirement.value}
        </div>
      );
    }
    return (
      <div
        className="tag"
        key={requirement.label}
        aria-hidden
        onClick={() => handleClick(requirement.value)}
      >
        {requirement.value}
      </div>
    );
  });

  const handleNameValidation = () => {
    const check = /^[A-Za-z\s]+$/;
    setValidName(check.test(userInfo.userName) && userInfo.userName !== '');
  };

  const handleCompanyNameValidation = () => {
    setValidCompany(userInfo.companyName !== '');
  };

  const handleDesignationValidation = () => {
    const check = /^[A-Za-z\s]+$/;
    setValidDesignation(
      check.test(userInfo.designation) && userInfo.designation !== '',
    );
  };

  const handleEmailIdValidation = () => {
    const check = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(check.test(userInfo.emailId));
  };

  return (
    <Modal
      open={open}
      footer={false}
      onCancel={() => handleCancel()}
      closable
      className="request-demo-modal"
    >
      <div className="request-demo-modal-title">
        {isAccess ? `Request access for ${module}` : 'Request a Demo'}
      </div>
      <div className="request-demo-modal-input">
        <Input
          placeholder="Full name*"
          onBlur={handleNameValidation}
          value={userInfo?.userName}
          onChange={(e) => handleChange(e, 'name')}
          label="Full name*"
        />
        <span className="not-valid">
          {`${validName ? '' : 'Please enter valid full name'}`}
        </span>
      </div>
      <div className="request-demo-modal-input">
        <Input
          placeholder="Enter your company name *"
          onBlur={handleCompanyNameValidation}
          value={userInfo?.companyName}
          onChange={(e) => handleChange(e, 'company')}
          label="Enter your company name*"
        />
        <span className="not-valid">
          {`${validCompany ? '' : 'Please enter valid company name'}`}
        </span>
      </div>
      <div className="request-demo-modal-input">
        <Input
          placeholder="Enter your email id*"
          onBlur={handleEmailIdValidation}
          value={userInfo?.emailId}
          onChange={(e) => handleChange(e, 'email')}
          label="Enter your email id*"
        />
        <span className="not-valid">
          {`${validEmail ? '' : 'Please enter valid email'}`}
        </span>
      </div>
      <div className="request-demo-modal-input">
        <Input
          placeholder="Enter your designation*"
          onBlur={handleDesignationValidation}
          value={userInfo?.designation}
          onChange={(e) => handleChange(e, 'designation')}
          label="Enter your designation*"
        />
        <div className="not-valid">
          {`${validDesignation ? '' : 'Please enter designation'}`}
        </div>
      </div>
      {!isAccess && (
        <div className="request-demo-modal-req">
          <div className="request-demo-modal-req-title">
            About your requirement*
          </div>
          <div className="requirements-container">{renderRequirements()}</div>
        </div>
      )}
      <div className="request-demo-modal-req-btn">
        <Button
          disabled={requestDemoButtonDissabled}
          onClick={() => updateAll()}
        >
          {isAccess ? 'Request access' : 'Request a Demo'}
        </Button>
      </div>
    </Modal>
  );
}

ModalRequestDemo.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isAccess: PropTypes.bool.isRequired,
  module: PropTypes.string.isRequired,
};

export default ModalRequestDemo;
