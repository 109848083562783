import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputDropdown from '../../components/InputDropdown';

function InputBox({ inputHandler, payload, isDisabled }) {
  const [smile, setSmile] = useState('');
  const [tryExample, setTryExample] = useState('');

  useEffect(() => {
    const inputs = {
      smile,
    };
    inputHandler(inputs);
  }, [smile]);
  const handleChange = (value) => {
    setSmile((prev) => ({ ...prev, id: value }));
  };

  return (
    <div className="provide-input-container">
      <div className={`input-container ${isDisabled ? 'disabled' : ''}`}>
        <div className="input-container-title">Provide Input</div>
        <div className="input-fields">
          <div className="input-fields-value">
            <div>
              <div className="input-text">
                <InputDropdown index={0} changeHandler={handleChange} tryExample={tryExample} setTryExample={setTryExample} payload={payload} isDisabled={isDisabled} />
              </div>
            </div>
          </div>
          <div className="input-fields-value try-example-section">
            <text className="try-example-section-title">Try Example: </text>
            <text
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => {
                if (!isDisabled) {
                  setTryExample('Mertansine DM1');
                }
              }}
            >
              Mertansine DM1
            </text>
          </div>
        </div>
      </div>
    </div>
  );
}

InputBox.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  payload: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default InputBox;
