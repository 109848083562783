import React from 'react';
import PropTypes from 'prop-types';

function StudyDetails({ study, displayFilters }) {
  const displaySamples = (temp) => (
    <div className="study-samples-container">
      {temp?.map((item) => (
        <div key={item} className="study-sample-item">
          {item}
        </div>
      ))}
    </div>
  );
  return (
    <div className={`study-details-container ${displayFilters ? 'hide' : 'show'}`}>
      <div className="study-details-main">
        <div className="published-date-row">
          <div className="published-date-text">{study.status}</div>
          <a aria-label="Redirect" target="_blank" rel="noopener noreferrer" href={study?.link}>
            <div className="redirect-icon" />
          </a>
        </div>
        <div className="study-title-text">{study.title}</div>
        <div className="study-details-samples-row">
          <div className="samples-title">Samples:</div>
          {displaySamples(study.sample)}
        </div>
        <div className="study-details-summary-row">
          <div className="summary-title">Summary:</div>
          <div className="summary-value">{study.description}</div>
        </div>
      </div>
    </div>
  );
}
StudyDetails.propTypes = {
  study: PropTypes.objectOf.isRequired,
  displayFilters: PropTypes.bool.isRequired,
};
export default StudyDetails;
