const data = [
  {
    name: 'Potentially Druggable Targets',
    children: [
      {
        name: 'NCT03614663',
        children: [
          {
            name: 'fragile x syndrome',
            value: 212,
          },
        ],
      },
      {
        name: 'NCT03802799',
        children: [
          {
            name: 'fragile x syndrome',
            value: 450,
          },
        ],
      },
    ],
  },
  {
    name: 'Challenging Targets',
    children: [
      {
        name: 'NCT03573518',
        children: [
          {
            name: 'acne vulgaris',
            value: 368,
          },
        ],
      },
    ],
  },
  {
    name: 'Placebo Transdermal Gel',
    children: [
      {
        name: 'NCT03614663',
        children: [
          {
            name: 'fragile x syndrome',
            value: 212,
          },
        ],
      },
    ],
  },
];

export default data;
