import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_CLINICAL_TRIAL_ENRICHMENT_DATA = 'GET_CLINICAL_TRIAL_ENRICHMENT_DATA';
const GET_CLINICAL_TRIAL_ENRICHMENT_DATA_SUCCESS = 'GET_CLINICAL_TRIAL_ENRICHMENT_DATA_SUCCESS';
const GET_CLINICAL_TRIAL_ENRICHMENT_DATA_FAILURE = 'GET_CLINICAL_TRIAL_ENRICHMENT_DATA_FAILURE';

export const getClinicalTrialEnrichmentAction = createAction(GET_CLINICAL_TRIAL_ENRICHMENT_DATA);
const getClinicalTrialEnrichmentSuccess = createAction(GET_CLINICAL_TRIAL_ENRICHMENT_DATA_SUCCESS);
const getClinicalTrialEnrichmentFailure = createAction(GET_CLINICAL_TRIAL_ENRICHMENT_DATA_FAILURE);

export const getClinicalTrialEnrichmentEpic = (actions$) => actions$.pipe(
  ofType(GET_CLINICAL_TRIAL_ENRICHMENT_DATA),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/clinicalTrialGraphData?${action.payload}`, 'get')).pipe(
    map((res) => getClinicalTrialEnrichmentSuccess(res)),
    catchError((err) => of(getClinicalTrialEnrichmentFailure(err))),
  )),
);

export const getClinicalTrialEnrichmentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getClinicalTrialEnrichmentAction, (state) => {
      state.loading = true;
    })
      .addCase(getClinicalTrialEnrichmentSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getClinicalTrialEnrichmentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
