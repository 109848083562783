const color = {
  cases: ['#419CCF', '#BEE7FE'],
  controls: ['#985BCA', '#EEDAFF'],
  control: ['#985BCA', '#EEDAFF'],
  treated: ['#0A7E4A', '#A8F8D4'],
  knockin: ['#C3753D', '#FDD9BF'],
  others: ['#3469D4', '#BCD1FD'],
};

export default color;
