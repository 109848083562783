import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';

function DCInputPage() {
  const [searchParams] = useSearchParams();
  const disease = searchParams.get('disease');
  const service = searchParams.get('service');
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const handleClick = () => {
    navigate(`${terminalsPath.metad3_dc_output}?service=${inputs.card}&disease=${inputs.id}`);
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  const checkBtnDissabledForAccess = inputs.id === '' || inputs.id === undefined || inputs.card === '' || inputs.card === undefined;

  useEffect(() => {
    setDisableButton(checkBtnDissabledForAccess);
  }, [inputs]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home" logo="dc-logo" width={400} />
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="main-title">Drug combination synergy score prediction</div>
          <div className="main-description">Using our AI model, you can predict the drug combinations and their synergy probability score, for 3 use cases.</div>
        </div>
        <SelectService inputHandler={getInputs} service={service} />
        <InputBox inputHandler={getInputs} cardState={inputs.card} disease={disease !== null ? disease : ''} isDisabled={inputs.card === '' || inputs.card === undefined} />
        <div className="ct-home-page-footer">
          <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
            GENERATE DRUG COMBINATIONS
          </button>
        </div>
      </div>
    </div>
  );
}

export default DCInputPage;
