import React from 'react';
import PropTypes from 'prop-types';
import CarouselLandingPage from '../CarouselLandingPage';

function MainInfo({ showReqestDemo }) {
  return (
    <div className="maininfo-container">
      <CarouselLandingPage requestDemo={() => showReqestDemo(true)} />
    </div>
  );
}

MainInfo.propTypes = {
  showReqestDemo: PropTypes.func.isRequired,
};

export default MainInfo;
