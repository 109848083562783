/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from '../../../common/components/Loader';
import Card from '../../components/Card';
import { checkPermission, setCookie } from '../../../utils';
import { getTerminalsAction, usersMeAction } from './logic';
import useAppSelector from '../../../utils/redux-hook';

function Selection({ setIsOpenRequestDemo, getModule }) {
  const dispatch = useDispatch();
  const terminals = useAppSelector((state) => state.terminals);
  const [isRequestListEmpty, setIsRequestListEmpty] = useState(false);
  const [isComingSoonListEmpty, setIsComingSoonListEmpty] = useState(false);
  const check = (appName) => checkPermission(appName);
  const usersMeResponse = useAppSelector((state) => state.usersMeResponse);

  const label = {
    metad3_ctp: 'terminal-cardtop ctp',
    metad3_biomarker: 'terminal-cardtop bm',
    metad3_adc: 'terminal-cardtop adc',
    metad3_dc: 'terminal-cardtop dc',
    metad3_target_id: 'terminal-cardtop ti',
    metad3_dr: 'terminal-cardtop dr',
    metad3_sar_denovo: 'terminal-cardtop sar',
    metad3_iep: 'terminal-cardtop iep',
    metad3_protac: 'terminal-cardtop protac',
  };

  let timer = null;
  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      dispatch(getTerminalsAction());
    }, 50);
    dispatch(usersMeAction());
  }, []);
  useEffect(() => {
    if (usersMeResponse.flag) {
      setCookie(usersMeResponse.data.profile.email, 'usersEmailId');
    }
  }, [usersMeResponse.data]);
  const renderCards2 = (type) => {
    let newData = [];
    const sortedList = new Array(9);
    /* eslint-disable */
    if (terminals.flag) {
      const orderList = ['metad3_target_id', 'metad3_biomarker', 'metad3_sar_denovo', 'metad3_adc', 'metad3_iep', 'metad3_dr', 'metad3_dc', 'metad3_protac', 'metad3_ctp'];
      terminals.data.map((item) => {
        for (let i = 0; i < 9; i++) {
          if (item.module === orderList[i]) {
            sortedList[i] = item;
          }
        }
      });
    }

    if (type === 'Coming Soon') {
      newData = sortedList.filter((item) => item.status === type);
      if (newData.length === 0) {
        setIsComingSoonListEmpty(true);
      }
    } else if (type === 'locked') {
      newData = sortedList.filter((item) => item.status === 'Active' && !check(item.module));
      if (newData.length === 0) {
        setIsRequestListEmpty(true);
      }
    } else {
      newData = sortedList.filter((item) => item.status === 'Active' && check(item.module));
    }

    return newData.map((item) => (
      // eslint-disable-next-line no-underscore-dangle
      <div key={crypto.randomUUID()} className={label[item.module]}>
        {type === 'unlocked' && <Card key={`${item.id}_${item.module}`} terminal={item.module} description={item.description} type={type} status={item.status} setOpen={() => setIsOpenRequestDemo(true)} isBack={false} getModule={getModule} />}
        {type !== 'unlocked' && (
          <div className="card-flip">
            <div className="side front">
              <Card key={`${item.id}_${item.module}`} terminal={item.module} description={item.description} type={type} status={item.status} setOpen={() => setIsOpenRequestDemo(true)} isBack={false} getModule={getModule} />
            </div>
            <div className="side back">
              <Card key={`${item.id}_${item.module}`} terminal={item.module} description={item.description} type={type} status={item.status} setOpen={() => setIsOpenRequestDemo(true)} isBack getModule={getModule} />
            </div>
          </div>
        )}
      </div>
    ));
  };
  return (
    <Loader
      height="84vh"
      className="black-loader"
    >
      <div className="home-page">
        <div className="home-bg-img">
          <div className="blue-bg-eclips-main" />
          <div className="blue-bg-eclips-left" />
          <div className="blue-bg-eclips-right" />
          <div className="blue-bg-eclips-left-bottom" />
          <div className="blue-bg-eclips-right-bottom" />
        </div>
        <div className="home-page-content">
          <div className="unclocked">
            <div className="header header-top">Optimize Your Drug Discovery With Our Modules</div>
            <Loader loading={terminals.loading} error={terminals.error} height="478px" width="100%">
              <div className="terminal-container">{renderCards2('unlocked')}</div>
            </Loader>
          </div>
          {!isRequestListEmpty && (
            <div className="unclocked">
              <div className="header header-middle">Unlock Additional Terminals Upon Access</div>
              <Loader loading={terminals.loading} error={terminals.error} height="455px" width="400px">
                <div className="terminal-container">{renderCards2('locked')}</div>
              </Loader>
            </div>
          )}
          {!isComingSoonListEmpty && (
            <div className="unclocked">
              <div className="header header-bottom">Coming Soon!</div>
              <Loader loading={terminals.loading} error={terminals.error} height="455px" width="400px">
                <div className="terminal-container">{renderCards2('Coming Soon')}</div>
              </Loader>
            </div>
          )}
        </div>
      </div>
    </Loader>
  );
}

Selection.propTypes = {
  setIsOpenRequestDemo: PropTypes.func.isRequired,
  getModule: PropTypes.func.isRequired,
};

export default Selection;
