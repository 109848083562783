import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

const GENERATE_CTP_INPUT_SUGGESTIONS = 'GENERATE_CTP_INPUT_SUGGESTIONS';
const GENERATE_CTP_INPUT_SUGGESTIONS_SUCCESS = 'GENERATE_CTP_INPUT_SUGGESTIONS_SUCCESS';
const GENERATE_CTP_INPUT_SUGGESTIONS_FAILURE = 'GENERATE_CTP_INPUT_SUGGESTIONS_FAILURE';

export const generateCTPInputsAction = createAction(GENERATE_CTP_INPUT_SUGGESTIONS);

const generateCTPInputsSuccess = createAction(GENERATE_CTP_INPUT_SUGGESTIONS_SUCCESS);

const generateCTPInputsFaliure = createAction(GENERATE_CTP_INPUT_SUGGESTIONS_FAILURE);

export const generateCTPInputsEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_CTP_INPUT_SUGGESTIONS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/autoSuggest?${action.payload}`, 'get')).pipe(
    map((res) => generateCTPInputsSuccess(res)),
    catchError((err) => of(generateCTPInputsFaliure(err))),
  )),
);
export const generateCTPInputsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateCTPInputsAction, (state) => {
        state.loading = true;
      })
      .addCase(generateCTPInputsSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateCTPInputsFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
