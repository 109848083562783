import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status
const IEP_EDIT_CHECK_STATUS = 'IEP_EDIT_CHECK_STATUS';
const IEP_EDIT_CHECK_STATUS_SUCCESS = 'IEP_EDIT_CHECK_STATUS_SUCCESS';
const IEP_EDIT_CHECK_STATUS_FAILURE = 'IEP_EDIT_CHECK_STATUS_FAILURE';
const IEP_EDIT_CHECK_STATUS_RESET = 'IEP_EDIT_CHECK_STATUS_RESET';

export const iepEditCheckStatusAction = createAction(IEP_EDIT_CHECK_STATUS);
export const iepEditCheckStatusResetAction = createAction(IEP_EDIT_CHECK_STATUS_RESET);

const iepEditCheckStatussSuccess = createAction(IEP_EDIT_CHECK_STATUS_SUCCESS);
const iepEditCheckStatusFailure = createAction(IEP_EDIT_CHECK_STATUS_FAILURE);

export const iepEditCheckStatusEpic = (actions$) => actions$.pipe(
  ofType(IEP_EDIT_CHECK_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_iep}/userIndicationList`, 'get')).pipe(
    map((res) => iepEditCheckStatussSuccess(res)),
    catchError((err) => of(iepEditCheckStatusFailure(err))),
  )),
);

export const iepEditCheckStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(iepEditCheckStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(iepEditCheckStatussSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(iepEditCheckStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(iepEditCheckStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
