import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get input suggest details
const GET_INPUT_SUGGEST_PROTAC = 'GET_INPUT_SUGGEST_PROTAC';
const GET_INPUT_SUGGEST_SUCCESS_PROTAC = 'GET_INPUT_SUGGEST_SUCCESS_PROTAC';
const GET_INPUT_SUGGEST_FAILURE_PROTAC = 'GET_INPUT_SUGGEST_FAILURE_PROTAC';

export const getInputSuggestProtacAction = createAction(GET_INPUT_SUGGEST_PROTAC);

const getInputSuggestProtacSuccess = createAction(GET_INPUT_SUGGEST_SUCCESS_PROTAC);

const getInputSuggestProtacFailure = createAction(GET_INPUT_SUGGEST_FAILURE_PROTAC);
export const getInputSuggestProtacEpic = (actions$) => actions$.pipe(
  ofType(GET_INPUT_SUGGEST_PROTAC),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_protac}/inputSuggest?${action.payload}`, 'get')).pipe(
    map((res) => getInputSuggestProtacSuccess(res)),
    catchError((err) => of(getInputSuggestProtacFailure(err))),
  )),
);

export const getInputSuggestProtacReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getInputSuggestProtacAction, (state) => {
        state.loading = true;
      })
      .addCase(getInputSuggestProtacSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getInputSuggestProtacFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
