import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveWaffle } from '@nivo/waffle';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../../utils/redux-hook';
import { getTargetVisibilityOutputAction } from './logic';

function TargetVisibilityView({ jobId }) {
  const getTargetVisibilityResponse = useAppSelector((state) => state.getTargetVisibilityResponse);
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getTargetVisibilityOutputAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getTargetVisibilityResponse.flag) {
      setGraphData(getTargetVisibilityResponse.data.data);
    }
  }, [JSON.stringify(getTargetVisibilityResponse)]);

  return (
    <div className="target-visuality-main">
      <div className="target-visuality-title graph-card-title">Target Visibility</div>
      <div className="target-visibility-legends" style={{ display: 'flex' }}>
        <div className="legend-novel-target">
          <div className="legend-symbol" />
          <div className="legend-value-label">
            <div className="legend-value">
              <div className="value-abs">{graphData[1]?.count}</div>
              <div className="value-percent">{`${graphData[1]?.value}%`}</div>
            </div>
            <div className="legend-label">Novel Targets</div>
          </div>
        </div>
        <div className="legend-known-target">
          <div className="legend-symbol" />
          <div className="legend-value-label">
            <div className="legend-value">
              <div className="value-abs">{graphData[0]?.count}</div>
              <div className="value-percent">{`${graphData[0]?.value}%`}</div>
            </div>
            <div className="legend-label">Known Targets</div>
          </div>
        </div>
      </div>
      <div className="waffle-chart" style={{ height: 290, width: 400 }}>
        <ResponsiveWaffle
          borderRadius={2}
          colors={{
            datum: 'color',
          }}
          columns={10}
          data={graphData}
          fillDirection="bottom"
          padding={2}
          rows={10}
          testIdPrefix="waffle"
          total={100}
        />
      </div>
    </div>
  );
}

TargetVisibilityView.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default TargetVisibilityView;
