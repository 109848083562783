import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// get input suggest details
const GET_STUDIES = 'GET_STUDIES';
const GET_STUDIES_SUCCESS = 'GET_STUDIES_SUCCESS';
const GET_MORE_STUDIES = 'GET_MORE_STUDIES';
const GET_MORE_STUDIES_SUCCESS = 'GET_MORE_STUDIES_SUCCESS';
const GET_STUDIES_FAILURE = 'GET_STUDIES_FAILURE';

export const getStudiesAction = createAction(GET_STUDIES);

const getStudiesSuccess = createAction(GET_STUDIES_SUCCESS);

export const getMoreStudiesAction = createAction(GET_MORE_STUDIES);

const getMoreStudiesSuccess = createAction(GET_MORE_STUDIES_SUCCESS);

const getStudiesFailure = createAction(GET_STUDIES_FAILURE);
export const getStudiesEpic = (actions$) => actions$.pipe(
  ofType(GET_STUDIES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/studies?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getStudiesSuccess(res)),
    catchError((err) => of(getStudiesFailure(err))),
  )),
);
export const getMoreStudiesEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_STUDIES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/studies?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getMoreStudiesSuccess(res)),
    catchError((err) => of(getStudiesFailure(err))),
  )),
);

export const getStudiesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getStudiesAction, (state) => {
        state.loading = true;
      })
      .addCase(getStudiesSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.total = action.payload.response.total;
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreStudiesAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreStudiesSuccess, (state, action) => {
        state.data = [...state.data, ...action.payload.response.data];
        state.total = action.payload.response.total;
        state.loading = false;
        state.flag = true;
      })
      .addCase(getStudiesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
