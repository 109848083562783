import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_BIOMARKER_OUTPUT = 'GET_BIOMARKER_OUTPUT';
const GET_BIOMARKER_OUTPUT_SUCCESS = 'GET_BIOMARKER_OUTPUT_SUCCESS';
const GET_BIOMARKER_OUTPUT_FAILURE = 'GET_BIOMARKER_OUTPUT_FAILURE';
const GET_MORE_BIOMARKER_OUTPUT = 'GET_MORE_BIOMARKER_OUTPUT';
const GET_MORE_BIOMARKER_OUTPUT_SUCCESS = 'GET_MORE_BIOMARKER_OUTPUT_SUCCESS';

export const getBiomakerOutputAction = createAction(GET_BIOMARKER_OUTPUT);
const getBiomarkerOutputSuccess = createAction(GET_BIOMARKER_OUTPUT_SUCCESS);
const getBiomarkerOutputFaliure = createAction(GET_BIOMARKER_OUTPUT_FAILURE);
export const getMoreBiomarkerOutputAction = createAction(GET_MORE_BIOMARKER_OUTPUT);
const getMoreBiomarkerOutputSuccess = createAction(GET_MORE_BIOMARKER_OUTPUT_SUCCESS);

export const getBiomarkerOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_BIOMARKER_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/jobs/${action.payload.path}?${action.payload.query}`, 'get')).pipe(
    map((res) => getBiomarkerOutputSuccess(res)),
    catchError((err) => of(getBiomarkerOutputFaliure(err))),
  )),
);
export const getMoreBiomarkerOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_BIOMARKER_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/jobs/${action.payload.path}?${action.payload.query}`, 'get')).pipe(
    map((res) => getMoreBiomarkerOutputSuccess(res)),
    catchError((err) => of(getBiomarkerOutputFaliure(err))),
  )),
);
export const getBiomarkerOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getBiomakerOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getBiomarkerOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreBiomarkerOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreBiomarkerOutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: {
            ...state.data.data,
            data: [...state.data.data.data, ...action.payload.response.data.data],
          },
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getBiomarkerOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// isRead BIOMARKER
const IS_READ_BIOMARKER_OUTPUT = 'IS_READ_BIOMARKER_OUTPUT';
const IS_READ_BIOMARKER_OUTPUT_SUCCESS = 'IS_READ_BIOMARKER_OUTPUT_SUCCESS';
const IS_READ_BIOMARKER_OUTPUT_FAILURE = 'IS_READ_BIOMARKER_OUTPUT_FAILURE';

export const isReadBiomarkerOutputAction = createAction(IS_READ_BIOMARKER_OUTPUT);

const isReadBiomarkerOutputSuccess = createAction(IS_READ_BIOMARKER_OUTPUT_SUCCESS);

const isReadBiomarkerOutputFailure = createAction(IS_READ_BIOMARKER_OUTPUT_FAILURE);

export const isReadBiomarkerOutputEpic = (actions$) => actions$.pipe(
  ofType(IS_READ_BIOMARKER_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/jobs/update/${action.payload.path}?${action.payload.query}`, 'PUT')).pipe(
    map((res) => isReadBiomarkerOutputSuccess(res)),
    catchError((err) => of(isReadBiomarkerOutputFailure(err))),
  )),
);
export const isReadBiomarkerOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(isReadBiomarkerOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(isReadBiomarkerOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(isReadBiomarkerOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// volcano BIOMARKER
const VOLCANO_GRAPH_BIOMARKER_OUTPUT = 'VOLCANO_GRAPH_BIOMARKER_OUTPUT';
const VOLCANO_GRAPH_BIOMARKER_OUTPUT_SUCCESS = 'VOLCANO_GRAPH_BIOMARKER_OUTPUT_SUCCESS';
const VOLCANO_GRAPH_BIOMARKER_OUTPUT_FAILURE = 'VOLCANO_GRAPH_BIOMARKER_OUTPUT_FAILURE';

export const volcanoGraphBiomarkerOutputAction = createAction(VOLCANO_GRAPH_BIOMARKER_OUTPUT);

const volcanoGraphBiomarkerOutputSuccess = createAction(VOLCANO_GRAPH_BIOMARKER_OUTPUT_SUCCESS);

const volcanoGraphBiomarkerOutputFailure = createAction(VOLCANO_GRAPH_BIOMARKER_OUTPUT_FAILURE);

export const volcanoGraphBiomarkerOutputEpic = (actions$) => actions$.pipe(
  ofType(VOLCANO_GRAPH_BIOMARKER_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/jobs/volcano/${action.payload.path}`, 'GET')).pipe(
    map((res) => volcanoGraphBiomarkerOutputSuccess(res)),
    catchError((err) => of(volcanoGraphBiomarkerOutputFailure(err))),
  )),
);
export const volcanoGraphBiomarkerOutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(volcanoGraphBiomarkerOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(volcanoGraphBiomarkerOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(volcanoGraphBiomarkerOutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
