import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

// get Partner details
const REQUEST_DEMO = 'REQUEST_DEMO';
const REQUEST_DEMO_SUCCESS = 'REQUEST_DEMO_SUCCESS';
const REQUEST_DEMO_FAILURE = 'REQUEST_DEMO_FAILURE';

export const requestDemoAction = createAction(REQUEST_DEMO);

const requestDemoSuccess = createAction(REQUEST_DEMO_SUCCESS);

const getTerminalFaliure = createAction(REQUEST_DEMO_FAILURE);

export const requestDemoEpic = (actions$) => actions$.pipe(
  ofType(REQUEST_DEMO),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl}/v1/auth/requestDemo`, 'POST', action.payload.params)).pipe(
    map((res) => requestDemoSuccess(res)),
    catchError((err) => of(getTerminalFaliure(err))),
  )),
);

const requestDemoReducer = createReducer(
  {
    data: '',
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(requestDemoAction, (state) => {
      state.loading = true;
    })
      .addCase(requestDemoSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(getTerminalFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default requestDemoReducer;
