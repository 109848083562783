import React from 'react';
import PropTypes from 'prop-types';

function AbsorptionValuesComponent({ admetData, createItem }) {
  return (
    <div className="properties-container container-layout">
      <div className="container-layout-card">
        <div className="container-layout-card-title">Absorption Values Component</div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Properties', isHeader: true })}
          {createItem({ text: 'Values', isHeader: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Aqueous Solubility', tooltip: 'Aqueous Solubility' })}
          {createItem({ text: admetData?.Solubility_AqSolDB, isValue: true, unit: 'log mol/L' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Lipophilicity', tooltip: 'Lipophilicity' })}
          {createItem({ text: admetData?.Lipophilicity_AstraZeneca, isValue: true, unit: 'log-ratio' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Hydration Free Energy', tooltip: 'Hydration Free Energy' })}
          {createItem({ text: admetData?.HydrationFreeEnergy_FreeSolv, isValue: true, unit: 'kcal/mol' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Caco Permeability', tooltip: 'Caco Permeability' })}
          {createItem({ text: admetData?.Caco2_Wang, isValue: true, unit: 'cm/s' })}
        </div>
        <div className="properties-items container-layout-card-item top-line">
          {createItem({ text: 'Properties', isHeader: true })}
          {createItem({ text: 'Probability', tooltip: 'Probability', isHeader: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'HIA', tooltip: 'HIA' })}
          {createItem({ isGraph: true, value: admetData?.HIA_Hou })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Oral Bioavailability', tooltip: 'Oral Bioavailability' })}
          {createItem({ isGraph: true, value: admetData?.Bioavailability_Ma })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'PAMPA Permeability', tooltip: 'PAMPA Permeability' })}
          {createItem({ isGraph: true, value: admetData?.PAMPA_NCATS })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'P-glycoprotein Inhibition', tooltip: 'P-glycoprotein Inhibition' })}
          {createItem({ isGraph: true, value: admetData?.Pgp_Broccatelli })}
        </div>
      </div>
    </div>
  );
}
AbsorptionValuesComponent.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  createItem: PropTypes.func.isRequired,
};
export default AbsorptionValuesComponent;
