import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// generate Job Id
const GENERATE_SAR_LEAD = 'GENERATE_SAR_LEAD';
const GENERATE_SAR_LEAD_SUCCESS = 'GENERATE_SAR_LEAD_SUCCESS';
const GENERATE_SAR_LEAD_FAILURE = 'GENERATE_SAR_LEAD_FAILURE';

export const generateSARLeadAction = createAction(GENERATE_SAR_LEAD);

const generateSARLeadSuccess = createAction(GENERATE_SAR_LEAD_SUCCESS);

const generateSARLeadFailure = createAction(GENERATE_SAR_LEAD_FAILURE);
export const generateSARLeadEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_SAR_LEAD),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/generateLead?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => generateSARLeadSuccess(res)),
    catchError((err) => of(generateSARLeadFailure(err))),
  )),
);

export const generateSARLeadReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateSARLeadAction, (state) => {
        state.loading = true;
      })
      .addCase(generateSARLeadSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateSARLeadFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// upload a document
const UPLOAD_SMILE_DOCUMENT = 'UPLOAD_DOCUMENT';
const UPLOAD_SMILE_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
const UPLOAD_SMILE_DOCUMENT_FAILURE = 'UPLOAD_DOCUMENT_FAILURE';

export const uploadSmileDocumentAction = createAction(UPLOAD_SMILE_DOCUMENT);

const uploadSmileDocumentSuccess = createAction(UPLOAD_SMILE_DOCUMENT_SUCCESS);

const uploadSmileDocumentFailure = createAction(UPLOAD_SMILE_DOCUMENT_FAILURE);
export const uploadSmileDocumentEpic = (actions$) => actions$.pipe(
  ofType(UPLOAD_SMILE_DOCUMENT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/uploadSmileDocument?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => uploadSmileDocumentSuccess(res)),
    catchError((err) => of(uploadSmileDocumentFailure(err))),
  )),
);

export const uploadSmileDocumentReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(uploadSmileDocumentAction, (state) => {
        state.loading = true;
      })
      .addCase(uploadSmileDocumentSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(uploadSmileDocumentFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// get Job Id status
const CHECK_SAR_STATUS = 'CHECK_SAR_STATUS';
const CHECK_SAR_STATUS_SUCCESS = 'CHECK_SAR_STATUS_SUCCESS';
const CHECK_SAR_STATUS_FAILURE = 'CHECK_SAR_STATUS_FAILURE';
const CHECK_SAR_STATUS_RESET = 'CHECK_SAR_STATUS_RESET';

export const checkSARStatusAction = createAction(CHECK_SAR_STATUS);
export const checkSARStatusResetAction = createAction(CHECK_SAR_STATUS_RESET);

const checkSARStatusSuccess = createAction(CHECK_SAR_STATUS_SUCCESS);
const checkSARStatusFailure = createAction(CHECK_SAR_STATUS_FAILURE);

export const checkSARStatusEpic = (actions$) => actions$.pipe(
  ofType(CHECK_SAR_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_sar}/allLeads`, 'get')).pipe(
    map((res) => checkSARStatusSuccess(res)),
    catchError((err) => of(checkSARStatusFailure(err))),
  )),
);

export const checkSARStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(checkSARStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(checkSARStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkSARStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkSARStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
