import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import { getInputSynonymsAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import { sendNotification } from '../../../../common/components/Notification/logic';
import Loader from '../../../../common/components/Loader';
import Mixpanel from '../../../../utils/mixpanel';
import InputBox from '../../container/InputBox';
import SaveProjectModal from '../../container/SaveProjectModal';

function IEPInputPage() {
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const proteinName = searchParams.get('proteinName');
  const [disableButton, setDisableButton] = useState(true);
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [proteinsList, setProteinsList] = useState([]);
  const [requestBody, setRequestBody] = useState({});
  const [inputs, setInputs] = useState({
    id: '',
    card: '',
    protein_name: '',
  });
  const getInputSynonymsResponse = useAppSelector((state) => state.getInputSynonymsResponse);
  const generateIndicationsResponse = useAppSelector((state) => state.generateIndicationsResponse);
  useEffect(() => {
    if (getInputSynonymsResponse.data) {
      setProteinsList(getInputSynonymsResponse.data[0]?.uniqueSynonymsTerms);
    }
  }, [getInputSynonymsResponse]);
  const handleClick = () => {
    Mixpanel.track('iepGenerateIndicationsClick', {
      action: 'Generate Indications Click',
      inputs,
    });
    setRequestBody({
      params: {
        service: inputs.card,
        protein_name: inputs.protein_name,
      },
      body: {
        synonyms_net: proteinsList,
        approach_weightage: {
          literature_mining: 20,
          clinical_trial: 20,
          network_disease_similarity: 15,
          network_ppi: 15,
          network_molecular_function: 15,
          network_literature_mining: 15,
        },
      },
    });
    if (inputs.protein_name) {
      setIsSubmitted(true);
    }
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkBtnDisabledForAccess = inputs.protein_name === '' || inputs.card === '' || inputs.card === undefined || inputs.protein_name === undefined;

  useEffect(() => {
    setDisableButton(checkBtnDisabledForAccess);
  }, [inputs]);

  useEffect(() => {
    if (generateIndicationsResponse.flag && generateIndicationsResponse.data) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: generateIndicationsResponse.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(generateIndicationsResponse)]);

  const updateProteinItem = (item) => {
    if (item !== '') {
      const request = {
        uid: item.uid,
      };
      dispatch(getInputSynonymsAction(queryString.stringify(request)));
    }
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home iep-header" logo="iep-logo" isIEP />
      <Loader loading={false}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Discover potential indications with IEP</div>
            <div className="main-description">Using our AI model you can discover and prioritise list of potential indications by providing the protein information.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} />
          <InputBox inputHandler={getInputs} cardState={inputs.card} proteinName={proteinName !== null ? proteinName : ''} isDisabled={inputs.card === ''} serviceName={inputs.card} updateProteinItem={updateProteinItem} />
          <div className="ct-home-page-footer">
            <button onClick={handleClick} disabled={disableButton || getInputSynonymsResponse.loading} className="generate-in-btn" type="button">
              GENERATE INDICATIONS
            </button>
          </div>
        </div>
        <SaveProjectModal name={inputs.protein_name} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} />
      </Loader>
    </div>
  );
}

export default IEPInputPage;
