/* eslint-disable */
import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

function CustomPieChart({ score }) {
  const updatedScore = Number(Number(score).toFixed(1));
  const data = [
    { value: updatedScore, color: '#3B76EF' },
    { value: 100 - updatedScore, color: '#D9D9D9' },
  ];
  const iR = 80;
  const oR = 100;
  return (
    <PieChart width={200} height={200}>
      <Pie dataKey="value" startAngle={630} endAngle={270} data={data} innerRadius={iR} outerRadius={oR} fill="#8884d8" stroke="none">
        {data.map((entry, index) => (
          <>
            <Cell key={`cell-${index}`} fill={entry.color} />
            <Label value={`${data[0].value}%`} position="center" fill="#121417" fontSize={38} fontFamily="Montserrat" fontWeight="600" />
          </>
        ))}
      </Pie>
    </PieChart>
  );
}

export default CustomPieChart;
