import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Admet Output details
const GET_ADMET_OUTPUT = 'GET_ADMET_OUTPUT';
const GET_ADMET_OUTPUT_SUCCESS = 'GET_ADMET_OUTPUT_SUCCESS';
const GET_ADMET_OUTPUT_FAILURE = 'GET_ADMET_OUTPUT_FAILURE';

export const getAdmetOutputAction = createAction(GET_ADMET_OUTPUT);

const getAdmetOutputSuccess = createAction(GET_ADMET_OUTPUT_SUCCESS);

const getAdmetOutputFaliure = createAction(GET_ADMET_OUTPUT_FAILURE);

export const getAdmetOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_ADMET_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getAdmet?${action.payload}`, 'get')).pipe(
    map((res) => getAdmetOutputSuccess(res)),
    catchError((err) => of(getAdmetOutputFaliure(err))),
  )),
);
export const getAdmetOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getAdmetOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getAdmetOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getAdmetOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export const getAdmetCompareOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getAdmetOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getAdmetOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getAdmetOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
