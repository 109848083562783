import React from 'react';
import Logout from '../Logout';
import webAuth from '../../../utils/webAuth';

function ProfileModal() {
  return (
    <div className="pofile-sec">
      <Logout className="sign-out-sec pofile-sec-btn" webAuth={webAuth} />
    </div>
  );
}

export default ProfileModal;
