import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get generate Job Id
const GENERATE_TARGET = 'GENERATE_TARGET';
const GENERATE_TARGET_SUCCESS = 'GENERATE_TARGET_SUCCESS';
const GENERATE_TARGET_FAILURE = 'GENERATE_TARGET_FAILURE';

export const generateTargetAction = createAction(GENERATE_TARGET);

const generateTargetSuccess = createAction(GENERATE_TARGET_SUCCESS);

const generateTargetFaliure = createAction(GENERATE_TARGET_FAILURE);
export const generateTITargetEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_TARGET),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ti}/generateTarget?${action.payload}`, 'GET')).pipe(
    map((res) => generateTargetSuccess(res)),
    catchError((err) => of(generateTargetFaliure(err))),
  )),
);

export const generateTITargetReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateTargetAction, (state) => {
        state.loading = true;
      })
      .addCase(generateTargetSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateTargetFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// get Job Id status
const CHECK_TI_STATUS = 'CHECK_TI_STATUS';
const CHECK_TI_STATUS_SUCCESS = 'CHECK_TI_STATUS_SUCCESS';
const CHECK_TI_STATUS_FAILURE = 'CHECK_TI_STATUS_FAILURE';
const CHECK_TI_STATUS_RESET = 'CHECK_TI_STATUS_RESET';

export const checkTIStatusAction = createAction(CHECK_TI_STATUS);
export const checkTIStatusResetAction = createAction(CHECK_TI_STATUS_RESET);

const checkTIStatusSuccess = createAction(CHECK_TI_STATUS_SUCCESS);
const checkTIStatusFailure = createAction(CHECK_TI_STATUS_FAILURE);

export const checkTIStatusEpic = (actions$) => actions$.pipe(
  ofType(CHECK_TI_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_ti}/allTargets`, 'get')).pipe(
    map((res) => checkTIStatusSuccess(res)),
    catchError((err) => of(checkTIStatusFailure(err))),
  )),
);

export const checkTIStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(checkTIStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(checkTIStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkTIStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkTIStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
