import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

const GET_FILTERS = 'GET_FILTERS';
const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
const GET_FILTERS_FAILURE = 'GET_FILTERS_FAILURE';

export const getFiltersAction = createAction(GET_FILTERS);

const getFiltersSuccess = createAction(GET_FILTERS_SUCCESS);

const getFiltersFailure = createAction(GET_FILTERS_FAILURE);

export const getFiltersEpic = (actions$) => actions$.pipe(
  ofType(GET_FILTERS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/filters?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getFiltersSuccess(res)),
    catchError((err) => of(getFiltersFailure(err))),
  )),
);

export const getFiltersReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getFiltersAction, (state) => {
        state.loading = true;
      })
      .addCase(getFiltersSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getFiltersFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

const GET_SAMPLE_FILTERS = 'GET_SAMPLE_FILTERS';
const GET_SAMPLE_FILTERS_SUCCESS = 'GET_SAMPLE_FILTERS_SUCCESS';
const GET_SAMPLE_FILTERS_FAILURE = 'GET_SAMPLE_FILTERS_FAILURE';

export const getSampleFiltersAction = createAction(GET_SAMPLE_FILTERS);

const getSampleFiltersSuccess = createAction(GET_SAMPLE_FILTERS_SUCCESS);

const getSampleFiltersFailure = createAction(GET_SAMPLE_FILTERS_FAILURE);

export const getSampleFiltersEpic = (actions$) => actions$.pipe(
  ofType(GET_SAMPLE_FILTERS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/filters?view=sample`, 'POST', action.payload)).pipe(
    map((res) => getSampleFiltersSuccess(res)),
    catchError((err) => of(getSampleFiltersFailure(err))),
  )),
);

export const getSampleFiltersReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getSampleFiltersAction, (state) => {
        state.loading = true;
      })
      .addCase(getSampleFiltersSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getSampleFiltersFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
