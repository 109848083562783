import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SvgLoader from '../SvgLoader';
import LoginModalButton from '../LoginModalButton';
import webAuth from '../../../utils/webAuth';
import { getCookie } from '../../../utils';
import ProfileModal from '../ProfileModal';

function LandingHeader({ className, logo, width }) {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const profileRef = useRef(null);

  useEffect(() => {
    const handler = (event) => {
      if (!profileRef.current) {
        return;
      }

      if (!profileRef.current.contains(event.target)) {
        setShowProfileModal(false);
      }
    };

    document.addEventListener('click', handler, true);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  const classes = classNames(className);

  return (
    <div className={classes}>
      <SvgLoader width={width} height={72} svgName={logo} />
      {getCookie('accessToken') ? (
        <div className="log-menu">
          <div className="profile-dropdown" style={{ display: 'flex' }}>
            <div className="log-menu-profile">
              <div aria-hidden className="profile-pic" />
            </div>
            <div className="user-name">{getCookie('fullName')}</div>
            <div className="dropdown-icon">
              <div className="dropdown-icon-img" onClick={() => setShowProfileModal(!showProfileModal)} aria-hidden />
            </div>
          </div>
          {showProfileModal && (
            <div className="log-menu-links" ref={profileRef}>
              <ProfileModal />
            </div>
          )}
        </div>
      ) : (
        <div className="btn-component">
          <LoginModalButton webAuth={webAuth} className="login-btn" />
        </div>
      )}
    </div>
  );
}

LandingHeader.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
  width: PropTypes.number,
};

LandingHeader.defaultProps = {
  className: '',
  logo: '',
  width: 216,
};

export default LandingHeader;
