import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import useAppSelector from '../../../../utils/redux-hook';
import { generatePayloadsAction } from './logic';

const { Option } = Select;
function InputDropdown({
  index, changeHandler, payload, isDisabled, tryExample, setTryExample,
}) {
  const [payloadList, setPayloadList] = useState([]);
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const adcPayloadListResponse = useAppSelector((state) => state.adcPayloadListResponse);
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    setValue(payload);
    dispatch(generatePayloadsAction());
  }, []);

  useEffect(() => {
    if (tryExample !== '') {
      setValue(tryExample);
      setTryExample('');
    }
  }, [tryExample]);

  useEffect(() => {
    if (adcPayloadListResponse.flag) {
      setPayloadList(adcPayloadListResponse?.data.data.payloads);
    }
  }, [JSON.stringify(adcPayloadListResponse)]);
  useEffect(() => {
    changeHandler(value, index);
  }, [value]);
  return (
    <Select
      style={{
        width: '100%',
        backgroundColor: '#DBEAF8',
      }}
      showSearch
      placeholder={isClicked ? 'Search payload molecule/smile name here' : 'Select payload molecule/smile name'}
      dropdownAlign={{ offset: [0, 10] }}
      onChange={(val) => setValue(val)}
      value={value === '' ? undefined : value}
      onClick={() => setIsClicked(true)}
      disabled={isDisabled}
    >
      {payloadList?.map((str) => (
        <Option key={str}>{str}</Option>
      ))}
    </Select>
  );
}

InputDropdown.propTypes = {
  index: PropTypes.number.isRequired,
  changeHandler: PropTypes.func.isRequired,
  payload: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tryExample: PropTypes.string.isRequired,
  setTryExample: PropTypes.func.isRequired,
};

export default InputDropdown;
