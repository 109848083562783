/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';
import LinearGauge2 from './LinearGauge/index1';

function MetabolismComponent({ admetData, createItem }) {
  const [isGraph, setIsGraph] = useState(false);
  const [graphData, setGraphData] = useState(undefined);

  useEffect(() => {
    if (admetData !== undefined && Object.keys(admetData).length !== 0) {
      const arr = [
        {
          id: 'CYP1A2 Inhibition',
          value: admetData?.CYP1A2_Veith * 100,
        },
        {
          id: 'CYP2C19 Inhibition',
          value: admetData?.CYP2C19_Veith * 100,
        },
        {
          id: 'CYP2C9 Substrate',
          value: admetData?.CYP2C9_Substrate_CarbonMangels * 100,
        },
        {
          id: 'CYP2C9 Inhibition',
          value: admetData?.CYP2C9_Veith * 100,
        },
        {
          id: 'CYP2D6 Substrate',
          value: admetData?.CYP2D6_Substrate_CarbonMangels * 100,
        },
        {
          id: 'CYP2D6 Inhibition',
          value: admetData?.CYP2D6_Veith * 100,
        },
        {
          id: 'CYP3A4 Substrate',
          value: admetData?.CYP3A4_Substrate_CarbonMangels * 100,
        },
        {
          id: 'CYP3A4 Inhibition',
          value: admetData?.CYP3A4_Veith * 100,
        },
      ];
      setGraphData(arr);
    }
  }, [admetData]);

  return (
    <div className="container-layout">
      <div className="container-layout-card">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="container-layout-card-title">
            Metabolism Component
          </div>
          <div className="metabolism-com-toggle">
            <Space>
              <Radio.Group value={isGraph} onChange={(e) => setIsGraph(e.target.value)}>
                <Radio.Button value={false}><div className="icon-toggle" /></Radio.Button>
                <Radio.Button value><div className="icon-toggle" /></Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        </div>
        { !isGraph  ? (
          <div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'Properties', isHeader: true })}
              {createItem({ text: 'Probability', tooltip: 'Probability', isHeader: true })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP1A2 Inhibition', tooltip: 'CYP1A2 Inhibition' })}
              {createItem({ isGraph: true, value: admetData?.CYP1A2_Veith })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP2C19 Inhibition', tooltip: 'CYP2C19 Inhibition' })}
              {createItem({ isGraph: true, value: admetData?.CYP2C19_Veith })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP2C9 Substrate', tooltip: 'CYP2C9 Substrate' })}
              {createItem({ isGraph: true, value: admetData?.CYP2C9_Substrate_CarbonMangels })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP2C9 Inhibition', tooltip: 'CYP2C9 Inhibition' })}
              {createItem({ isGraph: true, value: admetData?.CYP2C9_Veith })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP2D6 Substrate', tooltip: 'CYP2D6 Substrate' })}
              {createItem({ isGraph: true, value: admetData?.CYP2D6_Substrate_CarbonMangels })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP2D6 Inhibition', tooltip: 'CYP2D6 Inhibition' })}
              {createItem({ isGraph: true, value: admetData?.CYP2D6_Veith })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP3A4 Substrate', tooltip: 'CYP3A4 Substrate' })}
              {createItem({ isGraph: true, value: admetData?.CYP3A4_Substrate_CarbonMangels })}
            </div>
            <div className="properties-items container-layout-card-item">
              {createItem({ text: 'CYP3A4 Inhibition', tooltip: 'CYP3A4 Inhibition' })}
              {createItem({ isGraph: true, value: admetData?.CYP3A4_Veith })}
            </div>
          </div>
        ) : (  graphData !== undefined && <LinearGauge2 data={graphData} />)}
      </div>
    </div>
  );
}
MetabolismComponent.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  createItem: PropTypes.func.isRequired,
};
export default MetabolismComponent;
