import { Steps } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

function StepIndicator({ currentIndex }) {
  const items = [
    {
      title: 'Search Datasets',
    },
    {
      title: 'Select Preferred Studies',
    },
    {
      title: 'View Samples',
    },
    {
      title: 'Group Samples',
    },
    {
      title: 'Select AI Pipeline',
    },
    {
      title: 'DEG\'s Output',
    },
  ];
  return (
    <div className="step-indicator">
      <Steps current={currentIndex} labelPlacement="vertical" size="large" items={items} />
    </div>
  );
}
StepIndicator.propTypes = {
  currentIndex: PropTypes.number.isRequired,
};
export default StepIndicator;
