import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// isRead SAR
const IS_READ_ADMET_SAR_OUTPUT = 'IS_READ_ADMET_SAR_OUTPUT';
const IS_READ_ADMET_SAR_OUTPUT_SUCCESS = 'IS_READ_ADMET_SAR_OUTPUT_SUCCESS';
const IS_READ_ADMET_SAR_OUTPUT_FAILURE = 'IS_READ_ADMET_SAR_OUTPUT_FAILURE';

export const isReadAdmetSAROutputAction = createAction(IS_READ_ADMET_SAR_OUTPUT);

const isReadAdmetSAROutputSuccess = createAction(IS_READ_ADMET_SAR_OUTPUT_SUCCESS);

const isReadAdmetSAROutputFailure = createAction(IS_READ_ADMET_SAR_OUTPUT_FAILURE);

export const isReadAdmetSAROutputEpic = (actions$) => actions$.pipe(
  ofType(IS_READ_ADMET_SAR_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/readStatus?${action.payload}`, 'PUT')).pipe(
    map((res) => isReadAdmetSAROutputSuccess(res)),
    catchError((err) => of(isReadAdmetSAROutputFailure(err))),
  )),
);
export const isReadAdmetSAROutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(isReadAdmetSAROutputAction, (state) => {
        state.loading = true;
      })
      .addCase(isReadAdmetSAROutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(isReadAdmetSAROutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

const CHECK_ADMET_SAR_STATUS = 'CHECK_ADMET_SAR_STATUS';
const CHECK_ADMET_SAR_STATUS_SUCCESS = 'CHECK_ADMET_SAR_STATUS_SUCCESS';
const CHECK_ADMET_SAR_STATUS_FAILURE = 'CHECK_ADMET_SAR_STATUS_FAILURE';
const CHECK_ADMET_SAR_STATUS_RESET = 'CHECK_ADMET_SAR_STATUS_RESET';

export const checkAdmetSARStatusAction = createAction(CHECK_ADMET_SAR_STATUS);
export const checkAdmetSARStatusResetAction = createAction(CHECK_ADMET_SAR_STATUS_RESET);

const checkAdmetSARStatusSuccess = createAction(CHECK_ADMET_SAR_STATUS_SUCCESS);
const checkAdmetSARStatusFailure = createAction(CHECK_ADMET_SAR_STATUS_FAILURE);

export const checkAdmetSARStatusEpic = (actions$) => actions$.pipe(
  ofType(CHECK_ADMET_SAR_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_sar}/allLeads`, 'get')).pipe(
    map((res) => checkAdmetSARStatusSuccess(res)),
    catchError((err) => of(checkAdmetSARStatusFailure(err))),
  )),
);

export const checkAdmetStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(checkAdmetSARStatusAction, (state) => {
        state.loading = true;
      })
      .addCase(checkAdmetSARStatusSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(checkAdmetSARStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(checkAdmetSARStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// get Admet Output details
const GET_ADMET_COMPARE_OUTPUT = 'GET_ADMET_COMPARE_OUTPUT';
const GET_ADMET_COMPARE_OUTPUT_SUCCESS = 'GET_ADMET_COMPARE_OUTPUT_SUCCESS';
const GET_ADMET_COMPARE_RESET_OUTPUT = 'GET_ADMET_COMPARE_RESET_OUTPUT';
const GET_ADMET_COMPARE_OUTPUT_FAILURE = 'GET_ADMET_COMPARE_OUTPUT_FAILURE';

export const getAdmetCompareOutputAction = createAction(GET_ADMET_COMPARE_OUTPUT);

export const getAdmetCompareOutputResetAction = createAction(GET_ADMET_COMPARE_RESET_OUTPUT);

const getAdmetCompareOutputSuccess = createAction(GET_ADMET_COMPARE_OUTPUT_SUCCESS);

const getAdmetCompareOutputFaliure = createAction(GET_ADMET_COMPARE_OUTPUT_FAILURE);

export const getAdmetCompareOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_ADMET_COMPARE_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_sar}/getAdmet?${action.payload}`, 'get')).pipe(
    map((res) => getAdmetCompareOutputSuccess(res)),
    catchError((err) => of(getAdmetCompareOutputFaliure(err))),
  )),
);

export const getAdmetCompareOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getAdmetCompareOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getAdmetCompareOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getAdmetCompareOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addCase(getAdmetCompareOutputResetAction, (state) => {
        state.data = {};
        state.loading = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
