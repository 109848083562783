/* eslint-disable */
import { Checkbox, Divider, Radio, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getFiltersAction } from '../../../../pages/NewBiomarkerInputPage/logic';
import { getStudiesAction } from '../logic';
import queryString from 'query-string';

function FiltersSelectStudy({ filters, filtersSelected, setFiltersSelected, displayFilters, isSemantic, setCurrentPage }) {
  const [orgValue, setOrgValue] = useState(0);
  const [bioValue, setBioValue] = useState('');
  const [organisms, setOrganism] = useState([]);
  const [biospecimens, setBiospecimens] = useState([]);
  const [biospecimenInput, setBiospecimenInput] = useState([]);
  const [organismsInput, setOrganismsInput] = useState([
    {
      operator: 'OR',
      value: 'Homo sapiens',
    },
  ]);
  const dispatch = useDispatch();
  const [subCategory, setSubCategory] = useState('');

  useEffect(() => {
    setOrganism(filters.organism);
    setBiospecimens(filters.biospecimen);
  }, [filters]);
  useEffect(() => {
    if (isSemantic || filtersSelected.organism === undefined) {
      setOrgValue(0);
    } else {
      setOrgValue(filters.organism?.findIndex((item) => item.display_name === filtersSelected.organism));
    }
    if (!isSemantic && filtersSelected.biospecimen === []) {
      setBiospecimenInput([]);
    }
  }, []);
  const onChangeOrganism = (e) => {
    setOrganismsInput([
      {
        operator: 'OR',
        value: organisms[e.target.value].display_name,
      },
    ]);
    const body = {
      filters: {
        organism: [
          {
            operator: 'OR',
            value: organisms[e.target.value].display_name,
          },
        ],
        biospecimen: biospecimenInput === undefined ? undefined : biospecimenInput,
      },
    };
    const request = {
      params: queryString.stringify({
        search_query: filtersSelected.indicationName,
      }),
      body,
    };
    setFiltersSelected((prev) => ({ ...prev, organism: organisms[e.target.value] }));
    dispatch(getFiltersAction(request));
    dispatch(getStudiesAction(request));
    setCurrentPage(1);
    setOrgValue(e.target.value);
  };
  const onChangeBiospecimen = (checkedValues) => {
    setBiospecimenInput(checkedValues.map((item) => ({ operator: 'OR', value: item })));
    const tempValues = checkedValues.map((item) => ({ operator: 'OR', value: item }));
    const body = {
      filters: {
        organism: organismsInput,
        biospecimen: tempValues,
      },
    };
    const request = {
      params: queryString.stringify({
        search_query: filtersSelected.indicationName,
      }),
      body,
    };
    setFiltersSelected((prev) => ({ ...prev, biospecimen: checkedValues }));
    dispatch(getFiltersAction(request));
    dispatch(getStudiesAction(request));
    setCurrentPage(1);
    setBioValue(checkedValues);
  };
  const onChangeSubCategory = (e, name, list1) => {
    setSubCategory(name);
    setFiltersSelected((prev) => ({ ...prev, [name]: list1[e.target.value].display_name }));
    const body = {
      filters: {
        organism: organismsInput !== undefined ? organismsInput : undefined,
        biospecimen: biospecimenInput !== undefined ? biospecimenInput : undefined,
        [name]:
          list1[e.target.value].display_name !== undefined
            ? [
                {
                  operator: 'OR',
                  value: list1[e.target.value].display_name,
                },
              ]
            : undefined,
      },
    };
    const request = {
      params: queryString.stringify({
        search_query: filtersSelected.indicationName,
      }),
      body,
    };
    dispatch(getFiltersAction(request));
    dispatch(getStudiesAction(request));
    setCurrentPage(1);
  };
  const displayOrganisms = () => (
    <div className="organism-filters">
      <div className="individual-filter-title filters-main-content-title">Organisms</div>
      <Radio.Group onChange={onChangeOrganism} value={orgValue}>
        <Space direction="vertical">
          {organisms?.map((item, index) => (
            <Radio key={item.display_name} value={index}>{`${item.display_name} (${item.count})`}</Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );

  const displayBiospecimens = () => (
    <div className="biospecimen-filters">
      <div className="individual-filter-title filters-main-content-title">Biospecimens</div>
      <Checkbox.Group defaultValue={isSemantic ? [bioValue] : filtersSelected.biospecimen} onChange={onChangeBiospecimen}>
        <Space direction="vertical">
          {biospecimens?.map((item) => (
            <Checkbox key={item.display_name} value={item.display_name}>{`${item.display_name} (${item.count})`}</Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </div>
  );

  const displaySubCategories = (item, name) => (
    <div className="organism-filters">
      <div className="individual-filter-title filters-main-content-title">{item.display_name}</div>
      <Radio.Group onChange={(e) => onChangeSubCategory(e, name, item.sub_technologies)}>
        <Space direction="vertical">
          {item.sub_technologies?.map((item, index) => (
            <Radio key={item.display_name} value={index} checked={false}>{`${item.display_name} (${item.count})`}</Radio>
          ))}
        </Space>
      </Radio.Group>
      <Divider />
    </div>
  );

  const displayCategories = () => filters.techincal_categories?.map((item) => displaySubCategories(item, item.name));
  return (
    <div className={`filters-main ${displayFilters ? 'show' : 'hide'}`}>
      <div className="filters-main-content">
        <div className="filter-title">
          <div className="filter-icon" />
          Filters
        </div>
        <Divider />
        {displayOrganisms()}
        <Divider />
        {displayBiospecimens()}
        <Divider />
        <div className="technical-details-title filters-main-content-title">Technical Details</div>
        {displayCategories()}
      </div>
    </div>
  );
}
FiltersSelectStudy.propTypes = {
  filters: PropTypes.objectOf.isRequired,
  filtersSelected: PropTypes.objectOf.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
  displayFilters: PropTypes.bool.isRequired,
  isSemantic: PropTypes.bool.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};
export default FiltersSelectStudy;
