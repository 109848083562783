import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get indication details
const GET_INDICATION_OUTPUT_DR = 'GET_INDICATION_OUTPUT_DR';
const GET_INDICATION_OUTPUT_DR__SUCCESS = 'GET_INDICATION_OUTPUT_DR__SUCCESS';
const GET_MORE_INDICATION_OUTPUT_DR = 'GET_MORE_INDICATION_OUTPUT_DR';
const GET_MORE_INDICATION_OUTPUT_DR__SUCCESS = 'GET_MORE_INDICATION_OUTPUT_DR__SUCCESS';
const GET_INDICATION_OUTPUT_DR_FAILURE = 'GET_INDICATION_OUTPUT_DR_FAILURE';

export const getIndicationOutputDRAction = createAction(GET_INDICATION_OUTPUT_DR);

const getIndicationOutputDRSuccess = createAction(GET_INDICATION_OUTPUT_DR__SUCCESS);

export const getMoreIndicationOutputDRAction = createAction(GET_MORE_INDICATION_OUTPUT_DR);

const getMoreIndicationOutputDRSuccess = createAction(GET_MORE_INDICATION_OUTPUT_DR__SUCCESS);

const getIndicationOutputDRFaliure = createAction(GET_INDICATION_OUTPUT_DR_FAILURE);

export const getIndicationOutputDREpic = (actions$) => actions$.pipe(
  ofType(GET_INDICATION_OUTPUT_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_dr}/getIndications?${action.payload}`, 'get')).pipe(
    map((res) => getIndicationOutputDRSuccess(res)),
    catchError((err) => of(getIndicationOutputDRFaliure(err))),
  )),
);
export const getMoreIndicationOutputDREpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_INDICATION_OUTPUT_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_dr}/getIndications?${action.payload}`, 'get')).pipe(
    map((res) => getMoreIndicationOutputDRSuccess(res)),
    catchError((err) => of(getIndicationOutputDRFaliure(err))),
  )),
);
export const getIndicationOutputDRReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getIndicationOutputDRAction, (state) => {
      state.loading = true;
    })
      .addCase(getIndicationOutputDRSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreIndicationOutputDRAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreIndicationOutputDRSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.total_count,
          data: {
            ...state.data.data,
            extracted_data: [...state.data.data.extracted_data, ...action.payload.response.data.extracted_data],
          },
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getIndicationOutputDRFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

// isRead IEP

const IS_READ_DR_OUTPUT = 'IS_READ_DR_OUTPUT';
const IS_READ_DR_OUTPUT_SUCCESS = 'IS_READ_DR_OUTPUT_SUCCESS';
const IS_READ_DR_OUTPUT_FAILURE = 'IS_READ_DR_OUTPUT_FAILURE';

export const isReadDROutputAction = createAction(IS_READ_DR_OUTPUT);

const isReadDROutputSuccess = createAction(IS_READ_DR_OUTPUT_SUCCESS);

const isReadDROutputFailure = createAction(IS_READ_DR_OUTPUT_FAILURE);

export const isReadDROutputEpic = (actions$) => actions$.pipe(
  ofType(IS_READ_DR_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_dr}/readStatus?${action.payload}`, 'PUT')).pipe(
    map((res) => isReadDROutputSuccess(res)),
    catchError((err) => of(isReadDROutputFailure(err))),
  )),
);
export const isReadDROutputReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(isReadDROutputAction, (state) => {
      state.loading = true;
    })
      .addCase(isReadDROutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(isReadDROutputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
