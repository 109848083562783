/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';

function LinearGauge2({ data }) {
  const [height, setHeight] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  function SvgLine({
    x1, y1, x2, y2,
  }) {
    const width = Math.abs(x2 - x1);
    const height = Math.abs(y2 - y1);

    return (
      <div>
        <svg
          viewBox={`0 0 ${width} ${height}`}
          // xmlns="http://www.w3.org/2000/svg"
          style={{ width, height }}
        >
          <line
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke="black"
            strokeWidth="2"
          />
        </svg>
      </div>
    );
  }

  const calculatePostions = () => {
    const newData = [];
    let tot = 0;
    for (let i = 0; i < data.length; i += 1) {
      newData.push({ id: data[i].id, value: data[i].value });
    }
    newData.sort((a, b) => a.value - b.value);

    const dData = [];
    for (let i = 0; i < data.length; i += 1) {
      dData.push({ id: newData[i].id, value: newData[i].value });
    }
    tot = newData[0].value;
    for (let i = 1; i < newData.length; i += 1) {
      dData[i].value = newData[i].value - newData[i - 1].value;
      dData[i].value = dData[i].value * (height / newData[newData.length - 1].value);
      tot += dData[i].value;
    }
    const structure = dData.map((item, index) => (
      <div>
        <div style={{ height: `${item.value}px`, minHeight: '20px' }}>{item.id}</div>
      </div>
    ));
    structure.reverse();
    return structure;
  };

  const calculateLinePostions = () => {
    const newData = [];
    for (let i = 0; i < data.length; i += 1) {
      newData.push({ id: data[i].id, value: data[i].value });
    }
    newData.sort((a, b) => a.value - b.value);

    const dData = [];
    for (let i = 0; i < data.length; i += 1) {
      dData.push({ id: newData[i].id, value: newData[i].value });
    }
    for (let i = 0; i < newData.length; i += 1) {
      // dData[i].value = newData[i].value - newData[i - 1].value;
      // dData[i].value = dData[i].value * (height / newData[newData.length - 1].value);
      dData[i].value = height -  (dData[i].value / 100) * height;
    }
    const y1Arr = [];
    const y2Arr = [];
    dData.reverse();
    const structure = dData.map((item, index) => {
        y1Arr[index] = dData[index].value;
        // if(index === 0) {
        // } else {
        //   y1Arr[index] = y1Arr[index - 1] + dData[index].value;
        // }
        y2Arr[index] = y1Arr[index];
        if(index > 0 && Math.abs(y2Arr[index-1] - y2Arr[index]) < 20) {
          y2Arr[index] = y2Arr[index - 1] + 20;
        }
        if(y2Arr[index-1] > y2Arr[index]) {
          y2Arr[index] = y2Arr[index-1] + 20;
        }
        return <>
          <line
          x1={-2}
          y1={y1Arr[index]}
          x2={20}
          y2={y2Arr[index]}
          stroke="black"
          strokeWidth="1"
          />
          <text x={21} y={y2Arr[index]} dy={5} >{dData[index].id}</text>
        </>
      }
    );
    structure.reverse();
    const svgContainer = <div>
        <svg
          viewBox={`0 0 ${120} ${height}`}
          // xmlns="http://www.w3.org/2000/svg"
          style={{ width: 120, height, overflow: 'visible' }}
        >
          {/* <line
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke="black"
            strokeWidth="2"
          /> */}
          {structure}
        </svg>
      </div>
    return svgContainer;
  };

  return (
    <div className="toxicity-layout">
      <div className="toxicity-content">
        <div className="problity-content">
          <div className="problity-content-start">High Probability</div>
          <div style={{ transform: 'rotate(-90deg)' }}>Probability Score</div>
          <div className="problity-content-start">Low Probability</div>

        </div>
        <div className="scale-layout" ref={ref} />
        <div className="scale-layout-percentage" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end',  }}>
          {height && calculateLinePostions()}
        </div>
        {/* <div className="scale-layout-percentage" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
          {height && calculatePostions()}
        </div> */}
      </div>
    </div>
  );
}

export default LinearGauge2;
