/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'antd';
import terminalsDummyData from '../ExploreSolutions/data';
import Mixpanel from '../../../utils/mixpanel';

function CarouselLandingPage({ requestDemo }) {
  const attributes = ['AI platform for drug discovery solutions', 'Accelerating steps in drug discovery with AI-powered decision analytics', 'Ensuring drug development success with ML insights'];

  const requestDemoClick = () => {
    Mixpanel.track('requestDemoEvent', {
      action: 'request demo button click',
      view: 'landing page',
      btn: 'above explore our solution',
    });
    requestDemo();
  };

  const displayAttributes = () =>
    attributes.map((item) => (
      <div style={{ display: 'flex', flex: 'row' }}>
        <FontAwesomeIcon icon={faCheck} style={{ margin: '10px', color: '#ffffff', height: '23px' }} />
        <div className="info-text info-text-lvl">{item}</div>
      </div>
    ));

  const displayServices = (i, j) =>
    terminalsDummyData.slice(i, j).map((item, index) => (
      <div className="services-main" style={{ display: 'flex', margin: '10px', width: '350px' }}>
        {/* <FontAwesomeIcon
        icon={faCheck}
        style={{ margin: '10px', color: '#ffffff', height: '23px' }}
      /> */}
        <div className={`slide-text slide-text-icon-${i + index}`} />
        <div className="info-text">{item.name}</div>
      </div>
    ));

  const text = 'Curing the incurable using AI and ML based solutions for each step in drug discovery from target identification to improving clinical trial success. ';

  return (
    <div className="my-carousel-container">
      <Carousel autoplay speed={1500} style={{}}>
        <div className="carousel-item">
          <div className="header">AI powered Drug Discovery and Development</div>
          <div className="info-container">
            <div className="info-text info-text-lvl" style={{ maxWidth: '751px' }}>{text}</div>
            {/* <div className="info-text">
              the Power of AI & ML in Drug Discovery Innovation.
            </div> */}
          </div>
          {/* <button className="req-demo-btn" onClick={() => requestDemoClick()} type="button">
            Request a Demo
          </button> */}
        </div>
        <div className="carousel-item">
          <div className="header">Complimenting machine intelligence to human resolve for better drugs</div>
          <div className="info-container">{displayAttributes()}</div>
          {/* <button className="req-demo-btn" onClick={() => requestDemoClick()} type="button">
            Request a Demo
          </button> */}
        </div>
        <div className="carousel-item">
          <div className="header">Our Solutions</div>
          <div className="info-container">
            <div className="info-container-con" style={{ display: 'flex' }}>
              {displayServices(0, 3)}
            </div>
            <div className="info-container-con" style={{ display: 'flex' }}>
              {displayServices(3, 6)}
            </div>
            <div className="info-container-con" style={{ display: 'flex' }}>
              {displayServices(6, 9)}
            </div>
          </div>
        </div>
      </Carousel>
      <button className="req-demo-btn" onClick={() => requestDemoClick()} type="button">
        Request a Demo
      </button>
    </div>
  );
}

CarouselLandingPage.propTypes = {
  requestDemo: PropTypes.func.isRequired,
};

export default CarouselLandingPage;
