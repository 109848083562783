import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputCard from '../../components/InputCard';

function SelectService({ inputHandler, service }) {
  const [card, setCard] = useState([-1, -1]);
  const services = ['DISEASE_BASED', 'DRUG_BASED'];

  useEffect(() => {
    services.forEach((item, index) => {
      if (item === service) {
        setCard((prev) => {
          const newCard = prev;
          newCard[index] = 1;
          return newCard;
        });
        inputHandler({ service: services[index] });
      }
    });
  }, []);

  const handleCard = (index) => {
    setCard((prev) => {
      const newCard = prev.map((item, i) => {
        if (index === i) {
          return 1;
        }
        return 0;
      });
      return newCard;
    });
    inputHandler({ service: services[index] });
  };
  return (
    <div className="service-container service-container-ti">
      <div className="cards-container">
        <div className="cards-container-title">Select a Service</div>
        <div className="cards-list">
          <InputCard index={0} heading=" Disease Based" cardHandler={handleCard} isSelected={card[0]} colorClass="card-one" img="virus-icon" status="Open" />
          <InputCard index={1} heading=" Drug Based" cardHandler={handleCard} isSelected={card[1]} colorClass="card-two" img="virus2" status="Coming soon" />
        </div>
      </div>
    </div>
  );
}

SelectService.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
};

export default SelectService;
