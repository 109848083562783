import React from 'react';
import PropTypes from 'prop-types';

function SampleDetails({ sample, displayCategories }) {
  const sampleDetailsItem = (key, value) => (
    <div className="sample-details-item">
      <div className="sample-details-key">{key}</div>
      <div className="sample-details-value">{value && value !== '' ? value : '-'}</div>
    </div>
  );
  const sampleDetailsComponent = (sample1) => (
    <div className="sample-details-component">
      {sampleDetailsItem('Library Strategy', sample1?.library_strategy)}
      {sampleDetailsItem('Library Source', sample1?.library_source)}
      {sampleDetailsItem('Library Selection', sample1?.library_selection)}
      {sampleDetailsItem('Instrument Model', sample1?.instrument_model)}
      {sampleDetailsItem('Extracted Molecule', sample1?.extracted_molecule)}
    </div>
  );
  return (
    <div className={`sample-details-container ${displayCategories ? 'hide' : 'show'}`}>
      <div className="sample-details-main">
        <div className="published-date-row">
          <div className="published-date-text">{`Public on ${sample?.release_date || 'NA '}`}</div>
          <a aria-label="Redirect" target="_blank" rel="noopener noreferrer" href={sample?.link}>
            <div className="redirect-icon" />
          </a>
        </div>
        <div className="sample-title-text">{sample?.title}</div>
        <div className="sample-details-row">
          <div className="sample-title">Sample Details:</div>
          <div className="sample-value">{sampleDetailsComponent(sample)}</div>
        </div>
        <div className="sample-details-extraction-row">
          <div className="extraction-title">Extraction Protocol:</div>
          <div className="extraction-value">{sample?.extraction_protocol}</div>
        </div>
        <div className="sample-details-summary-row">
          <div className="summary-title">Summary:</div>
          <div className="summary-value">{sample?.data_processing}</div>
        </div>
      </div>
    </div>
  );
}
SampleDetails.propTypes = {
  sample: PropTypes.objectOf.isRequired,
  displayCategories: PropTypes.bool.isRequired,
};
export default SampleDetails;
