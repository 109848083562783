/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { Button, Tooltip } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { terminalsPath } from '../../../../app/components/Card/data';
import useAppSelector from '../../../../utils/redux-hook';
import ImageWithLoader from '../../components/ImageWithLoader';
import { getMoreSAROutputAction, getSAROutputAction } from './logic';
import CircularGauge from '../../components/CircularGauge';
import ComponentDetailsPage from './ComponentDetailsPage';
import Loader from '../../../../common/components/Loader';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import downloadXLSFile from '../../components/XLSXDownloader';

function LeadDetailsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const [smiles, setSmiles] = useState('');
  const [service, setService] = useState('');
  const [isComponentOpen, setComponentOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [individualItem, setIndividualItem] = useState({});
  const [isOpened, setIsopened] = useState(-1);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const inputStyle = searchParams.get('inputStyle');
  const noOfMolecules = searchParams.get('noOfMolecules');
  const outputResponse = useAppSelector((state) => state.getSAROutputResponse);

  useEffect(() => {
    const request = {
      job_id: jobId,
      from: currentPage,
      size: 10,
    };
    dispatch(getSAROutputAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (outputResponse.flag && outputResponse.data) {
      setTotalCount(outputResponse?.data?.data?.total_count);
      setSmiles(outputResponse?.data?.data?.job_data?.user_input?.molecular_smiles);
      setService(outputResponse?.data?.data?.job_data?.user_input?.service);
    }
  }, [outputResponse]);

  const handleComponent = (item) => {
    setIndividualItem(item);
    setComponentOpen(true);
  };

  const toggleComponent = () => {
    setComponentOpen(!isComponentOpen);
  };

  const handleExpandable = (index) => {
    setIsopened((prev) => {
      if (prev === index) {
        return -1;
      }
      return index;
    });
  };

  const loadMoreRows = () => {
    const request = {
      job_id: jobId,
      from: currentPage + 1,
      size: 10,
    };
    dispatch(getMoreSAROutputAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };

  const displayDrugItem = (item) => (
    <div className="combinations-output-item-left-part">
      <div className="combinations-drugs-info">
        <div className="combination-drug-name">
          <div className="drug-placeholder">
            <Tooltip placement="topLeft" title={item.scaffold_name}>
              {item.scaffold_name}
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="combinations-drugs-info-img">
        <div className="combinations-drugs-info-img-sec">
          <div className="drug-placeholder">
            <ImageWithLoader src={item.img_url} desc="Scaffold" />
          </div>
        </div>
      </div>
    </div>
  );

  const handleBack = () => {
    navigate(`${terminalsPath.metad3_sar_denovo}?service=${service}&inputStyle=${inputStyle}&smiles=${smiles}`);
  };

  const displayChildrens = (itemsList) =>
    itemsList.map((item) => (
      <div className="combinations-output-item">
        <div className="combinations-item-content">
          <div className="combinations-drugs-list">
            <div className="combinations-output-item-left-part">
              <div className="combinations-output-children-heading">
                <div className="combinations-output-children-title">
                  <Tooltip placement="topLeft" title={item.scaffold_lead}>
                    {item.scaffold_lead}
                  </Tooltip>
                </div>
                <div className="combinations-output-view-details">
                  <Button
                    className="view-details-btn"
                    onClick={() => {
                      handleComponent(item);
                    }}
                  >
                    View Details
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="combinations-output-children-content">
            <div className="combinations-output-children-svg">
              <ImageWithLoader src={item.img_url} desc="Scaffold" />
            </div>
            <div className="pie-circle">
              <CircularGauge value={parseFloat(item.lead_score).toFixed(2)} />
            </div>
          </div>
        </div>
      </div>
    ));

  const displayOutputList = () => {
    const combinationsData = outputResponse.data?.data?.smiles_generated_leads;
    return combinationsData.map((item, index) => (
      <div className={`combinations-output-item-outer-part ${isOpened === index ? 'open' : 'close'}`}>
        <div className="combinations-output-item">
          <div className="combinations-item-content">
            <div className="combinations-drugs-list">{displayDrugItem(item)}</div>
            <div className="pie-circle pie-circle-main">
              <CircularGauge value={parseFloat(item.scaffold_score).toFixed(2)} />
            </div>
          </div>
          <div role="button" className="combinations-output-item-button" onClick={() => handleExpandable(index)}>
            <div className="arrow-icon" />
            <div className="arrow-icon-text">View Leads</div>
          </div>
        </div>
        <div className="combinations-output-item-lower-part">{displayChildrens(item.scaffold_lead_scores)}</div>
      </div>
    ));
  };

  return (
    <>
      {isComponentOpen ? (
        <ComponentDetailsPage toggleComponent={toggleComponent} individualItem={individualItem} />
      ) : (
        <>
          <Loader loading={outputResponse?.loading} error={outputResponse?.error} width="100%" height="1000px">
            <LazyLoadDiv className="sar-lazy-loading" id="sar-lazy-loading-output" total={totalCount} currentTotal={(outputResponse.data?.data?.smiles_generated_leads || []).length} loadMoreRows={loadMoreRows}>
              <div className="ct-home-page">
                <div className="main-heading">
                  <div className="back-btn">
                    <Button className="back-btn-icon" onClick={handleBack}>
                      Back
                    </Button>
                  </div>
                  <div className="main-title">Prioritized list of scaffolds and their respective leads</div>
                  <div className="main-description">Based on the Innoplexus model scores, here is a list of scaffolds and its generated leads, with scores ranging from 0 to 1, where 0 indicates a low score and 1 indicates a high score.</div>
                  <div className="download-btns">
                    <div className="main-download-btn">
                      <Button
                        onClick={() => {
                          setIsPendingDownload(true);
                          downloadXLSFile({ isZip: false, jobId: jobId, setIsPendingDownload, setIsPendingZipDownload });
                        }}
                        disabled={isPendingDownload}
                      >
                        {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                      </Button>
                    </div>
                    <div className="main-download-btn">
                      <Button
                        onClick={() => {
                          setIsPendingZipDownload(true);
                          downloadXLSFile({ isZip: true, jobId: jobId, setIsPendingDownload, setIsPendingZipDownload });
                        }}
                        disabled={isPendingZipDownload}
                      >
                        {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="combinations-output-overview">
                  <div className="combinations-output-item">
                    <div className="item-title">Overview</div>
                    <div className="combinations-item-content">
                      <div className="combinations-drugs-list">
                        <div className="info-component-row">
                          <div className="combination-drug-name">
                            <div className="info-component-heading">Number of molecules</div>
                            <div className="info-component-value">{noOfMolecules}</div>
                          </div>
                          <div className="combination-drug-moa">
                            <div className="info-component-heading">Invalid smiles</div>
                            <div className="info-component-value">-</div>
                          </div>
                        </div>
                        <div className="info-component-row">
                          <div className="combination-drug-name">
                            <div className="info-component-heading">Number of invalid smiles</div>
                            <div className="info-component-value">-</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sar-output-list">{outputResponse.flag ? displayOutputList() : null}</div>
              </div>
            </LazyLoadDiv>
          </Loader>
        </>
      )}
    </>
  );
}

export default LeadDetailsPage;
