import React, { useState } from 'react';
import movie from '../../../../public/videos/terminal-loop.mp4';
import MainInfo from '../../components/MainInfo';
import ModalRequestDemo from '../../container/ModalRequestDemo';
import ExploreSolution from '../../components/ExploreSolutions';
import LandingHeader from '../../../common/components/LandingHeader';

function LandingPage() {
  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);
  return (
    <div className="app-main">
      <div className="video-parent">
        <div className="video-background">
          <video className="terminal-landing-video" autoPlay muted loop>
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
            <source src={movie} type="video/mp4" />
          </video>
        </div>
        <LandingHeader className="header-terminal" logo="metad3logo-white-new" />
        <MainInfo showReqestDemo={() => setIsOpenRequestDemo(true)} />
      </div>
      <div className="app-main-content">
        <ExploreSolution setOpen={() => setIsOpenRequestDemo(true)} />
      </div>

      <ModalRequestDemo
        setOpen={(flag) => setIsOpenRequestDemo(flag)}
        open={isOpenRequestDemo}
        isAccess={false}
      />
    </div>
  );
}

export default LandingPage;
