import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './epics';
import notificationMiddleWare from './utils/middleware';
import rootReducer from './reducer';

const epicMiddleware = createEpicMiddleware();

export default configureStore({
  reducer: rootReducer,
  middleware: [epicMiddleware, notificationMiddleWare],
});

epicMiddleware.run(rootEpic);
