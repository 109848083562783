import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { getInputSuggestDRAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';

const { Search } = Input;

function InputDropdown({
  changeHandler, drugName, isDisabled, setDrugName,
}) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const getInputSuggestDRResponse = useAppSelector((reduxState) => reduxState.getInputSuggestDRResponse || {});

  useEffect(() => {
    if (getInputSuggestDRResponse.flag) {
      setAutosuggestOptions(getInputSuggestDRResponse?.data?.suggestions);
    }
  }, [queryString.stringify(getInputSuggestDRResponse)]);

  useEffect(() => {
    if (drugName !== '') {
      setSearchText(drugName[0]);
      changeHandler([drugName[1], drugName[0]]);
      setDrugName('');
    }
  }, [drugName]);

  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0 && !isDisabled) {
      setAutosuggestOptions([]);
      setDisplayDropdown(true);
      setSearchText(e.target.value);
      const request = {
        input_text: e.target.value,
      };
      dispatch(getInputSuggestDRAction(queryString.stringify(request)));
    } else {
      setSearchText('');
      changeHandler(['']);
      setDisplayDropdown(false);
    }
  };

  const renderAutosuggestOptions = () => {
    const options = [];

    /* eslint-disable */
    for (let i = 0; i < Math.min(10, autosuggestOptions?.length); i++) {
      const itm = autosuggestOptions[i];
      options.push(
        <div
          key={crypto.randomUUID()}
          onClick={() => {
            changeHandler([itm.drug_id, itm?.preferred_name]);
            setSearchText(itm?.preferred_name);
            setDisplayDropdown(false);
          }}
        >
          {itm?.preferred_name}
        </div>,
      );
    }
    return options;
  };

  const noDataFound = () => <div className="no-data-found">No data found</div>;

  return (
    <div className="searchbar-dashboard-content-search joyride-searchBar">
      <Search placeholder="Enter Drug name, Preferred name or Smile" value={searchText} allowClear size="large" maxLength="256" onChange={onChangeInput} />
      <div className="search-dropdown-result-content">
        {displayDropdown ? (
          <div className="search-dropdown-result">
            <div className="search-dropdown-result-list">
              <Loader loading={getInputSuggestDRResponse.loading}>{renderAutosuggestOptions().length === 0 ? noDataFound() : renderAutosuggestOptions()}</Loader>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

InputDropdown.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  drugName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  setDrugName: PropTypes.func.isRequired,
};

export default InputDropdown;
