/* eslint-disable */
import React from 'react';
import FemaleSVG from './FemaleSVG';
import MaleSVG from './MaleSVG';

function NewHumanBodySVG() {
  return (
    <div className="top-organs-bodies" style={{ display: 'flex' }}>
      <FemaleSVG />
      <MaleSVG />
    </div>
  );
}
export default NewHumanBodySVG;
