/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

import 'echarts-wordcloud';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../../../../utils/redux-hook';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { ctpWordCloudAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import { XFilled } from '@ant-design/icons';

function Wordcloud({ indicationName }) {
  const [data, setData] = useState([]);
  const [categoryNames, setCategoryNames] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const dispatch = useDispatch();
  let color = ['#3B76EF', '#DF9661', '#00925D'];
  const ctpWordCloudResponse = useAppSelector((state) => state.ctpWordCloudResponse);

  useEffect(() => {
    const body = {
      indication_name: indicationName,
    };
    dispatch(ctpWordCloudAction(queryString.stringify(body)));
  }, []);

  useEffect(() => {
    if (ctpWordCloudResponse.flag) {
      setData(ctpWordCloudResponse.data);
    }
  }, [ctpWordCloudResponse]);

  function parsingData(data) {
    let names = [];
    let series = [];
    for (let i = 0; i < data.length; i++) {
      names.push(data[i].category);
      let summary = data[i].summary;
      for (let j = 0; j < summary.length; j++) {
        let obj = {};
        obj['name'] = summary[j].name;
        obj['value'] = summary[j].count;
        obj['textStyle'] = {
          color: color[i],
        };
        series.push(obj);
      }
    }
    setCategoryNames(names);
    setSeriesData(series);
  }

  useEffect(() => {
    parsingData(data);
  }, [data]);

  const option = {
    series: [
      {
        type: 'wordCloud',

        // The shape of the "cloud" to draw. Can be any polar equation represented as a
        // callback function, or a keyword present. Available presents are circle (default),
        // cardioid (apple or heart shape curve, the most known polar equation), diamond (
        // alias of square), triangle-forward, triangle, (alias of triangle-upright, pentagon, and star.

        shape: 'diamond',

        // Keep aspect ratio of maskImage or 1:1 for shapes
        // This option is supported from echarts-wordcloud@2.1.0
        keepAspect: false,

        // A silhouette image which the white area will be excluded from drawing texts.
        // The shape option will continue to apply as the shape of the cloud to grow.

        // maskImage,

        // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
        // Default to be put in the center and has 75% x 80% size.

        left: 'center',
        top: 'center',
        width: '95%',
        height: '95%',
        right: null,
        bottom: null,

        // Text size range which the value in data will be mapped to.
        // Default to have minimum 12px and maximum 60px size.

        sizeRange: [16, 60],

        // Text rotation range and step in degree. Text will be rotated randomly in range [-90, 90] by rotationStep 45

        rotationRange: [0, 0],
        rotationStep: 45,

        // size of the grid in pixels for marking the availability of the canvas
        // the larger the grid size, the bigger the gap between words.

        gridSize: 20,

        // set to true to allow word being draw partly outside of the canvas.
        // Allow word bigger than the size of the canvas to be drawn
        drawOutOfBound: false,

        // if the font size is   too large for the text to be displayed,
        // whether to shrink the text. If it is set to false, the text will
        // not be rendered. If it is set to true, the text will be shrinked.
        shrinkToFit: true,

        // If perform layout animation.
        // NOTE disable it will lead to UI blocking when there is lots of words.
        layoutAnimation: true,

        // Global text style
        textStyle: {
          fontFamily: 'lato',
          fontWeight: '400',
          // Color can be a callback function or a color string
        },
        silent: true,
        emphasis: {
          disabled: true,
        },

        // Data is an array. Each array item must have name and value property.
        data: seriesData || [],
      },
    ],
  };

  return (
    <Loader loading={ctpWordCloudResponse.loading}>
      <div
        className="wordcloud-header"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          backgroundColor: 'transparent',
        }}
      >
        <div className="wordcloud-title">
          <XFilled />
          <div className="wordcloud-trialtype">Indications</div>
        </div>
        <div className="wordcloud-title">
          <XFilled />
          <div className="wordcloud-trialtype">Interventions</div>
        </div>
        <div className="wordcloud-title">
          <XFilled />
          <div className="bubble-charts-trialtype">Genes</div>
        </div>
      </div>
      <div className="wordcloud-wrapper" style={{ width: '100%' }}>

        <div className="wordcloud-container">
          <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />
        </div>
      </div>
    </Loader>
  );
}

Wordcloud.propTypes = {
  indicationName: PropTypes.string.isRequired,
};

export default Wordcloud;
