import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// get input suggest details
const GET_AUTO_SUGGEST = 'GET_AUTO_SUGGEST';
const GET_AUTO_SUGGEST_SUCCESS = 'GET_AUTO_SUGGEST_SUCCESS';
const GET_AUTO_SUGGEST_FAILURE = 'GET_AUTO_SUGGEST_FAILURE';

export const getAutoSuggestAction = createAction(GET_AUTO_SUGGEST);

const getAutoSuggestSuccess = createAction(GET_AUTO_SUGGEST_SUCCESS);

const getAutoSuggestFailure = createAction(GET_AUTO_SUGGEST_FAILURE);
export const getAutoSuggestEpic = (actions$) => actions$.pipe(
  ofType(GET_AUTO_SUGGEST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/autoSuggest?${action.payload}`, 'get')).pipe(
    map((res) => getAutoSuggestSuccess(res)),
    catchError((err) => of(getAutoSuggestFailure(err))),
  )),
);

export const getAutoSuggestReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getAutoSuggestAction, (state) => {
        state.loading = true;
      })
      .addCase(getAutoSuggestSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getAutoSuggestFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
