/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import PropTypes, { node } from 'prop-types';
import * as d3 from 'd3';

function BubbleChart({ data }) {
  const svgRef = useRef(null);
  const tooltip = d3.select('body').append('div').attr('id', 'tooltip-div').style('position', 'absolute').style('padding', '10px').style('background', '#fff').style('border', '1px solid #000').style('border-radius', '5px').style('opacity', 0).style('pointer-events', 'none');
  const showTooltip = function (d) {
    tooltip.transition().duration(200).style('opacity', 0.9);
    tooltip
      .html(
        '<span style="color:black">' +
          `${
            d.data.key
              ? `${d?.data?.key
                  ?.split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}`
              : ''
          }` +
          `<span style="color:grey">` +
          `${d.data.score ? ` : ${d.data.score}` : ''}` +
          `</span>
      `,
      )
      .style('left', `${d3.event.pageX}px`)
      .style('top', `${d3.event.pageY - 28}px`);
  };

  const hideTooltip = function () {
    tooltip.transition().duration(200).style('opacity', 0);
    tooltip.html(null);
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    const width = 250;
    const height = 250;

    const bubble = d3.pack().size([width, height]).padding(5);

    const svg = d3.select(svgRef.current).attr('viewBox', `0 0 250 250`).style('stroke-width', 0);
    const root = d3.hierarchy({ children: data }).sum((d) => d.score);
    const nodes = bubble(root).descendants();

    svg
      .selectAll('circle')
      .data(nodes)
      .enter()
      .append('circle')
      /* eslint no-underscore-dangle: 0 */
      .attr('id', (d) => d.data?.key)
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', (d) => d.r)
      .attr('label', (d) => d.data?.score)
      .style('fill', (d) => d?.data?.color)
      .style('stroke', 'none')
      .style('stroke-width', 0)
      .style('opacity', 0.9)
      // .on('mouseover', (d) => {
      //   if (d?.data?.key != null || d?.data?.score != null) {
      //     // showTooltip(d);
      //   }
      // })
      // .on('mouseout', hideTooltip);

    const labelGroup = svg.append('g');

    labelGroup
      .selectAll('text')
      .data(nodes)
      .join('text')
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y + 10)
      .attr('text-anchor', 'middle')
      .attr('fill', 'black')
      .style('color', 'black')
      .style('font-size', (d) => {
        const len = d.value.toString().substring(0, d.r / 5).length;
        let size = d.r / 5;
        size *= 7 / len;
        size -= 3;
        return `${Math.round(size)}px`;
      })
      .text((d) => (d.height > 0 || d.value === 0 ? '' : d.value))
      // .on('mouseover', (d) => {
      //   if (d?.data?.key != null || d?.data?.score != null) {
      //     // showTooltip(d);
      //   }
      // })
      // .on('mouseout', hideTooltip);
  }, []);

  return <svg ref={svgRef} />;
}

BubbleChart.propTypes = {
  data: PropTypes.arrayOf.isRequired,
};

export default BubbleChart;
