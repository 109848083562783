/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { checkTIStatusResetAction } from '../TargetIdInputPage/logic';
import DiseaseBased from '../DiseaseBasedPage';
import { isReadTIOutputAction } from './logic';

function TargetIdOutputPage() {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');

  useEffect(() => {
    window.scrollTo(0, 0);
    const request = {
      job_id: jobId,
      is_read: true,
    };
    dispatch(isReadTIOutputAction(queryString.stringify(request)));
    dispatch(checkTIStatusResetAction());
  }, []);

  return <DiseaseBased />;
}

TargetIdOutputPage.propTypes = {
  module: PropTypes.string.isRequired,
};

export default TargetIdOutputPage;
