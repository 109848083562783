/* eslint-disable */
import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Partner details
const GENERATE_PAYLOADS = 'GENERATE_PAYLOADS';
const GENERATE_PAYLOADS_SUCCESS = 'GENERATE_PAYLOADS_SUCCESS';
const GENERATE_PAYLOADS_FAILURE = 'GENERATE_PAYLOADS_FAILURE';

export const generatePayloadsAction = createAction(GENERATE_PAYLOADS);

const generatePayloadsSuccess = createAction(GENERATE_PAYLOADS_SUCCESS);

const generatePayloadsFaliure = createAction(GENERATE_PAYLOADS_FAILURE);
export const generatePayloadsEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_PAYLOADS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_adc}/v0/adcPayloadList`, 'get')).pipe(
    map((res) => generatePayloadsSuccess(res)),
    catchError((err) => of(generatePayloadsFaliure(err))),
  )),
);

const generatePayloadsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(generatePayloadsAction, (state) => {
      state.loading = true;
    })
      .addCase(generatePayloadsSuccess, (state, action) => {
        state.data = {...action.payload.response};
        state.loading = false;
        state.flag = true;
      })
      .addCase(generatePayloadsFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

export default generatePayloadsReducer;
