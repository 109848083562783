/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Header from '../../../../common/components/Header';
import SelectService from '../../container/SelectService';
import InputBox from '../../container/InputBox';
import { terminalsPath } from '../../../../app/components/Card/data';
import { checkTIStatusAction, generateTargetAction } from './logic';
import Loader from '../../../../common/components/Loader';
import PendingStatusModal from '../../components/PendingStatusModal';
import queryString from 'query-string';
import useAppSelector from '../../../../utils/redux-hook';
import { sendNotification } from '../../../../common/components/Notification/logic';

function TargetIdInputPage() {
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    service: '',
    disease_name: '',
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');
  const diseaseName = searchParams.get('diseaseName');
  const [disableButton, setDisableButton] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const generateTITargetResponse = useAppSelector((state) => state.generateTITargetResponse);
  const checkStatusResponse = useAppSelector((state) => state.checkTIStatus);

  const handleClick = () => {
    const request = {
      service: inputs.service,
      disease_name: inputs.disease_name,
    };
    dispatch(generateTargetAction(queryString.stringify(request)));
    setIsPending(true);
    setIsSubmitted(true);
  };

  const getInputs = (input) => {
    setInputs((prev) => ({
      ...prev,
      ...input,
    }));
  };

  const checkBtnDissabledForAccess = inputs.service === '' || inputs.service === undefined || inputs.disease_name === '' || inputs.disease_name === undefined;

  useEffect(() => {
    window.scrollTo(0, 0);
    const request = {
      service: 'RNA_SEQ',
    };
    dispatch(checkTIStatusAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    setDisableButton(checkBtnDissabledForAccess);
  }, [inputs]);

  useEffect(() => {
    let interval = '';
    if (isSubmitted) {
      interval = setInterval(() => {
        const request = {
          service: 'RNA_SEQ',
        };
        dispatch(checkTIStatusAction(queryString.stringify(request)));
      }, 2000);
    }
    return () => {
      if (interval !== '') {
        clearInterval(interval);
      }
    };
  }, [isSubmitted]);

  useEffect(() => {
    if (checkStatusResponse.flag && checkStatusResponse.data.length > 0) {
      if (checkStatusResponse.data[0]?.status === 'PENDING') {
        if (checkStatusResponse.data[0]?.is_read === false) {
          setIsPending(true);
        }
      } else if (checkStatusResponse.data[0]?.status === 'COMPLETED' && checkStatusResponse.data[0]?.is_read === false) {
        navigate(`${terminalsPath.metad3_target_id_output}?jobId=${checkStatusResponse.data[0].job_id}`);
      } else if (checkStatusResponse.data[0]?.status === 'REJECTED') {
        setIsPending(false);
      }
    }
  }, [JSON.stringify(checkStatusResponse)]);

  useEffect(() => {
    if (generateTITargetResponse.flag) {
      dispatch(
        sendNotification({
          type: 'REQUEST_SUCCESS',
          notificationType: 'success',
          message: generateTITargetResponse.data,
          showNotification: true,
        }),
      );
    }
  }, [JSON.stringify(generateTITargetResponse)]);

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home ti-header" logo="ti-logo" width={216} />
      <Loader loading={checkStatusResponse.loading && !isSubmitted} error={checkStatusResponse.error}>
        <div className="ct-home-page">
          <div className="main-heading">
            <div className="main-title">Discover potential targets with TargetGenesis</div>
            <div className="main-description">Using our AI model you can discover and prioritise list of potential targets simply by providing the disease name.</div>
          </div>
          <SelectService inputHandler={getInputs} service={service} />
          <InputBox inputHandler={getInputs} cardState={inputs.card} diseaseName={diseaseName !== null ? diseaseName : ''} isDisabled={inputs.service === ''} />
          <div className="ct-home-page-footer">
            <button onClick={handleClick} disabled={disableButton} className="generate-in-btn" type="button">
              GENERATE TARGETS
            </button>
          </div>
        </div>
        <PendingStatusModal open={isPending} />
      </Loader>
    </div>
  );
}

export default TargetIdInputPage;
