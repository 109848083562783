import React from 'react';

const terminalsDummyData = [
  {
    name: 'Target  ID',
    content: 'TargetExplore deploys AI to prioritize biologically relevant targets for diseases, evaluating diverse biological parameters and literature strength. This innovative platform enhances the efficiency and accuracy of target selection in drug discovery, allowing researchers to focus on targets with the highest therapeutic potential',
    label: 'drug',
    svgName: 'therapy',
  },
  {
    name: 'Biomarker Candidates',
    content: 'Leveraging AI and ML to characterise biomarkers using high throughput  OMICS analysis platforms to predict prognosis and therapeutic response in various disease conditions.',
    label: 'therapy',
    svgName: 'therapy',
  },
  {
    name: 'SAR & Denovo',
    content: 'Leveraging the AI to generate the lead molecules and its properties. These predictive models can then guide the design of new molecules with improved pharmacological properties.',
    label: 'sardrug',
    svgName: 'therapy',
  },
  {
    name: 'ADC Design',
    content: 'Maximize ADC development with our comprehensive suite of services, including linker prediction, payload prioritization, and de novo ADC generation. Our advanced algorithms optimize linkers for enhanced drug delivery, prioritize payloads for personalized treatment strategies, and efficiently generate novel ADCs for targeted therapies with improved efficacy and reduced side effects',
    label: 'protein',
    svgName: 'therapy',
  },
  {
    name: 'Indication Prioritisation',
    content: 'Leveraging proprietary algorithms to prioritize high value indications for a target protein of interest by offering valuable insights to guide research and development decisions. This will help in selecting promising therapeutic molecules with potential to treat newly discovered disease/s.',
    label: 'preclinical',
    svgName: 'therapy',
  },
  {
    name: 'Drug Repurposing',
    content: `Drug repurposing involves identifying existing drugs that can be used for new therapeutic purposes beyond their original intended use. This approach capitalizes on the known safety profiles and pharmacological properties of approved drugs, potentially reducing development costs and timelines.
    Drug repurposing is a promising approach for discovering new treatments and addressing unmet medical needs efficiently. 
    It is is a promising approach for discovering new treatments and addressing unmet medical needs efficiently.
    `,
    label: 'dr',
    svgName: 'therapy-terminal',
  },
  {
    name: 'Drug Combination',
    content: 'Our drug combination product utilizes AI-driven tools to swiftly identify synergistic interactions between compounds and expediting the development of novel combination therapies. By employing sophisticated algorithms, our platform sifts through vast datasets, uncovering intricate relationships between drugs and biological targets, enabling researchers to identify optimal combinations with enhanced efficacy and safety profiles.',
    label: 'ct',
    svgName: 'ct-terminal',
  },
  {
    name: 'PROTAC Design',
    content: 'PROTAC enables rapid design of warheads, linkers, and complete molecules for challenging undruggable targets. Our advanced AI-ML platform predicts PROTAC efficacy by analyzing target protein and E3 ligase structures, improving drug discovery by forecasting protein degradation efficiency.',
    label: 'protac',
    svgName: 'therapy',
  },
  {
    name: 'Clinical Trial Prediction',
    content: 'Empower pharma/biotech companies with AI-driven insights for precise clinical trial success predictions, feasibility analysis, and optimized study designs, streamlining drug development pathways and enabling informed decision-making.',
    label: 'disease',
    svgName: 'therapy',
  },
];

const renderItems = ({ name, content, label }) => (
  <div className={`content item-4 terminal-${label}`}>
    <div className="content-sec-img">
      <div className="content-sec-img-icon" />
    </div>
    <div className="content-sec-content">
      <div className="terminal-header">{name}</div>
      <div className="terminal-sub-header">{content}</div>
    </div>
  </div>
);

const renderTerminalInfoViewItems = ({ name, content, label }) => (
  <div className={`container-name terminal-${label}`} style={{ width: '700px' }}>
    <div className="name-icon" />
    <div className="name-title">{name}</div>
    <div
      className="name-content"
      style={{
        transition: 'all 0.3s ease-in-out 0.2s',
      }}
    >
      {content}
    </div>
  </div>
);

export const getTerminalInfoData = (data) => data.map((item, index) => ({
  key: index,
  name: item.name,
  content: renderTerminalInfoViewItems(item),
}));

export const tranformData = (data) => data.map((item, index) => ({
  key: index,
  name: item.name,
  content: renderItems(item),
}));

export default terminalsDummyData;
