import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const CTP_PHASE_DISTRIBUTION = 'CTP_PHASE_DISTRIBUTION';
const CTP_PHASE_DISTRIBUTION_SUCCESS = 'CTP_PHASE_DISTRIBUTION_SUCCESS';
const CTP_PHASE_DISTRIBUTION_FAILURE = 'CTP_PHASE_DISTRIBUTION_FAILURE';
const CTP_PHASE_DISTRIBUTION_RESET = 'CTP_PHASE_DISTRIBUTION_RESET';

export const ctpPhaseDistributionAction = createAction(CTP_PHASE_DISTRIBUTION);
export const ctpPhaseDistributionResetAction = createAction(CTP_PHASE_DISTRIBUTION_RESET);

const ctpPhaseDistributionsSuccess = createAction(CTP_PHASE_DISTRIBUTION_SUCCESS);
const ctpPhaseDistributionFailure = createAction(CTP_PHASE_DISTRIBUTION_FAILURE);

export const ctpPhaseDistributionEpic = (actions$) => actions$.pipe(
  ofType(CTP_PHASE_DISTRIBUTION),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/phaseDistribution?${action.payload}`, 'get')).pipe(
    map((res) => ctpPhaseDistributionsSuccess(res)),
    catchError((err) => of(ctpPhaseDistributionFailure(err))),
  )),
);

export const ctpPhaseDistributionReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(ctpPhaseDistributionAction, (state) => {
        state.loading = true;
      })
      .addCase(ctpPhaseDistributionsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(ctpPhaseDistributionFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ctpPhaseDistributionResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
