/* eslint-disable */
import { Button, Divider, Input, Modal, Radio, Space, Upload } from 'antd';
const { TextArea } = Input;
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SvgLoader from '../../../../common/components/SvgLoader';
import { useDispatch } from 'react-redux';
import { addProteinsFileInputAction, addProteinsTextualInputAction, addProteinsTextualInputResetAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import axios from 'axios';
import Rcookie from 'react-cookies';

function AddProteinsModal({ open, setOpen, onAddClick }) {
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState(false);
  const inputStyleOptions = [
    {
      label: 'Textual Input',
      value: 'textual_input',
    },
    {
      label: 'Upload a Document',
      value: 'upload_document',
    },
  ];
  const [isTextual, setIsTextual] = useState(inputStyleOptions[0].label);
  const [newFormData, setNewFormData] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [verifiedProteinsData, setVerifiedProteinsData] = useState({});
  const [requestData, setRequestData] = useState({
    ds: [],
    ppi: [],
    mfs: [],
    lm: [],
  });
  const [textData, setTextData] = useState({
    ds: '',
    ppi: '',
    mfs: '',
    lm: '',
  });
  const [editing, setEditing] = useState({
    isEditing: false,
    editingId: '',
    editingName: '',
  });
  const addProteinsTextualInputResponse = useAppSelector((state) => state.addProteinsTextualInputResponse);
  const addProteinsFileInputResponse = useAppSelector((state) => state.addProteinsFileInputResponse);

  useEffect(() => {
    if (addProteinsTextualInputResponse.flag) {
      setVerifiedProteinsData(addProteinsTextualInputResponse.data);
      setIsVerified(true);
    }
  }, [addProteinsTextualInputResponse]);
  useEffect(() => {
    if (addProteinsFileInputResponse.flag) {
      setVerifiedProteinsData(addProteinsFileInputResponse.data);
      setIsVerified(true);
      const updatedRequestData = { ...requestData };
      Object.keys(addProteinsFileInputResponse.data).forEach((key1) => {
        updatedRequestData[key1.toLowerCase()] = addProteinsFileInputResponse.data[key1].map((item) => item.protein_name);
      });
      console.log('Updated request data', updatedRequestData);
      setRequestData(updatedRequestData);
    }
  }, [addProteinsFileInputResponse]);
  const onVerifyClick = () => {
    if (isTextual === 'Textual Input') {
      const request = {
        body: {
          ds: requestData.ds,
          ppi: requestData.ppi,
          mfs: requestData.mfs,
          lm: requestData.lm,
        },
      };
      dispatch(addProteinsTextualInputAction(request));
    } else if (isFileSelected) {
      const request = {
        body: newFormData,
      };
      dispatch(addProteinsFileInputAction(request));
    }
  };
  const addProtein = (list1, key, finalList) => {
    const tempData = list1
      .filter((item) => item.prot_id !== ' ')
      .map((item) => ({
        preferred_name: item.protein_name,
        unique_id: item.prot_id,
        approaches_selected: [key === 'ds' ? 'DiseaseSimilaritySignature' : key === 'ppi' ? 'PpiSignature' : key === 'mfs' ? 'MolecularFunctionSignature' : 'LiteratureSignature'],
      }));
    tempData.forEach((item) => {
      const existingItem = finalList.find((fitem) => fitem.preferred_name === item.preferred_name);
      if (existingItem) {
        existingItem.approaches_selected = [...existingItem.approaches_selected, key === 'ds' ? 'DiseaseSimilaritySignature' : key === 'ppi' ? 'PpiSignature' : key === 'mfs' ? 'MolecularFunctionSignature' : 'LiteratureSignature'];
      } else {
        finalList.push(item);
      }
    });
  };
  const onAddProteinClick = () => {
    const finalList = [];
    addProtein(verifiedProteinsData.DS, 'ds', finalList);
    addProtein(verifiedProteinsData.PPI, 'ppi', finalList);
    addProtein(verifiedProteinsData.MFS, 'mfs', finalList);
    addProtein(verifiedProteinsData.LM, 'lm', finalList);
    onAddClick(finalList);
    finalList.splice(0, finalList.length);
    setIsVerified(false);
    setVerifiedProteinsData({});
    setRequestData({
      ds: [],
      ppi: [],
      mfs: [],
      lm: [],
    });
    dispatch(addProteinsTextualInputResetAction({}));
  };
  const onDeleteAllInvalid = (key) => {
    const tempData = verifiedProteinsData[key.toUpperCase()].filter((item) => item.prot_id !== ' ');
    setRequestData({
      ...requestData,
      [key]: tempData.map((item) => item.protein_name),
    });
    const request = {
      body: {
        ds: key === 'ds' ? tempData.map((item) => item.protein_name) : requestData.ds,
        ppi: key === 'ppi' ? tempData.map((item) => item.protein_name) : requestData.ppi,
        mfs: key === 'mfs' ? tempData.map((item) => item.protein_name) : requestData.mfs,
        lm: key === 'lm' ? tempData.map((item) => item.protein_name) : requestData.lm,
      },
    };
    dispatch(addProteinsTextualInputAction(request));
  };
  const onDeleteProteinName = (index, key) => {
    const tempData = requestData[key];
    tempData.splice(index, 1);
    setRequestData((prev) => {
      return { ...prev, [key]: tempData };
    });
    const request = {
      body: {
        ds: key === 'ds' ? tempData : requestData.ds,
        ppi: key === 'ppi' ? tempData : requestData.ppi,
        mfs: key === 'mfs' ? tempData : requestData.mfs,
        lm: key === 'lm' ? tempData : requestData.lm,
      },
    };
    dispatch(addProteinsTextualInputAction(request));
  };
  const onEditProteinName = (index, key) => {
    console.log('On edit protein --->', index, key, requestData);
    const tempData = requestData[key];
    tempData[index] = editing.editingName;
    setRequestData({ ...requestData, [key]: tempData });
    const request = {
      body: {
        ds: key === 'ds' ? tempData : requestData.ds,
        ppi: key === 'ppi' ? tempData : requestData.ppi,
        mfs: key === 'mfs' ? tempData : requestData.mfs,
        lm: key === 'lm' ? tempData : requestData.lm,
      },
    };
    console.log('Request --->', request);
    dispatch(addProteinsTextualInputAction(request));
    setEditing({ isEditing: false, editingId: '', editingName: '' });
  };
  const onInputFileChange = (fileList) => {
    if (fileList.length > 1) {
      fileList.shift();
    }
    const files = fileList;
    if (files.length) {
      const formData = new FormData();
      formData.append('file', files[0].originFileObj);
      setIsFileSelected(true);
      setNewFormData(formData);
    }
  };
  const processInput = (text) => text.split('\n').filter((line) => line);
  const props = {
    name: 'file',
    accept: '.xls,.xlsx',
    multiple: true,
    progress: true,

    beforeUpload: () => false,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        onInputFileChange(info.fileList);
      }
    },
    onRemove() {
      setIsFileSelected(false);
      setNewFormData('');
    },
  };
  const downloadTemplateFile = async () => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '',
      'Content-Type': 'blob',
      'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
    };
    const apiUrl = `${process.env.apiUrl_iep}/templateDownload`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      const outputFilename = 'template.xlsx';

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };
  const individualSourceArea = (title1, key1, list1, requestData) => (
    <div className="input-proteins-item">
      <div className="title">{title1}</div>
      {isVerified ? (
        <div className="verified-proteins">
          <Divider />
          <div className="verified-proteins-titles">
            <div className="accepted-protein">
              Accepted Protein: <span>{list1?.filter((item) => item.prot_id !== ' ').length}</span>
            </div>
            <div className="invalid-protein">
              Invalid Protein: <span>{list1?.filter((item) => item.prot_id === ' ').length}</span>
            </div>
            <div className="delete-btn">
              <Button disabled={verifiedProteinsData[key1.toUpperCase()]?.every((item) => item.prot_id !== ' ') || Object.keys(verifiedProteinsData).length === 0} onClick={() => onDeleteAllInvalid(key1)}>
                Delete All Invalid
              </Button>
            </div>
          </div>
          <Divider />
          <div className="verified-proteins-list">
            {list1?.map((item, index) => (
              <div className="verified-proteins-list-item">
                {editing.isEditing && editing.editingId === `${key1}-${index}` ? (
                  <Space.Compact
                    style={{
                      width: '100%',
                    }}
                  >
                    <Input value={editing.editingName ?? item.protein_name} onChange={(e) => setEditing({ ...editing, editingName: e.target.value })} onPressEnter={() => onEditProteinName(index, key1)} />
                    <Button type="primary" onClick={() => onEditProteinName(index, key1)}>
                      Update
                    </Button>
                  </Space.Compact>
                ) : (
                  <div className="protein-info" style={{ display: 'flex' }}>
                    {item.prot_id === ' ' && <div className="invalid-icon" />}
                    <div className="protein-name">{item.protein_name}</div>
                    <div className="edit-btns">
                      {item.prot_id === ' ' && (
                        <div className="edit-btn">
                          <Button
                            onClick={() =>
                              setEditing({
                                isEditing: true,
                                editingId: `${key1}-${index}`,
                              })
                            }
                          />
                        </div>
                      )}
                      <div className="delete-btn">
                        <Button onClick={() => onDeleteProteinName(index, key1)} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="input-proteins">
          <TextArea
            rows={4}
            placeholder="Enter new protein/s here"
            type="textarea"
            value={textData[key1]}
            // allowClear

            onChange={(e) => {
              setRequestData({
                ...requestData,
                [key1]: processInput(e.target.value),
              });
              setTextData({
                ...requestData,
                [key1]: e.target.value,
              });
            }}
          />
        </div>
      )}
    </div>
  );
  return (
    <Modal
      footer={null}
      closeIcon={null}
      closable
      onCancel={() => {
        setOpen(false);
        // finalList.splice(0, finalList.length);
        setIsVerified(false);
        setVerifiedProteinsData({});
        setRequestData({
          ds: [],
          ppi: [],
          mfs: [],
          lm: [],
        });
      }}
      open={open}
      className="add-protein-modal"
    >
      <div className="add-protein-title">Add Proteins</div>
      <div className="add-protein-content">
        <div className="select-input-style">
          <div className="select-input-style-title">Select input style and provide them to proceed</div>
          <div className="select-input-style-radio-btns">
            <Radio.Group
              value={isTextual}
              onChange={(e) => {
                setIsTextual(e.target.value);
              }}
              disabled={isVerified}
            >
              <Space>
                {inputStyleOptions?.map((item, ind) => (
                  <Radio key={item.label} value={item.label}>
                    {item.label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </div>
        </div>
        <div className="input-style-content">
          {isTextual === 'Textual Input' || isVerified ? (
            <div className="protein-inputs-row" style={{ display: 'flex' }}>
              {individualSourceArea('Disease Similarity', 'ds', verifiedProteinsData?.DS, requestData)}
              {individualSourceArea('Protein-Protein Interaction', 'ppi', verifiedProteinsData?.PPI, requestData)}
              {individualSourceArea('Molecular Function Similarity', 'mfs', verifiedProteinsData?.MFS, requestData)}
              {individualSourceArea('Literature Mining', 'lm', verifiedProteinsData?.LM, requestData)}
            </div>
          ) : (
            <div className="upload-document-view">
              <div className="upload-document-title">
                # To upload the document, first update your data in the provided template file, and then upload it.
                <span className="download-template-text" style={{ cursor: 'pointer' }} onClick={() => downloadTemplateFile()} aria-hidden>
                  Download the template file.
                </span>
              </div>
              <div className="upload-document">
                <Upload {...props} showUploadList={newFormData !== ''}>
                  {!isFileSelected && (
                    <p className="ant-upload-drag-icon">
                      <SvgLoader width={216} height={72} svgName="upload-file" />
                    </p>
                  )}
                  {!isFileSelected && (
                    <p className="ant-upload-text">
                      <span className="ant-upload-text-grey">Drag & drop files here or</span>
                      <span className="ant-upload-text-blue">Browse</span>
                    </p>
                  )}
                  {!isFileSelected && <p className="ant-upload-hint">The file should be in .xlsx format only, and the maximum size limit is 50MB.</p>}
                </Upload>
              </div>
            </div>
          )}
        </div>
        <div className="verify-btns-row" style={{ display: 'flex' }}>
          <div
            className="clear-btn"
            onClick={() => {
              setRequestData({ ds: [], ppi: [], mfs: [], lm: [] });
              setTextData({ ds: '', ppi: '', mfs: '', lm: '' });
              setIsVerified(false);
              setIsFileSelected(false);
              setNewFormData('');
            }}
            style={{ cursor: 'pointer' }}
          >
            Clear
          </div>
          <div className="verify-btn">
            <Button
              onClick={() => {
                isVerified ? onAddProteinClick() : onVerifyClick();
              }}
              disabled={requestData.ds.length === 0 && requestData.ppi.length === 0 && requestData.mfs.length === 0 && requestData.lm.length === 0 && !isFileSelected}
            >
              {`${isVerified ? 'Add Protein' : 'Verify Protein'}`}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AddProteinsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
};

export default AddProteinsModal;
