/* eslint-disable */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall, { apiCallwithoutCT } from '../../../../utils';

// verify proteins textual input
const ADD_PROTEINS_TEXTUAL_INPUT = 'ADD_PROTEINS_TEXTUAL_INPUT';
const ADD_PROTEINS_TEXTUAL_INPUT_SUCCESS = 'ADD_PROTEINS_TEXTUAL_INPUT_SUCCESS';
const ADD_PROTEINS_TEXTUAL_INPUT_FAILURE = 'ADD_PROTEINS_TEXTUAL_INPUT_FAILURE';
const ADD_PROTEINS_TEXTUAL_INPUT_RESET = 'ADD_PROTEINS_TEXTUAL_INPUT_RESET';

export const addProteinsTextualInputAction = createAction(ADD_PROTEINS_TEXTUAL_INPUT);
export const addProteinsTextualInputResetAction = createAction(ADD_PROTEINS_TEXTUAL_INPUT_RESET);

const addProteinsTextualInputSuccess = createAction(ADD_PROTEINS_TEXTUAL_INPUT_SUCCESS);
const addProteinsTextualInputFailure = createAction(ADD_PROTEINS_TEXTUAL_INPUT_FAILURE);

export const addProteinsTextualInputEpic = (actions$) =>
  actions$.pipe(
    ofType(ADD_PROTEINS_TEXTUAL_INPUT),
    switchMap((action) =>
      ajax(apiCall(`${process.env.apiUrl_iep}/verifyProteins`, 'POST', action.payload.body)).pipe(
        map((res) => addProteinsTextualInputSuccess(res)),
        catchError((err) => of(addProteinsTextualInputFailure(err))),
      ),
    ),
  );
export const addProteinsTextualInputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(addProteinsTextualInputAction, (state) => {
        state.loading = true;
      })
      .addCase(addProteinsTextualInputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(addProteinsTextualInputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(addProteinsTextualInputResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// Upload proteins file
const ADD_PROTEINS_UPLOAD_FILE_INPUT = 'ADD_PROTEINS_UPLOAD_FILE_INPUT';
const ADD_PROTEINS_UPLOAD_FILE_INPUT_SUCCESS = 'ADD_PROTEINS_UPLOAD_FILE_INPUT_SUCCESS';
const ADD_PROTEINS_UPLOAD_FILE_INPUT_FAILURE = 'ADD_PROTEINS_UPLOAD_FILE_INPUT_FAILURE';
const ADD_PROTEINS_UPLOAD_FILE_INPUT_RESET = 'ADD_PROTEINS_UPLOAD_FILE_INPUT_RESET';

export const addProteinsFileInputAction = createAction(ADD_PROTEINS_UPLOAD_FILE_INPUT);
export const addProteinsFileInputResetAction = createAction(ADD_PROTEINS_UPLOAD_FILE_INPUT_RESET);

const addProteinsFileInputSuccess = createAction(ADD_PROTEINS_UPLOAD_FILE_INPUT_SUCCESS);
const addProteinsFileInputFailure = createAction(ADD_PROTEINS_UPLOAD_FILE_INPUT_FAILURE);

export const addProteinsFileInputEpic = (actions$) =>
  actions$.pipe(
    ofType(ADD_PROTEINS_UPLOAD_FILE_INPUT),
    mergeMap((action) =>
      ajax(apiCallwithoutCT(`${process.env.apiUrl_iep}/verifyFilesProteins`, 'POST', action.payload.body)).pipe(
        map((res) => addProteinsFileInputSuccess(res)),
        catchError((err) => of(addProteinsFileInputFailure(err))),
      ),
    ),
  );
export const addProteinsFileInputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(addProteinsFileInputAction, (state) => {
        state.loading = true;
      })
      .addCase(addProteinsFileInputSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(addProteinsFileInputFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(addProteinsFileInputResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
