import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  map, catchError, switchMap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../utils';

const GET_TERMINALS = 'GET_TERMINALS';
const GET_TERMINALS_SUCCESS = 'GET_TERMINALS_SUCCESS';
const GET_TERMINALS_FAILURE = 'GET_TERMINALS_FAILURE';

export const getTerminalsAction = createAction(GET_TERMINALS);

const getTerminalsSuccess = createAction(GET_TERMINALS_SUCCESS);

const getTerminalFaliure = createAction(GET_TERMINALS_FAILURE);

export const getTerminalsEpic = (actions$) => actions$.pipe(
  ofType(GET_TERMINALS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl}/v1/d3_modules/getModules`, 'get')).pipe(
    map((res) => getTerminalsSuccess(res)),
    catchError((err) => of(getTerminalFaliure(err))),
  )),
);

export const getTerminalsReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(getTerminalsAction, (state) => {
      state.loading = true;
    })
      .addCase(getTerminalsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getTerminalFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);

const USERS_ME = 'USERS_ME';
const USERS_ME_SUCCESS = 'USERS_ME_SUCCESS';
const USERS_ME_FAILURE = 'USERS_ME_FAILURE';

export const usersMeAction = createAction(USERS_ME);

const usersMeSuccess = createAction(USERS_ME_SUCCESS);

const usersMeFailure = createAction(USERS_ME_FAILURE);
export const usersMeEpic = (actions$) => actions$.pipe(
  ofType(USERS_ME),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl}/v1/users/me`, 'get')).pipe(
    map((res) => usersMeSuccess(res)),
    catchError((err) => of(usersMeFailure(err))),
  )),
);

export const usersMeReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(usersMeAction, (state) => {
      state.loading = true;
    })
      .addCase(usersMeSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(usersMeFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
