/* eslint-disable */
import React, { useEffect, useState } from 'react';
// import marker from '../LinearGauge/marker.png';

function LinearGauge({data}) {
    const [labelList, setLabelList] = useState([]);
    const [value, setValue] = useState(0);


    useEffect(() => {
        var min = data?.min ?? 0;
        var max = data?.max ?? 0;

        var total = max - min;
        var value = data?.value ?? 0;
        if(total === 0) total = 1;
        var percentage = ((value - min) / total) * 100;
        if(percentage >= 99.5) {
          percentage = 98;
        }
        else if(percentage >= 98) {
          percentage = 97;
        }
        setValue(percentage);
        var arr = [];
        for(var i=0;i<5;i++) {
          var v = ((total * i * 0.25) + min);
          if(typeof v === 'number')
            v=v.toFixed(2);
          arr.push(v);
        }
        setLabelList(arr);
    }, [data]);


  return (
    <div className='linear-gauge-container' >
      <div className='linear-gauge-box'>

      <div className='linear-gauge-bar'>

      </div>
      <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>

      <div className='linear-gauge-marker' style={{left:`${Math.min(value,97)}%`}}><div className="marker-value">{data?.value}</div></div>
      </div>
      <div id='linear-gauge-labels' className='linear-gauge-labels' style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}} >
        <div >{labelList[0]}</div>
        <div >{labelList[1]}</div>
        <div >{labelList[2]}</div>
        <div >{labelList[3]}</div>
        <div >{labelList[4]}</div>
      </div>
      </div>
    </div>
  );
}

export default LinearGauge;
