import React from 'react';
import PropTypes from 'prop-types';

function PropertiesContainer({ admetData, createItem }) {
  return (
    <div className="properties-container container-layout">
      <div className="container-layout-card">
        <div className="properties-header container-layout-card-title">Physicochemical Properties</div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Properties', isHeader: true })}
          {createItem({ text: 'Values', isHeader: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Molecular Weight', tooltip: 'Molecular Weight' })}
          {createItem({ text: admetData?.molecular_weight, isValue: true, unit: 'Dalton' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Log P', tooltip: 'Log P' })}
          {createItem({ text: admetData?.logP, isValue: true, unit: 'log-ratio' })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Hydrogen Bond Acceptors', tooltip: 'Hydrogen Bond Acceptors' })}
          {createItem({ text: admetData?.hydrogen_bond_acceptors, isValue: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Hydrogen Bond Donors', tooltip: 'Hydrogen Bond Donors' })}
          {createItem({ text: admetData?.hydrogen_bond_donors, isValue: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Lipinski Rule of 5', tooltip: 'Lipinski Rule of 5' })}
          {createItem({ text: admetData?.Lipinski, isValue: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'QED', tooltip: 'QED' })}
          {createItem({ text: admetData?.QED, isValue: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'Stereo Centers', tooltip: 'Stereo Centers' })}
          {createItem({ text: admetData?.stereo_centers, isValue: true })}
        </div>
        <div className="properties-items container-layout-card-item">
          {createItem({ text: 'TPSA', tooltip: 'TPSA' })}
          {createItem({ text: admetData?.tpsa, isValue: true, unit: 'Å2' })}
        </div>
      </div>
    </div>
  );
}
PropertiesContainer.propTypes = {
  admetData: PropTypes.objectOf.isRequired,
  createItem: PropTypes.func.isRequired,
};
export default PropertiesContainer;
