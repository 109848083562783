import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { removeSession } from './logic';
import { checkPermission, getCookie } from '../../../utils';
import Mixpanel from '../../../utils/mixpanel';

function Status() {
  const { type, status } = useSelector((state) => state.notification);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const activityFlow = () => {
    if (type === undefined) {
      return null;
    }
    if (type === 'LOGOUT_SUCCESS') {
      Mixpanel.reset();
      dispatch(removeSession());
      navigate('/', { replace: true });
    } else if (type === 'SET_SESSION') {
      navigate(process.env.redirectRoute, { replace: true });
    } else if (type.includes('_FAILURE')) {
      if (status === 401 && type !== 'LOGIN_FAILURE') {
        dispatch(removeSession());
        navigate(`${process.env.mainRoute}?${queryString.stringify({ redirect_url: `${location.pathname}${location.search}${location.hash}` })}`, { replace: true });
      }
    }
    return false;
  };

  useEffect(() => {
    if (window.location.pathname === '/' && getCookie('accessToken') && checkPermission('metad3_app_access')) {
      navigate('/home', { replace: true });
      navigate(0);
    }
    if (window.location.pathname !== '/' && !getCookie('accessToken') && !location.hash.includes('access_token')) {
      navigate('/', { replace: true });
      navigate(0);
    }
  }, [window.location]);

  useEffect(() => {
    if (type !== 'cancel') {
      activityFlow();
    }
  }, [type]);
  return null;
}

export default Status;
