import { combineEpics } from 'redux-observable';
import rootCommonEpic from './common/container/epics';
import rootAppEpic from './app/container/epic';

const rootEpic = combineEpics(
  rootCommonEpic,
  rootAppEpic,
);

export default rootEpic;
