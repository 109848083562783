import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get input suggest details
const GET_INPUT_SUGGEST = 'GET_INPUT_SUGGEST';
const GET_INPUT_SUGGEST_SUCCESS = 'GET_INPUT_SUGGEST_SUCCESS';
const GET_INPUT_SUGGEST_FAILURE = 'GET_INPUT_SUGGEST_FAILURE';

export const getInputSuggestAction = createAction(GET_INPUT_SUGGEST);

const getInputSuggestSuccess = createAction(GET_INPUT_SUGGEST_SUCCESS);

const getInputSuggestFailure = createAction(GET_INPUT_SUGGEST_FAILURE);
export const getInputSuggestEpic = (actions$) => actions$.pipe(
  ofType(GET_INPUT_SUGGEST),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/inputSuggest?${action.payload}`, 'get')).pipe(
    map((res) => getInputSuggestSuccess(res)),
    catchError((err) => of(getInputSuggestFailure(err))),
  )),
);

export const getInputSuggestReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getInputSuggestAction, (state) => {
        state.loading = true;
      })
      .addCase(getInputSuggestSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getInputSuggestFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
