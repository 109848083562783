/* eslint-disable */
import { Button, Radio, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MolecularStructure from '../../components/AdmetProperties/MolecularStructure';
import ParameterGraphicalViewContanier from '../../components/AdmetProperties/ParameterGraphicalView';
import ParameterValuesContainer from '../../container/AdmetProperties/ParameterValuesContainer';
import CompareMoleculeView from '../../components/AdmetProperties/CompareMoleculeView';
import useAppSelector from '../../../../utils/redux-hook';
import { getAdmetOutputAction } from './logic';
import Loader from '../../../../common/components/Loader';
import { terminalsPath } from '../../../../app/components/Card/data';
import downloadXLSFile from '../../components/XLSXDownloader';

function 

AdmetPropertiesPage() {
  const [isCompareView, setIsCompareView] = useState(false);
  const [admetData, setAdmetData] = useState({});
  const [comparatorData, setComparatorData] = useState({});
  const [threeCol, setThreeCol] = useState(true);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const getAdmetOutputResponse = useAppSelector((state) => state.getAdmetOutputResponse);
  const [searchParams] = useSearchParams();
  const jobId = searchParams.get('jobId');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getAdmetOutputAction(queryString.stringify(request)));
  }, [jobId]);
  useEffect(() => {
    if (getAdmetOutputResponse) {
      setAdmetData(getAdmetOutputResponse.data[0]);
    }
  }, [JSON.stringify(getAdmetOutputResponse)]);

  const handleBack = () => {
    navigate(`${terminalsPath.metad3_sar_denovo}`);
  };
  return (
    <Loader loading={getAdmetOutputResponse.loading}>
      <div className="ct-home-page">
        <div className="main-heading">
          <div className="back-btn">
            <Button className="back-btn-icon" onClick={() => handleBack()}>
              Back
            </Button>
          </div>
        </div>
        <div className="combinations-output-overview overview-layout-section">
          <div className="overview-header">
            <div className="main-title">Overview</div>
            <div className="download-btns">
              <div className="main-download-btn">
                <Button
                  onClick={() => {
                    setIsPendingDownload(true);
                    downloadXLSFile({ isZip: false, jobId: jobId, setIsPendingDownload, setIsPendingZipDownload });
                  }}
                >
                  {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                </Button>
              </div>
              <div className="main-download-btn">
                <Button
                  onClick={() => {
                    setIsPendingZipDownload(true);
                    downloadXLSFile({ isZip: true, jobId: jobId, setIsPendingDownload, setIsPendingZipDownload });
                  }}
                >
                  {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                </Button>
              </div>
            </div>
          </div>
          <div className="overview-body">
            <div className="combinations-output-item">
              <div className="combinations-output-item-card">
                <MolecularStructure admetData={admetData} />
              </div>
            </div>
            <div className="combinations-output-item">
              <div className="combinations-output-item-card">{isCompareView ? <CompareMoleculeView setIsCompareView={setIsCompareView} setComparatorData={setComparatorData} /> : <ParameterGraphicalViewContanier setIsCompareView={setIsCompareView} admetData={admetData} comparatorData={comparatorData} />}</div>
            </div>
          </div>
        </div>
        <div className="combinations-output-overview">
          <div className="parameter-header">
            <div className="main-title">Parameter</div>
            <div className="header-toggle-sec">
              <Space>
                <Radio.Group value={threeCol} onChange={(e) => setThreeCol(e.target.value)}>
                  <Radio.Button value={false}>
                    <div className="icon-toggle" />
                  </Radio.Button>
                  <Radio.Button value>
                    <div className="icon-toggle" />
                  </Radio.Button>
                </Radio.Group>
              </Space>
            </div>
          </div>
          <ParameterValuesContainer admetData={admetData} isThreeCol={threeCol} />
        </div>
      </div>
    </Loader>
  );
}

export default AdmetPropertiesPage;
