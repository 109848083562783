import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SvgLoader({
  width, height, svgName, className, ...rest
}) {
  const clasess = classNames(`${svgName}-icon`, className);
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width={width} {...rest} height={height} className={clasess}>
      <use xlinkHref={`/spritemap.svg#sprite-${svgName}`} />
    </svg>
  );
}

SvgLoader.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  svgName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SvgLoader.defaultProps = {
  className: '',
};

export default SvgLoader;
