import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status

const PROTAC_CHECK_STATUS = 'PROTAC_CHECK_STATUS';
const PROTAC_CHECK_STATUS_SUCCESS = 'PROTAC_CHECK_STATUS_SUCCESS';
const PROTAC_CHECK_STATUS_FAILURE = 'PROTAC_CHECK_STATUS_FAILURE';
const PROTAC_CHECK_STATUS_RESET = 'PROTAC_CHECK_STATUS_RESET';

export const protacCheckStatusAction = createAction(PROTAC_CHECK_STATUS);
export const protacCheckStatusResetAction = createAction(PROTAC_CHECK_STATUS_RESET);

const protacCheckStatussSuccess = createAction(PROTAC_CHECK_STATUS_SUCCESS);
const protacCheckStatusFailure = createAction(PROTAC_CHECK_STATUS_FAILURE);

export const protacCheckStatusEpic = (actions$) => actions$.pipe(
  ofType(PROTAC_CHECK_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_protac}/userMoleculesList`, 'get')).pipe(
    map((res) => protacCheckStatussSuccess(res)),
    catchError((err) => of(protacCheckStatusFailure(err))),
  )),
);

export const protacCheckStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(protacCheckStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(protacCheckStatussSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(protacCheckStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(protacCheckStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// generate DESIGN

const GENERATE_DESIGN_PROTAC = 'GENERATE_DESIGN_PROTAC';
const GENERATE_DESIGN_PROTAC_SUCCESS = 'GENERATE_DESIGN_PROTAC_SUCCESS';
const GENERATE_DESIGN_PROTAC_FAILURE = 'GENERATE_DESIGN_PROTAC_FAILURE';

export const generateDesignProtacAction = createAction(GENERATE_DESIGN_PROTAC);

const generateDesignProtacSuccess = createAction(GENERATE_DESIGN_PROTAC_SUCCESS);

const generateDesignProtacFailure = createAction(GENERATE_DESIGN_PROTAC_FAILURE);
export const generateDesignProtacEpic = (actions$) => actions$.pipe(
  ofType(GENERATE_DESIGN_PROTAC),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_protac}/generateDesign?${action.payload}`, 'POST')).pipe(
    map((res) => generateDesignProtacSuccess(res)),
    catchError((err) => of(generateDesignProtacFailure(err))),
  )),
);

export const generateDesignProtacReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateDesignProtacAction, (state) => {
        state.loading = true;
      })
      .addCase(generateDesignProtacSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateDesignProtacFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
