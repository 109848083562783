/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Checkbox, Popover, Space,
} from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import useAppSelector from '../../../../utils/redux-hook';
import { getAssociatedProteinsDistributionAction, getMoreAssociatedProteinsDistributionAction } from '../../components/TopIndicationGraphs/AssociatedProteinDistributionGraph/logic';
import Loader from '../../../../common/components/Loader';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';
import Mixpanel from '../../../../utils/mixpanel';
import AddProteinsModal from '../AddProteinsModal';
import SaveProjectModal from '../SaveProjectModal';

function EditAssociatedProteinView({ proteinName, serviceName, jobId }) {
  const dispatch = useDispatch();
  const getAssociatedProteinDistributionResponse = useAppSelector((state) => state.getAssociatedProteinDistributionResponse);
  const getIndicationOutputResponse = useAppSelector((state) => state.getIndicationOutputResponse);
  const [mainData, setMainData] = useState([]);
  const [activeSort, setActiveSort] = useState('');
  const [defaultProteins, setDefaultProteins] = useState([]);
  const [selectedProteins, setSelectedProteins] = useState([]);
  const [isOpenAddProtein, setIsOpenAddProtein] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [proteinsAddedList, setProteinsAddedList] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const filterOptions = [
    { value: 'DiseaseSimilaritySignature', label: 'DS Score' },
    { value: 'PpiSignature', label: 'PPI Score' },
    { value: 'MolecularFunctionSignature', label: 'MF Score' },
    { value: 'LiteratureSignature', label: 'LM Score' },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedProteinsData, setSelectedProteinsData] = useState([]);
  const [manuallyAddedProteins, setManuallyAddedProteins] = useState([]);
  const [sortType, setSortType] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [requiredJobId, setRequiredJobId] = useState('');
  const [uncheckedList, setUncheckedList] = useState([]);
  const [requestBody, setRequestBody] = useState({});

  useEffect(() => {
    if (getAssociatedProteinDistributionResponse && getAssociatedProteinDistributionResponse.flag) {
      setMainData(getAssociatedProteinDistributionResponse.data?.table_data.extracted_data);
      const tempData = getAssociatedProteinDistributionResponse.data?.table_data.extracted_data;
      setTableData([...proteinsAddedList, ...(tempData || [])]);
      const tempSelectedProteinsData = getAssociatedProteinDistributionResponse.data?.table_data.extracted_data?.map((item) => ({
        preferred_name: item.preferred_name,
        unique_id: item.unique_id,
        sources: item.sources,
      }));
      setSelectedProteinsData(tempSelectedProteinsData);
      const preferredNames = getAssociatedProteinDistributionResponse.data?.table_data.extracted_data?.map((item) => item.preferred_name);
      setDefaultProteins([...preferredNames]);
      setSelectedProteins([...selectedProteins, ...preferredNames].filter((item) => !uncheckedList.includes(item)));
      if (totalCount === 0) {
        setTotalCount(getAssociatedProteinDistributionResponse.data?.table_data.total_count);
      }
    }
  }, [JSON.stringify(getAssociatedProteinDistributionResponse)]);
  useEffect(() => {
    setRequiredJobId(jobId);
    setSelectedOptions(filterOptions.map((option) => option.value));
  }, []);
  useEffect(() => {
  }, [isSubmitted]);
  const updateParameters = (type, order, key) => {
    Mixpanel.track('iepUpdateParametersClick', {
      action: 'update parameters click',
      type,
      order,
    });
    setActiveSort(key);
    setSortType(type);
    setSortOrder(order);
    const request = {
      job_id: requiredJobId,
      sort_key: type,
      sort_order: order,
      from: 1,
      size: 10,
      filters: selectedOptions.length === 0 ? undefined : JSON.stringify(selectedOptions),
    };
    dispatch(getAssociatedProteinsDistributionAction(queryString.stringify(request)));
    setCurrentPage(1);
  };
  const onAddProteinClick = (finalList) => {
    Mixpanel.track('iepAddProteinClick', {
      action: 'add protein click',
    });
    setManuallyAddedProteins([...manuallyAddedProteins, ...finalList]);
    const newProtein = finalList.map((item) => ({
      preferred_name: item.preferred_name,
      ...item.approaches_selected.reduce((acc, curr) => ({ ...acc, [curr]: 1 }), {}),
    }));
    setTableData([...newProtein, ...tableData]);
    setProteinsAddedList([...proteinsAddedList, ...newProtein]);
    setSelectedProteins([...selectedProteins, ...finalList.map((item) => item.preferred_name)]);
    setIsOpenAddProtein(!isOpenAddProtein);
    const allProteins = [...manuallyAddedProteins, ...finalList];
    const allTableData = [...newProtein, ...tableData];
    const allProteinsAddedList = [...proteinsAddedList, ...newProtein];
    const allSelectedProteins = [...selectedProteins, ...finalList.map((item) => item.preferred_name)];
  };
  const handleFilterChange = (e) => {
    setSelectedOptions(e);
  };

  const handleResetToDefault = () => {
    Mixpanel.track('iepResetToDefaultClick', {
      action: 'reset to default click',
    });
    setTableData(mainData);
    setSelectedProteins(mainData.map((p) => p.preferred_name));
    setProteinsAddedList([]);
    setSelectedProteinsData(mainData);
    setIsChecked(true);
  };

  const clearFilters = () => {
    Mixpanel.track('iepClearFiltersClick', {
      action: 'clear filters click',
    });
    setSelectedOptions([]);
  };

  const applyFilters = () => {
    Mixpanel.track('iepApplyFiltersClick', {
      action: 'apply filters click',
    });
    const request = {
      job_id: requiredJobId,
      sort_key: sortType === '' ? undefined : sortType,
      sort_order: sortOrder === '' ? undefined : sortOrder,
      from: 1,
      size: 10,
      filters: selectedOptions.length === 0 ? undefined : JSON.stringify(selectedOptions),
    };
    dispatch(getAssociatedProteinsDistributionAction(queryString.stringify(request)));
    setIsOpenFilters(!isOpenFilters);
    setCurrentPage(1);
  };
  const onChangedAll = (value) => {
    setIsChecked(!isChecked);
    const tempSelectedValues = manuallyAddedProteins.map((item) => item.preferred_name);
    if (value) {
      setSelectedProteins([...tempSelectedValues, ...defaultProteins]);
    } else {
      setSelectedProteins([]);
    }
  };
  const onChangedProtein = (item) => {
    if (selectedProteins.includes(item.preferred_name)) {
      setSelectedProteins(selectedProteins.filter((p) => p !== item.preferred_name));
      setSelectedProteinsData(tableData.filter((p) => p.preferred_name !== item.preferred_name));
      setIsChecked(false);
      setUncheckedList([...uncheckedList, item.preferred_name]);
    } else {
      setSelectedProteins([...selectedProteins, item.preferred_name]);
      setSelectedProteinsData([...tableData, item]);
      setUncheckedList(uncheckedList.filter((p) => p !== item.preferred_name));
      if (tableData.map((p) => p.preferred_name).filter((p) => ![...selectedProteins, item.preferred_name].includes(p)).length === 0) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }
  };
  // (p) => !selectedProteinsData.some((n) => n.preferred_name === p.preferred_name)
  const onSubmitted = () => {
    const added = selectedProteins.filter((p) => !defaultProteins.includes(p));
    const removed = defaultProteins.filter((p) => !selectedProteins.includes(p));
    const addedProteins = manuallyAddedProteins.filter((p) => added.includes(p.preferred_name));
    const removedProteins = mainData
      .filter((p) => removed.includes(p.preferred_name))
      .map((p) => ({
        preferred_name: p.preferred_name,
        unique_id: p.unique_id,
        approaches_selected: p.source,
      }));
    const request = {
      params: {
        service: serviceName,
        protein_name: proteinName,
        previous_job_id: requiredJobId,
      },
      body: {
        proteins_removed: Array.from(new Set(removedProteins.map(JSON.stringify))).map(JSON.parse),
        proteins_added: addedProteins,
        synonyms_net: getIndicationOutputResponse.data.data?.jobData?.user_input.synonyms_net,
        approach_weightage: getIndicationOutputResponse.data.data?.jobData?.user_input.approach_weightage,
        synonyms_added: getIndicationOutputResponse.data.data?.jobData?.user_input.synonyms_added,
        synonyms_removed: getIndicationOutputResponse.data.data?.jobData?.user_input.synonyms_removed,
        rare_indication_flag: getIndicationOutputResponse.data.data?.jobData?.user_input.rare_indication_flag,
      },
    };
    setRequestBody(request);
    if (Object.keys(requestBody) !== 0) {
      setIsSubmitted(true);
    }
    Mixpanel.track('iepGenerateIndicationsStage2Click', {
      action: 'generate indications stage 2 submit click',
      addedProteins,
      removedProteins,
    });
  };
  const loadMoreTableData = () => {
    const request = {
      job_id: requiredJobId,
      sort_key: sortType === '' ? undefined : sortType,
      sort_order: sortOrder === '' ? undefined : sortOrder,
      from: currentPage + 1,
      size: 10,
      filters: selectedOptions.length === 0 ? undefined : JSON.stringify(selectedOptions),
    };
    dispatch(getMoreAssociatedProteinsDistributionAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };
  // const addProteinDropdown = () => (
  //   <div className="add-protein-dropdown-menu">
  //     <InputDropdown index={0} changeHandler={handleProteinChange} proteinName={protein} isDisabled={false} setProteinName={() => {}} setProteinItem={setProteinItem} />
  //     <Select value={sourceArea} onChange={handleSourceAreaChange} placeholder="Select Source Area">
  //       {filterOptions.map((option) => (
  //         <Option key={option.value} value={option.value}>
  //           {option.label}
  //         </Option>
  //       ))}
  //     </Select>
  //     <div className="add-protein-dropdown-menu-footer">
  //       <Button className="clear-btn" onClick={clearInputs}>
  //         Clear
  //       </Button>
  //       <Button className="add-btn" onClick={addProtein} disabled={proteinItem.gene_name === '' || sourceArea === ''}>
  //         Add Protein
  //       </Button>
  //     </div>
  //   </div>
  // );

  const filterDropdown = () => (
    <div className="dropdown-menu">
      <Checkbox.Group onChange={handleFilterChange} defaultValue={selectedOptions}>
        <Space>
          {filterOptions.map((option) => (
            <Checkbox key={option.value} value={option.value} checked={selectedOptions.includes(option.value)}>
              {option.label}
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
      <div className="add-protein-dropdown-menu-footer">
        <Button className="clear-btn" onClick={clearFilters}>
          Clear
        </Button>
        <Button className="apply-btn" onClick={applyFilters}>
          Apply
        </Button>
      </div>
    </div>
  );
  const tableRow = (preferredName, isManuallyAdded, dsScore, ppiScore, mfScore, lmScore) => (
    <div className="table-row">
      <div className="table-cell">
        {preferredName}
        {isManuallyAdded && <span className="manually-added">(Manually Added)</span>}
      </div>
      <div className="table-cell">{dsScore === undefined || dsScore === null || dsScore === 0 ? '-' : dsScore}</div>
      <div className="table-cell">{ppiScore === undefined || ppiScore === null || ppiScore === 0 ? '-' : ppiScore}</div>
      <div className="table-cell">{mfScore === undefined || mfScore === null || mfScore === 0 ? '-' : mfScore}</div>
      <div className="table-cell">{lmScore === undefined || lmScore === null || lmScore === 0 ? '-' : lmScore}</div>
    </div>
  );
  const tableRowList = () => {
    const rows = (
      <Checkbox.Group value={selectedProteins}>
        <Space direction="vertical">
          {tableData?.map((item) => (
            <div className={`table-row-container${item.new_added_protein ? ' added' : ''}`}>
              <Checkbox key={item.preferred_name} value={item.preferred_name} checked={selectedProteins.includes(item.preferred_name)} onChange={() => onChangedProtein(item)}>
                {tableRow(item.preferred_name, item.new_added_protein, item.DiseaseSimilaritySignature, item.PpiSignature, item.MolecularFunctionSignature, item.LiteratureSignature)}
              </Checkbox>
            </div>
          ))}
        </Space>
      </Checkbox.Group>
    );
    return rows;
  };
  return (
    <div className="edit-protein-view-main">
      <div className="edit-protein-btns">
        <div className="reset-to-default-btn" style={{ cursor: 'pointer' }} onClick={() => handleResetToDefault()} aria-hidden>
          Reset List to Default
        </div>
        <div className="add-dropdown">
          <div
            className="add-btn"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              Mixpanel.track('iepAddProteinClick', {
                action: 'add protein dropdown click',
              });
              setIsOpenAddProtein(!isOpenAddProtein);
              setIsOpenFilters(false);
            }}
            aria-hidden
          >
            Add
          </div>
        </div>
        {/* <Popover overlayClassName="add-dropdown-popover" open={isOpenAddProtein} placement="bottomRight" content={addProteinDropdown()}>

        </Popover> */}

        <Popover overlayClassName="filter-dropdown-popover" open={isOpenFilters} placement="bottomRight" content={filterDropdown()}>
          <div className="filters-dropdown">
            <div
              className="filters-btn"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                Mixpanel.track('iepFiltersClick', {
                  action: 'filters dropdown click',
                });
                setIsOpenFilters(!isOpenFilters);
                setIsOpenAddProtein(false);
              }}
              aria-hidden
            >
              Filters
            </div>
          </div>
        </Popover>
      </div>
      <div className="table-container-main">
        <div className="table-header">
          <div className="table-cell">
            <Checkbox checked={isChecked} onChange={(e) => onChangedAll(e.target.checked)}>
              Preferred Name
            </Checkbox>
          </div>
          <div className="table-cell">
            <div className="title-with-sort">
              <div className="table-cell-title">DS Score</div>
              <div className="up-down-btns" style={{ fontSize: '12px' }}>
                <div
                  className={`up-btn ${activeSort === 'asc-disease-similarity-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('DiseaseSimilaritySignature', 'asc', 'asc-disease-similarity-signature');
                  }}
                  aria-hidden
                />
                <div
                  className={`down-btn ${activeSort === 'desc-disease-similarity-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('DiseaseSimilaritySignature', 'desc', 'desc-disease-similarity-signature');
                  }}
                  aria-hidden
                />
              </div>
            </div>
          </div>
          <div className="table-cell">
            <div className="title-with-sort">
              <div className="table-cell-title">PPI Score</div>
              <div className="up-down-btns" style={{ fontSize: '12px' }}>
                <div
                  className={`up-btn ${activeSort === 'asc-ppi-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('PpiSignature', 'asc', 'asc-ppi-signature');
                  }}
                  aria-hidden
                />
                <div
                  className={`down-btn ${activeSort === 'desc-ppi-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('PpiSignature', 'desc', 'desc-ppi-signature');
                  }}
                  aria-hidden
                />
              </div>
            </div>
          </div>
          <div className="table-cell">
            <div className="title-with-sort">
              <div className="table-cell-title">MF Score</div>
              <div className="up-down-btns" style={{ fontSize: '12px' }}>
                <div
                  className={`up-btn ${activeSort === 'asc-molecular-function-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('MolecularFunctionSignature', 'asc', 'asc-molecular-function-signature');
                  }}
                  aria-hidden
                />
                <div
                  className={`down-btn ${activeSort === 'desc-molecular-function-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('MolecularFunctionSignature', 'desc', 'desc-molecular-function-signature');
                  }}
                  aria-hidden
                />
              </div>
            </div>
          </div>
          <div className="table-cell">
            <div className="title-with-sort">
              <div className="table-cell-title">LM Score</div>
              <div className="up-down-btns" style={{ fontSize: '12px' }}>
                <div
                  className={`up-btn ${activeSort === 'asc-literature-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('LiteratureSignature', 'asc', 'asc-literature-signature');
                  }}
                  aria-hidden
                />
                <div
                  className={`down-btn ${activeSort === 'desc-literature-signature' && 'active'}`}
                  onClick={() => {
                    updateParameters('LiteratureSignature', 'desc', 'desc-literature-signature');
                  }}
                  aria-hidden
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-body-container">
          {/* {tableRowList()} */}
          <Loader loading={getAssociatedProteinDistributionResponse.loading && !getAssociatedProteinDistributionResponse.data} error={getAssociatedProteinDistributionResponse.error} height="calc(100vh - 206px)">
            <LazyLoadDiv className="datatable-container-inner" id="table-list" total={totalCount} currentTotal={(mainData || []).length} height="calc(100vh - 460px)" loadMoreRows={loadMoreTableData}>
              {tableRowList()}
            </LazyLoadDiv>
          </Loader>
        </div>
      </div>
      <div className="protein-view-footer-sec">
        <div className="protein-submit-btn" onClick={() => onSubmitted()} aria-hidden>
          Submit
        </div>
      </div>
      <AddProteinsModal open={isOpenAddProtein} setOpen={setIsOpenAddProtein} onAddClick={onAddProteinClick} />
      <SaveProjectModal name={getIndicationOutputResponse.data.data?.jobData?.user_input.protein_name} open={isSubmitted} setOpen={setIsSubmitted} requestBody={requestBody} />
    </div>
  );
}

EditAssociatedProteinView.propTypes = {
  proteinName: PropTypes.string.isRequired,
  serviceName: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
};
export default EditAssociatedProteinView;
