/* eslint-disable */
import { React, useEffect, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import axios from 'axios';
import Rcookie from 'react-cookies';
import Header from '../../../../common/components/Header';
import CircularGauge from '../../components/CircularGauge';
import { terminalsPath } from '../../../../app/components/Card/data';
import { getIndicationOutputDRAction, isReadDROutputAction, getMoreIndicationOutputDRAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';
import { drCheckStatusResetAction } from '../DRInputPage/logic';
import LazyLoadDiv from '../../../../common/components/LazyLoadDiv';

function DROutputPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const getIndicationOutputDRResponse = useAppSelector((state) => state.getIndicationOutputDRResponse);
  const [listData, setListData] = useState([]);
  const jobId = searchParams.get('jobId');
  const [currentPage, setCurrentPage] = useState(1);
  const [isPendingDownload, setIsPendingDownload] = useState(false);
  const [isPendingZipDownload, setIsPendingZipDownload] = useState(false);
  const [drugName, setDrugName] = useState('');
  const [drugId, setDrugId] = useState('');
  const [service, setService] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const request = {
      job_id: jobId,
      is_read: true,
    };
    dispatch(isReadDROutputAction(queryString.stringify(request)));
    dispatch(drCheckStatusResetAction());
  });

  useEffect(() => {
    const request = {
      job_id: jobId,
      from: currentPage,
      size: 10,
    };
    dispatch(getIndicationOutputDRAction(queryString.stringify(request)));
  }, []);

  useEffect(() => {
    if (getIndicationOutputDRResponse.flag) {
      setDrugName(getIndicationOutputDRResponse?.data?.data?.job_data?.user_input?.drugName);
      setService(getIndicationOutputDRResponse?.data?.data?.job_data?.user_input?.drugType);
      setDrugId(getIndicationOutputDRResponse?.data?.data?.job_data?.user_input?.drugID);
    }
  }, [getIndicationOutputDRResponse]);

  useEffect(() => {
    let removeList = ['Prion', 'Diabetes Mellitus, Type 2', 'insulin resistance', 'metabolic syndrome', 'Diabetes Mellitus', 'renal failure', 'cancer', 'hyperglycemia', 'Heart failure ', 'Prediabetes ', '', 'Insulin sensityvity ', 'Cardiopathy', 'Hypoxia', 'Low Blood Glucose', 'Stein Leventhal Syndrome', 'Ketoacidosis', 'Diabetic Nephropathy', 'Covid 19', 'Hyperinsulinemia', 'Anemia', 'Genital Infection', 'Kidney; Disease (functional)', 'Kidney Disorders', 'Urinary Tract Infections', 'Heart Failure With Reduced Ejection Fraction', 'Cardiac Failure', 'Hepatitis C', 'Cardiotoxicity', 'Lactic Acidosis', 'Cognitive Impairment', 'Reperfusion Injury', 'Diabetic Cardiomyopathy'];
    removeList = removeList.map((item) => item.toLowerCase());
    setListData(() => {
      let arr = getIndicationOutputDRResponse?.data?.data?.extracted_data?.slice(0, 100);
      if (drugName === 'metformin') {
        arr = arr?.filter((item) => !removeList.includes(item === '' ? '' : item.preferred_name.toLowerCase()));
      }
      return arr;
    });
  }, [JSON.stringify(getIndicationOutputDRResponse)]);

  function formatNumber(value) {
    if (value === undefined || value === '') {
      return '-';
    }
    const val = parseFloat(value);
    return Math.ceil(val * 100) / 100;
  }

  function capitalizeFirstLetterOfEveryWord(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  const infoComponent = (heading, value) => (
    <div className="info-component-main">
      <div className="info-component-heading">{heading}</div>
      <div className="info-component-value">
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      </div>
    </div>
  );
  const displayDrugItem = (item) => {
    const therapeuticMap = {
      'breast cancer': ['Onclology'],
      'systemic lupus erythematosus': ['Autoimmune'],
      'pulmonary arterial hypertension': ['Vascular disorder'],
      melas: ['Rare disease'],
      'ovarian cancer': ['Onclology'],
      'amyotrophic lateral sclerosis': ['Neurology'],
      'early infantile epileptic encephalopathy': ['Neurology'],
      "parkinson's disease": ['Neurology'],
      lymphoma: ['Oncology'],
      'diffuse large b-cell lymphoma': ['Oncology'],
      'kearns sayre syndrome': ['Rare disease'],
      'fatty (change of) liver, not elsewhere classified': ['Hepatology'],
      'dementia alzheimer': ['Neurology'],
      'endometrial hyperplasia': ['Gyneacology'],
      'leigh syndrome': ['Rare disease'],
    };

    const val = therapeuticMap[item.preferred_name?.toLowerCase()] !== undefined ? therapeuticMap[item.preferred_name.toLowerCase()] : item.therapeutic_area;
    const ta = item.preferred_name?.toLowerCase().includes('cancer') ? 'Oncology' : val !== '' ? val.join()[0]?.toUpperCase() + val.join().slice(1) : '-';

    return (
      <div className="combinations-drugs-info">
        <div className="info-component-row">
          <div className="info-component">
            <div className="info-component">
              <div className="info-component-main">
                <div className="info-component-heading">Therapeutic Area</div>
                <div className="info-component-value">
                  <Tooltip placement="topLeft" title={ta === 'undefined' ? '-' : ta}>
                    {ta === 'undefined' ? '-' : ta}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="info-component">{infoComponent('Network DI Score', formatNumber(item.network_di_score !== undefined ? item.network_di_score : item.network_DI_score))}</div>
          <div className="info-component">{infoComponent('Network DTPI Score', formatNumber(item.network_dtpi_score !== undefined ? item.network_dtpi_score : item.network_DTPI_score))}</div>
          <div className="info-component">{infoComponent('Enrichment CT Score', formatNumber(item.enrichment_ct_score !== undefined ? item.enrichment_ct_score : item.CT_score))}</div>
        </div>
        <div className="info-component-row">
          <div className="info-component">{infoComponent('Literature Mining Score', formatNumber(item.literature_mining_score))}</div>
          <div className="info-component">{infoComponent('Network DTI Score', formatNumber(item.network_dti_score !== undefined ? item.network_dti_score : item.network_DTI_score))}</div>
          <div className="info-component">{infoComponent('Saw Score', formatNumber(item.saw_score))}</div>
          <div className="info-component">{infoComponent('Clinical Trial Score', formatNumber(item.clinical_score !== undefined ? item.clinical_score : item.clinical_trial_score))}</div>
        </div>
      </div>
    );
  };

  const displayOutputList = () =>
    listData?.map((item) => (
      <div key={crypto.randomUUID()} className="combinations-output-item">
        <div className="item-title">{item.preferred_name === undefined || item.preferred_name === '' ? '' : capitalizeFirstLetterOfEveryWord(item.preferred_name)}</div>
        <div className="combinations-item-content">
          <div className="combinations-drugs-list">{displayDrugItem(item)}</div>
          <CircularGauge value={formatNumber(item.topsis_score)} />
        </div>
      </div>
    ));

  const downloadXLSFile = async ({ isZip }) => {
    if (isZip) {
      setIsPendingZipDownload(true);
    } else {
      setIsPendingDownload(true);
    }
    const headers = { Authorization: Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) ? Rcookie.load(`${process.env.app}_accessToken_${process.env.type}`) : '', 'Content-Type': 'blob', 'Access-Control-Allow-Origin': `${process.env.applicationUrl}` };
    const apiUrl = isZip ? `${process.env.apiUrl_dr}/zipDownload?job_id=${jobId}` : `${process.env.apiUrl_dr}/resultDownload?job_id=${jobId}`;

    try {
      const response = await axios({
        method: 'GET',
        url: apiUrl,
        responseType: 'arraybuffer',
        headers,
      });
      setIsPendingDownload(false);
      setIsPendingZipDownload(false);
      const outputFilename = isZip ? `${Date.now()}.zip` : `${Date.now()}.xls`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const loadMoreRows = () => {
    const request = {
      job_id: jobId,
      from: currentPage + 1,
      size: 10,
    };
    dispatch(getMoreIndicationOutputDRAction(queryString.stringify(request)));
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="main-page main-page-modules">
      <Header className="header-home dr-header" logo="dr-logo" />
      <Loader loading={getIndicationOutputDRResponse.loading} error={getIndicationOutputDRResponse.error} height="calc(100vh - 0px)">
        <LazyLoadDiv className="dr-lazy-loading" id="dr-lazy-loading-output" total={1100} currentTotal={10} loadMoreRows={() => loadMoreRows()} height="calc(100vh - 0px)">
          <div className="ct-home-page">
            <div className="main-heading">
              <div className="back-btn">
                <Button
                  className="back-btn-icon"
                  onClick={() => {
                    navigate(`${terminalsPath.metad3_dr}?service=${service}&drugName=${drugName}&drugId=${drugId}`);
                  }}
                >
                  Back
                </Button>
              </div>
              <div className="main-title">{`Prioritised list of indications for "${drugName}"`}</div>
              <div className="main-description">Based on Innoplexus network approach, here is prioritized list of Indication.</div>
              <div className="download-btns">
                <div className="main-download-btn">
                  <Button
                    onClick={() => {
                      setIsPendingDownload(true);
                      downloadXLSFile({ isZip: false });
                    }}
                    disabled={isPendingDownload}
                  >
                    {`${isPendingDownload ? 'Downloading...' : 'Download Output'}`}
                  </Button>
                </div>
                <div className="main-download-btn">
                  <Button
                    onClick={() => {
                      setIsPendingZipDownload(true);
                      downloadXLSFile({ isZip: true });
                    }}
                    disabled={isPendingZipDownload}
                  >
                    {`${isPendingZipDownload ? 'Downloading...' : 'Download .zip'}`}
                  </Button>
                </div>
              </div>
            </div>
            <div className="dr-output-list" style={{ height: '300', minHeight: '300', maxHeight: '300' }}>
              {displayOutputList()}
            </div>
          </div>
        </LazyLoadDiv>
      </Loader>
    </div>
  );
}

export default DROutputPage;
