import React, { useState } from 'react';
import PropTypes from 'prop-types';
import terminalsDummyData, { getTerminalInfoData } from './data';
import TerminalInfo from '../TerminalInfo';

function ExploreSolution({ setOpen }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  const displayCarousaldata = () => terminalsDummyData.map((item, index) => (
    <div
      key={crypto.randomUUID()}
      className="carousel-card"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <div className={`caro-icon icon-${index} ${index === currentIndex && 'active'}`} />
      <div
        onClick={() => handleClick(index)}
        aria-hidden
        style={{
          fontSize: index === currentIndex ? '30px' : '20px',
          fontWeight: '400',
          paddingLeft: index === currentIndex ? '17px' : '23px',
          paddingTop: '20px',
          paddingRight: '13px',
          paddingBottom: '20px',
          cursor: 'pointer',
          transition: 'all 0.3s ease-in-out 0.2s',
        }}
      >
        {item.name}
      </div>
    </div>
  ));
  return (
    <div className="explore-and-scolutions">
      <div className="sub-header-main-page">Unveiling Our Drug Discovery Software Suite</div>
      <div className="explore-and-scolutions-content">
        <div className="slider-custom slider-vertical">
          <div className="slider-vertical-content-slide slider-vertical-content-scroll">
            <div className="slider-vertical-content-slide-card">{displayCarousaldata()}</div>
          </div>
        </div>
        <TerminalInfo current={currentIndex} data={getTerminalInfoData(terminalsDummyData)} setOpen={setOpen} />
      </div>
    </div>
  );
}

ExploreSolution.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default ExploreSolution;
