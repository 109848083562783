import {
  Checkbox, Divider, Radio, Space,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { getSampleFiltersAction } from '../../../../pages/NewBiomarkerInputPage/logic';
import { getSamplesAction } from '../logic';
import useAppSelector from '../../../../../../utils/redux-hook';

function CategoriesViewSamples({
  studiesSelected, filtersSelected, setFiltersSelected, setSamplesSelected, displayCategoriesFlag, isTryExample,
}) {
  const [categoryValue, setCategoryValue] = useState('All samples');

  const [filtersStudyValues, setFilterStudyValues] = useState([]);
  const [studyCount, setStudyCount] = useState({});
  const getSampleFiltersResponse = useAppSelector((state) => state.getSampleFiltersResponse);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const arr = studiesSelected.map((item) => item.study_id);

    setFilterStudyValues(arr);
  }, []);

  useEffect(() => {
    if (getSampleFiltersResponse.loading !== true) {
      const categoriesList = getSampleFiltersResponse?.data?.category?.map((item) => {
        const obj = { name: item.display_name, count: item.count };
        return obj;
      });
      setCategories(categoriesList);

      setStudyCount(() => {
        const temp = {};
        studiesSelected.forEach((item) => {
          temp[item.study_id] = getSampleFiltersResponse?.data?.study?.find((study) => study?.display_name === item?.study_id)?.count ?? 0;
        });
        return temp;
      });
    }
  }, [getSampleFiltersResponse]);

  const onChangeCategory = (e) => {
    setCategoryValue(e.target.value);
    setFiltersSelected((prev) => ({ ...prev, sampleCategory: e.target.value }));
  };
  const onChangeStudies = (checkedValues) => {
    setFiltersSelected((prev) => ({ ...prev, studies: checkedValues }));
    setSamplesSelected([]);
  };

  useEffect(() => {
    const body = {
      filters: {},
    };
    let arr = [];
    let arr2 = [];
    arr = filtersSelected?.study || [];
    for (let i = 0; i < arr?.length; i += 1) {
      if (filtersSelected?.studies?.includes(arr[i])) {
        arr2 = [...arr2, { operator: 'OR', value: arr[i] }];
      } else {
        arr2 = [...arr2, { operator: 'NOT', value: arr[i] }];
      }
    }
    body.filters.study = arr2;

    if (categoryValue !== 'All samples') {
      body.filters.category = [{ operator: 'AND', value: categoryValue }];
    }

    dispatch(getSampleFiltersAction(body));

    let sampleIds = [];
    if (filtersSelected?.group_1_sample_ids !== undefined) sampleIds = [...sampleIds, ...filtersSelected.group_1_sample_ids];
    if (filtersSelected?.group_2_sample_ids !== undefined) sampleIds = [...sampleIds, ...filtersSelected.group_2_sample_ids];

    body.filters.sample = sampleIds.map((item) => ({ operator: 'NOT', value: item }));
    const request = {
      params: queryString.stringify({
        from: 1,
        size: 10,
      }),
      body,
    };
    dispatch(getSamplesAction(request));
  }, [filtersSelected]);

  useEffect(() => {
    onChangeStudies(filtersStudyValues);
  }, [filtersStudyValues]);

  const displayCategories = () => (
    <div className="categories-container" style={{ marginBottom: '20px' }}>
      <Radio.Group onChange={onChangeCategory} value={categoryValue} disabled={isTryExample}>
        <Space direction="vertical">
          {categories?.map((item) => (
            <Radio key={item.name} value={item.name}>
              {`${item.name} (${item.count})`}
              {' '}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </div>
  );

  const displayStudies = () => (
    <div className="studies-container">
      {/* <Checkbox.Group
        onChange={(e) => {
          if (filtersStudyValues.length > 1) onChangeStudies(e);
        }}
      > */}
      <Space direction="vertical">
        {studiesSelected?.map((item, index) => {
          const arr = studiesSelected.map((it) => filtersSelected?.studies?.includes(it.study_id));
          return (
            <Checkbox
              onChange={(e) => {
                if (filtersStudyValues.includes(e.target.value)) {
                  setFilterStudyValues((prev) => prev.filter((it) => it !== e.target.value));
                } else {
                  setFilterStudyValues((prev) => [...prev, e.target.value]);
                }
              }}
              checked={(arr !== undefined && arr[index]) || false}
              key={item.study_id}
              value={item.study_id}
              disabled={(filtersStudyValues?.length === 1 && filtersStudyValues?.includes(item.study_id)) || isTryExample}
            >
              {`${item.study_id} (${studyCount[item.study_id]})`}
            </Checkbox>
          );
        })}
      </Space>
      {/* </Checkbox.Group> */}
    </div>
  );
  return (
    <div className={`filters-main ${displayCategoriesFlag ? 'show' : 'hide'}`}>
      <div className="filters-main-content">
        <div className="filter-title">
          <div className="filter-icon" />
          Filters
        </div>
        <Divider />
        <div className="category-title filters-main-content-title">Categories</div>
        {displayCategories()}
        <Divider />
        <div className="studies-title filters-main-content-title">Selected Studies</div>
        {displayStudies()}
      </div>
    </div>
  );
}
CategoriesViewSamples.propTypes = {
  studiesSelected: PropTypes.arrayOf.isRequired,
  filtersSelected: PropTypes.objectOf.isRequired,
  setFiltersSelected: PropTypes.func.isRequired,
  setSamplesSelected: PropTypes.func.isRequired,
  displayCategoriesFlag: PropTypes.bool.isRequired,
  isTryExample: PropTypes.bool.isRequired,
};
export default CategoriesViewSamples;
