/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as $3Dmol from '3dmol/build/3Dmol.js';
import InputCard from '../../components/InputCard';

function SelectService({ inputHandler, service }) {
  const [card, setCard] = useState([-1, -1]);
  const services = ['PROTAC_DESIGN', 'WARHEAD_DESIGN'];
  const cardRef = useRef(null);

  useEffect(() => {
    services.forEach((item, index) => {
      if (item === service) {
        setCard((prev) => {
          const newCard = prev;
          newCard[index] = 1;
          return newCard;
        });
        inputHandler({ card: services[index] });
      }
    });
  }, []);

  const handleCard = (index) => {
    setCard((prev) => {
      const newCard = prev.map((item, i) => {
        if (index === i) {
          return 1;
        }
        return 0;
      });
      return newCard;
    });
    inputHandler({ card: services[index] });
  };

  useEffect(() => {
    if (cardRef.current != null) {
      const viewer = $3Dmol.createViewer(cardRef.current, { backgroundColor: 'orange' });
      $3Dmol.download('pdb:4UAA', viewer, {}, function () {
        viewer.setBackgroundColor(0xffffffff);
        viewer.setStyle({}, { stick: {} });
        viewer.vibrate(10, 1);
        viewer.animate({ loop: 'forward', reps: 1 });

        viewer.zoomTo();
        viewer.render();
      });
    }
  }, [cardRef.current]);

  return (
    <div className="service-container service-container-protac">
      <div className="cards-container">
        <div className="cards-container-title">Select a Service</div>
        <div className="cards-list">
          <InputCard index={0} heading=" Protac Design" cardHandler={handleCard} isSelected={card[0]} colorClass="card-one" img="warhead-card" status="Open" />
          <InputCard index={1} heading=" Warhead Design" cardHandler={handleCard} isSelected={card[1]} colorClass="card-three" img="protac-card" status="Coming soon" />
        </div>
      </div>
    </div>
  );
}

SelectService.propTypes = {
  inputHandler: PropTypes.func.isRequired,
  service: PropTypes.string.isRequired,
};

export default SelectService;
