import React from 'react';
import PropTypes from 'prop-types';
import TargetFeaturesNetworkGraph from '../../components/DiseaseBasedGraphs/TargetFeaturesNetworkGraph';

function TargetFeaturesNetworkView({ jobId }) {
  return (
    <>
      <div className="tfn-title graph-card-title">Target-Features Network Visualisation</div>
      <TargetFeaturesNetworkGraph jobId={jobId} />
    </>
  );
}

TargetFeaturesNetworkView.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default TargetFeaturesNetworkView;
