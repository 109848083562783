/* eslint-disable */
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { XFilled } from '@ant-design/icons';
import { getLiteratureMiningAction } from './logic';
import Loader from '../../../../../common/components/Loader';
import useAppSelector from '../../../../../utils/redux-hook';

function LiteratureMiningGraph({jobId}) {
  const [data, setData] = useState([]);
  const [legends, setLegends] = useState([]);

  const dispatch = useDispatch();
  const getLiteratureMiningResponse = useAppSelector((state) => state.getLiteratureMiningResponse);

  useEffect(() => {
    const body = {
      // job_id: '312a28b3-60cf-4970-b951-613735cbf459',
      job_id: jobId,
    };
    dispatch(getLiteratureMiningAction(queryString.stringify(body)));
  }, []);

  useEffect(() => {
    if (getLiteratureMiningResponse.flag) {
      const arr = [];
      const arr2 = new Map();
      for (let i = 0; i < getLiteratureMiningResponse.data?.length; i += 1) {
        let x = getLiteratureMiningResponse.data[i].literature_mining_score;
        x = Math.round(x * 100) / 100
        let y = getLiteratureMiningResponse.data[i].topsis_score ?? Math.random() * 10;
        y = Math.round(y * 100) / 100
        const ta = getLiteratureMiningResponse.data[i].therapeutic_area;
        const indicationName = getLiteratureMiningResponse.data[i].indication_name;
        const color = getLiteratureMiningResponse.data[i].colour;
        arr.push([x, y, ta, indicationName, color]);
        if (color !== undefined) arr2.set(ta, color);
      }
      const arr3 = [];
      for (const [key, value] of arr2) {
        arr3.push({ ta: key, color: value });
      }
      setLegends(arr3);
      setData(arr);
    }
  }, [getLiteratureMiningResponse]);


  const displayLegends = () => {
    var arr = [];
    for(const [key, value] of Object.entries(legends)) {
      arr.push(<div className="pathway-enrichment-graph-legend-custom">
        <div className="pathway-enrichment-graph-legend-icon">
          <XFilled style={{color: `${value['color']}`, fontSize: '120%'}}/>
        </div>
        <div className="pathway-enrichment-graph-legend-name" style={{paddingLeft: '5px'}}>
          {value['ta']}
        </div>
      </div>);
    }
    return (
      <div className="pathway-enrichment-graph-header literature">
        {[...arr]}
      </div>
    ) ;

  }

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, [legends]);

  const option = {
    xAxis: {
      type: 'value',
      name: 'Literature Mining Score --->',
      nameLocation: 'middle',
      nameGap: 40,
      nameTextStyle: {
        fontFamily: 'Inter',
        fontSize: '15px',
        color: '#1F1F1F',
        fontWeight: '500',
      },

      axisLine: {
        borderWidth: 1,
        show: true,
        borderColor: '#FFF',
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
      },
    },
    grid: { containLabel: true, left: 0, right: 20 },
    yAxis: {
      type: 'value',
      // onZero: false,
      scale: true,
      axisTick: {
        alignWithLabel: true,
      },
      axisLine: {
        offset: {
          onZero: false,
        },
      },
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
        },
        interval: 0,
      },
    },
    tooltip: {
      show: true,
      backgroundColor: 'rgba(255,255,255,0.7)',
      position: 'top',
      confine:'true',
      formatter(param) {
        let x = '  ' + param.data[0];
        let y = '  ' + param.data[1];
        const ta = '  ' + param.data[2];
        const indicationName = '  ' + param.data[3];
        return '<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;margin-bottom: 7px; display: flex;flex-direction: column">'
                      + '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">Indication: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">'+`${indicationName}`+'</p></div>'
                      + '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">Therapeutic Area: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">'+` ${ta}`+'</p></div>'
                      + '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">Topsis Score: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">'+`${y}`+'</p></div>'
                      + '<div style="display: flex;flex-direction: row;justify-content: space-between"><p style="font-family: Inter;font-size: 14px;color: #59748A">LM Sore: </p><p style="font-family: Inter;font-size: 15px;color: #121417;margin-left:1em">'+`${x}`+'</p></div>'
                      + '</div>';
      },
    },
    series: [
      {
        data: [['', '', 0], ...data],
        itemStyle: {
          color(param) {
            return param.data[4];
          },
        },
        type: 'scatter',
        symbolSize: 20,
      },
    ],
  };
  return (
    <div className="pathway-enrichment-graph-container" width="100%">
      <Loader loading={getLiteratureMiningResponse.loading}>
        <div className="card-content-ctp-title">
          Literature Mining Graph
        </div>
        {/* <div className="pathway-enrichment-graph-header"> */}
          {displayLegends()}
        {/* </div> */}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{
              fontFamily: 'Inter', fontSize: '15px', color: '#1F1F1F', fontWeight: '500', transform: 'rotate(180deg)', width: '50px', writingMode: 'vertical-rl',
            }}
            >
              {'Topsis Score --->'}
            </span>
          </div>
          <ReactECharts option={option} style={{ height: '800px', width: '100%' }} />
        </div>
      </Loader>
    </div>
  );
}

LiteratureMiningGraph.propTypes = {
  jobId: PropTypes.string.isRequired,
}


export default LiteratureMiningGraph;
