import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

// get input suggest details
const GET_SAMPLES = 'GET_SAMPLES';
const GET_SAMPLES_SUCCESS = 'GET_SAMPLES_SUCCESS';
const GET_MORE_SAMPLES = 'GET_MORE_SAMPLES';
const GET_MORE_SAMPLES_SUCCESS = 'GET_MORE_SAMPLES_SUCCESS';
const GET_SAMPLES_FAILURE = 'GET_SAMPLES_FAILURE';

export const getSamplesAction = createAction(GET_SAMPLES);

const getSamplesSuccess = createAction(GET_SAMPLES_SUCCESS);

export const getMoreSamplesAction = createAction(GET_MORE_SAMPLES);

const getMoreSamplesSuccess = createAction(GET_MORE_SAMPLES_SUCCESS);

const getSamplesFailure = createAction(GET_SAMPLES_FAILURE);
export const getSamplesEpic = (actions$) => actions$.pipe(
  ofType(GET_SAMPLES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/samples?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getSamplesSuccess(res)),
    catchError((err) => of(getSamplesFailure(err))),
  )),
);
export const getMoreSamplesEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_SAMPLES),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/samples?${action.payload.params}`, 'POST', action.payload.body)).pipe(
    map((res) => getMoreSamplesSuccess(res)),
    catchError((err) => of(getSamplesFailure(err))),
  )),
);

export const getSamplesReducer = createReducer(
  {
    data: [],
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getSamplesAction, (state) => {
        state.loading = true;
      })
      .addCase(getSamplesSuccess, (state, action) => {
        state.data = action.payload.response.data;
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreSamplesAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreSamplesSuccess, (state, action) => {
        state.data = {
          data: {
            total: action.payload.response.data.data.total,
            documents: [...state.data.data.documents, ...action.payload.response.data.data.documents],
          },
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getSamplesFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
