import { Tooltip } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageWithLoader from '../../ImageWithLoader';

function MolecularStructure({ admetData }) {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = (text) => {
    setIsCopied(true);
    navigator.clipboard.writeText(text);
  };
  return (
    <div className="molecular-structure-container">
      <div className="molecular-structure-header">Molecular Structure</div>
      <div className="smile-component">
        <div className="info-component-value">
          <Tooltip placement="topLeft" title={admetData?.smiles}>
            {admetData?.smiles}
          </Tooltip>
        </div>
        <Tooltip placement="topLeft" title={isCopied ? 'copied' : 'copy'}>
          <div className="smile-copy-sec">
            <div className="info-copy-btn" aria-hidden style={{ cursor: 'pointer' }} onClick={() => copyToClipboard(admetData?.smiles)} />
          </div>
        </Tooltip>
      </div>
      {admetData?.img_url === '' ? 'No Image Available' : <ImageWithLoader src={admetData?.img_url} desc="Molecular Structure" />}
    </div>
  );
}

MolecularStructure.propTypes = {
  admetData: PropTypes.string.isRequired,
};

export default MolecularStructure;
