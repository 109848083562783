import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ErrorBoundary from '../ErrorBoundary';
import NoDataFound from '../NoDataFound';

function Loader(props) {
  const dispatch = useDispatch();
  const {
    loading, width, height, style, error, errorMessage, retryAction,
  } = props;
  if (loading) {
    return (
      <div className="loader black-loader" style={{ height, width, ...style }}>
        <div className="spinner">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      </div>
    );
  }
  if (error) {
    const styleLoader = {
      height,
      width,
      ...style,
      textAlign: 'center',
    };
    return (
      <div style={styleLoader} className="loader">
        {errorMessage || 'Oops! There has been an issue. Re-try in some time.'}
        {
          retryAction ? <button type="button" onClick={() => dispatch(retryAction())}>Retry</button> : null
        }
      </div>
    );
  }

  const {
    message, noData, className, children,
  } = props;
  if (noData) {
    return <NoDataFound message={message} className={className ? `loader ${className}` : 'loader'} style={{ height, width, ...style }} />;
  }
  return (
    <ErrorBoundary height={height} width={width}>
      {children}
    </ErrorBoundary>
  );
}

Loader.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  noData: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  message: PropTypes.string,
  errorMessage: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  retryAction: PropTypes.func,
};
Loader.defaultProps = {
  height: 'calc(100vh - 110px)',
  width: '100%',
  noData: false,
  children: null,
  message: '',
  errorMessage: '',
  style: {},
  className: '',
  retryAction: undefined,
};

export default Loader;
