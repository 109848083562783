// RadarChart.js
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend,
} from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

function RadarChartSAR({
  labelsList, valuesList, parametersList, comparatorValuesList,
}) {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    setChartData([
      {
        label: 'Molecule',
        data: valuesList,
        fill: false,
        backgroundColor: 'rgba(59, 118, 239, 0.2)',
        borderColor: 'rgba(59, 118, 239, 1)',
        pointBackgroundColor: 'rgba(59, 118, 239, 1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(59, 118, 239, 1)',
      },
    ]);
    if (comparatorValuesList !== undefined && comparatorValuesList?.length > 0) {
      setChartData([
        {
          label: 'Molecule',
          data: valuesList,
          fill: false,
          backgroundColor: 'rgba(59, 118, 239, 0.2)',
          borderColor: 'rgba(59, 118, 239, 1)',
          pointBackgroundColor: 'rgba(59, 118, 239, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(59, 118, 239, 1)',
        },
        {
          label: 'Comparator',
          data: comparatorValuesList,
          fill: true,
          backgroundColor: 'rgba(166, 109, 212, 0.5)',
          borderColor: 'rgba(166, 109, 212, 1)',
          pointBackgroundColor: 'rgba(166, 109, 212, 1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(166, 109, 212, 1)',
        },
      ]);
    }
  }, [labelsList, valuesList, parametersList, comparatorValuesList]);
  const data = {
    labels: labelsList,
    datasets: chartData,
  };

  const options = {
    responsive: true,
    scales: {
      r: {
        ticks: {
          count: 3,
          display: false,
          beginAtZero: true,
          max: 100,
        },
        grid: {
          color: 'rgba(200, 200, 200, 0.5)', // Change grid color if needed
          lineWidth: 2, // Increase grid line weight
        },
        angleLines: {
          color: 'rgba(200, 200, 200, 0.5)', // Change angle line color
          lineWidth: 2, // Increase angle line weight
        },
        pointLabels: {
          color: 'rgba(18, 20, 23, 1)', // Adjust the color as needed
          font: {
            weight: '400', // Make the labels bold
            size: 18, // Adjust the size as needed
          },
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Radar Chart Example',
      },
    },
  };

  return <Radar data={data} options={options} />;
}

RadarChartSAR.propTypes = {
  labelsList: PropTypes.arrayOf.isRequired,
  valuesList: PropTypes.arrayOf.isRequired,
  parametersList: PropTypes.arrayOf.isRequired,
  comparatorValuesList: PropTypes.arrayOf.isRequired,
};

export default RadarChartSAR;
