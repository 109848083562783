import React, { useEffect, useState } from 'react';
import ModalRequestDemo from '../../container/ModalRequestDemo';
import Selection from '../../container/Selection';
import Header from '../../../common/components/Header';
import { setCookie } from '../../../utils';

function HomePage() {
  const [isOpenRequestDemo, setIsOpenRequestDemo] = useState(false);
  const [module, setModule] = useState(null);
  useEffect(() => {
    setCookie(false, 'pageLoading');
  }, []);

  return (
    <div className="main-page">
      <Header className="header-home" logo="metad3logo-white-new" />
      <Selection setIsOpenRequestDemo={(flag) => setIsOpenRequestDemo(flag)} getModule={setModule} />
      <ModalRequestDemo setOpen={(flag) => setIsOpenRequestDemo(flag)} open={isOpenRequestDemo} isAccess module={module} />
    </div>
  );
}

export default HomePage;
