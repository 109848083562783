import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const GET_ASSOCIATED_PROTEINS_DISTRIBUTION = 'GET_ASSOCIATED_PROTEINS_DISTRIBUTION';
const GET_ASSOCIATED_PROTEINS_DISTRIBUTION_SUCCESS = 'GET_ASSOCIATED_PROTEINS_DISTRIBUTION_SUCCESS';
const GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION = 'GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION';
const GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_SUCCESS = 'GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_SUCCESS';
const GET_ASSOCIATED_PROTEINS_DISTRIBUTION_FAILURE = 'GET_ASSOCIATED_PROTEINS_DISTRIBUTION_FAILURE';

export const getAssociatedProteinsDistributionAction = createAction(GET_ASSOCIATED_PROTEINS_DISTRIBUTION);
const getAssociatedProteinsDistributionSuccess = createAction(GET_ASSOCIATED_PROTEINS_DISTRIBUTION_SUCCESS);
export const getMoreAssociatedProteinsDistributionAction = createAction(GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION);
const getMoreAssociatedProteinsDistributionSuccess = createAction(GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION_SUCCESS);
const getAssociatedProteinsDistributionFailure = createAction(GET_ASSOCIATED_PROTEINS_DISTRIBUTION_FAILURE);

export const getAssociatedProteinsDistributionEpic = (actions$) => actions$.pipe(
  ofType(GET_ASSOCIATED_PROTEINS_DISTRIBUTION),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/associatedProteinData?${action.payload}`, 'get')).pipe(
    map((res) => getAssociatedProteinsDistributionSuccess(res)),
    catchError((err) => of(getAssociatedProteinsDistributionFailure(err))),
  )),
);

export const getMoreAssociatedProteinsDistributionEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_ASSOCIATED_PROTEINS_DISTRIBUTION),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_iep}/associatedProteinData?${action.payload}`, 'get')).pipe(
    map((res) => getMoreAssociatedProteinsDistributionSuccess(res)),
    catchError((err) => of(getAssociatedProteinsDistributionFailure(err))),
  )),
);

export const getAssociatedProteinsDistributionReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getAssociatedProteinsDistributionAction, (state) => {
        state.loading = true;
      })
      .addCase(getAssociatedProteinsDistributionSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreAssociatedProteinsDistributionAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreAssociatedProteinsDistributionSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.table_data.total_count,
          table_data: {
            ...state.data.extracted_data,
            extracted_data: [...state.data.table_data.extracted_data, ...action.payload.response.table_data.extracted_data],
          },
        };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getAssociatedProteinsDistributionFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
