import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactECharts from 'echarts-for-react';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import useAppSelector from '../../../../utils/redux-hook';
import data from './data';
import { getDrugDevelopmentOutputAction } from './logic';

function DrugDevelopmentFeasilibilityView({ jobId }) {
  const getDrugDevelopmentResponse = useAppSelector((state) => state.getDrugDevelopmentResponse);
  const [graphData, setGraphData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const request = {
      job_id: jobId,
    };
    dispatch(getDrugDevelopmentOutputAction(queryString.stringify(request)));
  }, []);
  useEffect(() => {
    if (getDrugDevelopmentResponse.flag) {
      setGraphData(getDrugDevelopmentResponse.data.data);
    }
  }, [JSON.stringify(getDrugDevelopmentResponse)]);
  const parsingData = (dData) => {
    const tempData = _.cloneDeep(dData);
    const colors = ['#35D8EE', '#52B956', '#B787DD', '#F28B5B', '#1C8F7A', '#4E96EB', '#EA78D8', '#E4908A', '#FBBC08'];
    for (let x = 0; x < tempData?.length; x += 1) {
      const clr = colors[x % colors.length];
      const arr = tempData[x].children;
      for (let y = 0; y < arr?.length; y += 1) {
        const arr2 = arr[y].children;
        for (let z = 0; z < arr2.length; z += 1) {
          arr2[z].itemStyle = {
            color: `${clr}60`,
          };
          arr2[z].type = 'Indication name';
        }
        arr[y].itemStyle = {
          color: `${clr}95}`,
        };
        arr[y].type = 'Clinical Trials';
      }
      tempData[x].itemStyle = {
        color: clr,
      };
      tempData[x].type = 'Drug name';
    }
  };

  useEffect(() => {
    parsingData(graphData);
  }, [graphData]);

  const option = {
    tooltip: {
      trigger: 'item',
      formatter(param) {
        const { name, value } = param.data;
        if (name === undefined || value === undefined) return null;
        return `<div style="max-height: 40px; border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;margin-bottom: 7px; display: flex;flex-direction: column">
             <div style="display: flex;flex-direction: column;justify-content: space-between">
             <p style="font-family: Inter;font-size: 14px;color: rgba(18, 20, 23, 0.6)">${name}</p>
               <p style="font-family: Inter;font-size: 16px;color: rgba(18, 20, 23, 1)">${value}</p>
               <p style="font-family: Inter;font-size: 11px;color: rgba(18, 20, 23, 0.6)">Target Count</p>
             </div>
           </div>`;
      },
    },
    emphasis: {
      focus: 'none',
    },
    label: {
      color: '#1F1F1F',
      overflow: 'truncate',
    },
    series: {
      type: 'sunburst',
      data,
      radius: [40, '95%'],
      emphasis: {
        focus: 'none',
      },
      label: {
        width: 30,
        overflow: 'truncate',
      },
    },
  };

  return (
    <div className="ct-enrichment-graph-container" width="100%">
      <div className="card-content-ctp-title graph-card-title">Drug Development Feasibility of Targets</div>
      <div style={{ height: '300px', width: '100%', position: 'relative' }}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontSize: '11px',
            width: '70px',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          Colorectal cancer
        </div>
        <ReactECharts option={option} style={{ height: '500px', width: '100%' }} />
      </div>
    </div>
  );
}

DrugDevelopmentFeasilibilityView.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export default DrugDevelopmentFeasilibilityView;
