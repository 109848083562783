import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get study group details
const GET_STUDY_GROUPS = 'GET_STUDY_GROUPS';
const GET_STUDY_GROUPS_SUCCESS = 'GET_STUDY_GROUPS_SUCCESS';
const GET_STUDY_GROUPS_FAILURE = 'GET_STUDY_GROUPS_FAILURE';

export const getStudyGroupsAction = createAction(GET_STUDY_GROUPS);

const getStudyGroupsSuccess = createAction(GET_STUDY_GROUPS_SUCCESS);

const getStudyGroupsFailure = createAction(GET_STUDY_GROUPS_FAILURE);
export const getStudyGroupsEpic = (actions$) => actions$.pipe(
  ofType(GET_STUDY_GROUPS),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_biomarker}/study_groups?${action.payload}`, 'get')).pipe(
    map((res) => getStudyGroupsSuccess(res)),
    catchError((err) => of(getStudyGroupsFailure(err))),
  )),
);

export const getStudyGroupsReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getStudyGroupsAction, (state) => {
        state.loading = true;
      })
      .addCase(getStudyGroupsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(getStudyGroupsFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
