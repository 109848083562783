import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// get Output details
const GET_TI_OUTPUT = 'GET_TI_OUTPUT';
const GET_TI_OUTPUT_SUCCESS = 'GET_TI_OUTPUT_SUCCESS';
const GET_TI_OUTPUT_FAILURE = 'GET_TI_OUTPUT_FAILURE';
const GET_MORE_TI_OUTPUT = 'GET_MORE_TI_OUTPUT';
const GET_MORE_TI_OUTPUT_SUCCESS = 'GET_MORE_TI_OUTPUT_SUCCESS';

export const getTIOutputAction = createAction(GET_TI_OUTPUT);

const getTIOutputSuccess = createAction(GET_TI_OUTPUT_SUCCESS);

export const getMoreTIOutputAction = createAction(GET_MORE_TI_OUTPUT);

const getMoreTIOutputSuccess = createAction(GET_MORE_TI_OUTPUT_SUCCESS);

const getTIOutputFaliure = createAction(GET_TI_OUTPUT_FAILURE);

export const getTIOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_TI_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ti}/target?${action.payload}`, 'get')).pipe(
    map((res) => getTIOutputSuccess(res)),
    catchError((err) => of(getTIOutputFaliure(err))),
  )),
);

export const getMoreTIOutputEpic = (actions$) => actions$.pipe(
  ofType(GET_MORE_TI_OUTPUT),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ti}/target?${action.payload}`, 'get')).pipe(
    map((res) => getMoreTIOutputSuccess(res)),
    catchError((err) => of(getTIOutputFaliure(err))),
  )),
);
export const getTIOutputReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(getTIOutputAction, (state) => {
        state.loading = true;
      })
      .addCase(getTIOutputSuccess, (state, action) => {
        state.data = { ...action.payload.response };
        state.loading = false;
        state.flag = true;
      })
      .addCase(getMoreTIOutputAction, (state) => {
        state.loading = false;
      })
      .addCase(getMoreTIOutputSuccess, (state, action) => {
        state.data = {
          total_count: action.payload.response.data.totalCount,
          data: {
            ...state.data.data,
            model_results: [...state.data.data.model_results, ...action.payload.response.data.model_results],
          },
        };
      })
      .addCase(getTIOutputFaliure, (state) => {
        state.error = true;
        state.flag = false;
        state.loading = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
