import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import {
  BarChart, Bar, XAxis, YAxis, ReferenceLine, Tooltip as RechartTooltip, ResponsiveContainer, LabelList, CartesianGrid, Legend,
} from 'recharts';
import CustomPieChart from '../../container/Graph/PieChart/PieChart';
import useAppSelector from '../../../../utils/redux-hook';

function ClinicalSuccessOutputPage() {
  const ctpOutput = useAppSelector((state) => state.ctpOutput);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [posData2, setPosData2] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    setData1(ctpOutput?.data?.data?.endpoint_data);
    setData2(ctpOutput?.data?.data?.phase_data);
    setData3(ctpOutput?.data?.data?.shap_analysis);
    setPosData2(ctpOutput?.data?.data?.pos);
    if (ctpOutput?.data?.data?.endpoint_data?.length === 0 || ctpOutput?.data?.data?.phase_data?.length === 0) {
      setIsEmpty(true);
    }
  });

  const titleTooltip = () => (
    <div className="custom-tooltip" style={{ backgroundColor: '#fff', color: '#000' }}>
      CTP Output
    </div>
  );

  const shapCustomToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', color: '#000' }}>
          <div className="custom-tooltip-content" style={{ display: 'flex', flexDirection: 'column' }}>
            <p className="label">{`${label}`}</p>
            <p className="pass" style={{ color: payload[0].value < 0 ? '#F56F6F' : '#42CD9B' }}>
              {`${payload[0].value}`}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const customToolTip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', color: '#000' }}>
          <div className="custom-tooltip-content" style={{ display: 'flex', flexDirection: 'column' }}>
            <p className="label">{`${label}`}</p>
            <p className="pass" style={{ color: '#42CD9B' }}>
              {`Pass: ${payload[0].value}`}
            </p>
            <p className="fail" style={{ color: '#F56F6F' }}>
              {`Fail: ${payload[1].value}`}
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="ctp-content">
      <div className="ctp-content-upper" style={{ display: 'flex' }}>
        <div className="card-content-left-part">
          <div className="ctp-content-upper-heading">Probability of Success</div>

          <div className="ctp-content-upper-card-content-ctp-value">
            <div className="ctp-content-upper-card-content-ctp-value-percentage">
              <CustomPieChart score={parseFloat(posData2?.probability).toFixed(2)} status={posData2?.prediction} />
              <div className="gauge-content">{posData2?.prediction}</div>
            </div>
          </div>
        </div>
        <div className="card-content-ctp-main">
          <div className="ctp-content-upper-card-content-ctp-content">
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-values-id">{posData2?.trial}</div>
              <div className="ctp-content-upper-blocks-values-phase">{`${posData2?.phase} - ${posData2?.trial_status}`}</div>
            </div>

            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-values-title">{posData2?.title}</div>
            </div>
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-keys">Condition:</div>
              <div className="ctp-content-upper-blocks-values">{posData2?.condition}</div>
            </div>
            <div className="ctp-content-upper-blocks">
              <div className="ctp-content-upper-blocks-keys">Start Date:</div>
              <div className="ctp-content-upper-blocks-values">{`${posData2?.start_date} | ${posData2?.anticipated_enrollment} Anticipated Enrollment`}</div>
            </div>
            <div className="ctp-content-upper-blocks ctp-content-upper-blocks-dual">
              <div className="ctp-content-upper-blocks-keys">(Category) Primary Outcome:</div>
              <div className="ctp-content-upper-blocks-values">
                {posData2?.primary_outcome?.map((item) => (
                  <div className="ctp-content-upper-blocks-values-box">
                    <div className="ctp-content-upper-blocks-values-right">
                      <Tooltip placement="topLeft" title={`(${item.predicted_labels})`}>{`(${item.predicted_labels})`}</Tooltip>
                    </div>
                    <div className="ctp-content-upper-blocks-values-left">
                      <div className="ctp-content-upper-blocks-values-left-text">
                        <Tooltip placement="topLeft" title={item.measure}>
                          {item.measure}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty && (
        <div className="ctp-content-middle">
          <div className="ctp-content-data-card">
            <div className="ctp-content-middle-first card-content-ctp">
              <div className="card-content-ctp-title">{`Primary Outcome Category Distribution for ${posData2?.condition}`}</div>
              <div className="ctp-output-legends">
                <div className="ctp-output-each-legend">
                  <div className="legend-pass">green</div>
                  <div className="legend-pass-value">Pass</div>
                </div>
                <div>
                  <div className="legend-fail">red</div>
                  <div className="legend-pass-value">Fail</div>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  barCategoryGap={20}
                  width={350}
                  height={500}
                  data={data1}
                  layout="vertical"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 45,
                    bottom: 30,
                  }}
                >
                  <XAxis
                    type="number"
                    label={{
                      value: 'Number of Trials  --->',
                      position: 'insideBottom',
                      offset: -20,
                      fill: '#121417',
                      fontSize: '18',
                      fontWeight: '600',
                    }}
                    style={{ margin: { top: 10 } }}
                    tick={{
                      fill: '#121417',
                      fontSize: '16',
                      fontWeight: '400',
                    }}
                  />
                  <YAxis
                    dataKey="name"
                    type="category"
                    width={100}
                    tick={{
                      fill: '#121417',
                      fontSize: '16',
                      fontWeight: '400',
                    }}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <RechartTooltip content={customToolTip} cursor={false} />
                  <Bar dataKey="Pass" stackId="stack" fill="#42CD9B">
                    <LabelList dataKey="x" position="insideTop" />
                  </Bar>
                  <Bar dataKey="Fail" stackId="stack" fill="#F56F6F">
                    <LabelList dataKey="y" position="insideTop" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="ctp-content-middle-second card-content-ctp">
              <div className="card-content-ctp-title">{`Phase Distribution for ${posData2?.condition}`}</div>
              <div className="ctp-output-legends">
                <div className="ctp-output-each-legend">
                  <div className="legend-pass">green</div>
                  <div className="legend-pass-value">Pass</div>
                </div>
                <div>
                  <div className="legend-fail">red</div>
                  <div className="legend-pass-value">Fail</div>
                </div>
              </div>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  barCategoryGap={45}
                  width={350}
                  height={500}
                  data={data2}
                  layout="vertical"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 10,
                    bottom: 30,
                  }}
                >
                  <XAxis
                    type="number"
                    label={{
                      value: 'Number of Trials  --->',
                      position: 'insideBottom',
                      offset: -20,
                      fill: '#121417',
                      fontSize: '18',
                      fontWeight: '600',
                    }}
                    style={{ margin: { top: 10 } }}
                    tick={{
                      fill: '#121417',
                      fontSize: '16',
                      fontWeight: '400',
                    }}
                  />
                  <YAxis
                    dataKey="name"
                    type="category"
                    width={100}
                    tick={{
                      fill: '#121417',
                      fontSize: '16',
                      fontWeight: '400',
                    }}
                  />
                  <CartesianGrid strokeDasharray="3 3" />
                  <RechartTooltip content={customToolTip} cursor={false} />
                  <Bar dataKey="Pass" stackId="stack" fill="#42CD9B">
                    <LabelList dataKey="x" position="insideTop" />
                  </Bar>
                  <Bar dataKey="Fail" stackId="stack" fill="#F56F6F">
                    <LabelList dataKey="y" position="insideTop" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
      <div className="ctp-content-lower card-content-ctp">
        <div className="card-content-ctp-title info-add">
          SHAP Analysis
          <span className="info-add-icon">
            <Tooltip content={titleTooltip} />
          </span>
        </div>
        <div className="card-content-ctp-sub-title">SHAP value analysis is a promising method for incorporating explainability in CTP model development and usage. It calculates the contribution of each feature class (drug, trial and disease) towards a prediction, providing insights and their impact on the clinical trial success. By understanding these contributions, we can gain a better understanding of how the model works and make informed decisions based on its outputs.</div>
        <ResponsiveContainer width="100%" height={1200}>
          <BarChart
            layout="vertical"
            width={450}
            height={600}
            data={data3}
            barGap={10}
            margin={{
              top: 5,
              right: 30,
              left: 50,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              type="number"
              label={{
                value: 'SHAP values  ---> ',
                position: 'insideBottom',
                offset: -5,
                fill: '#121417',
                fontSize: '18',
                fontWeight: '600',
              }}
              tick={{
                fill: '#121417',
                fontSize: '16',
                fontWeight: '400',
              }}
            />
            <YAxis
              width={300}
              dataKey="name"
              type="category"
              tick={{
                fill: '#121417',
                fontSize: '16',
                fontWeight: '400',
              }}
            />
            <RechartTooltip
              content={shapCustomToolTip} // Display only the value in the tooltip
              cursor={false}
            />
            <ReferenceLine x={0} stroke="transparent" />
            {' '}
            <Bar dataKey="pv" fill="#42CD9B" barSize={1000} />
            <Bar dataKey="uv" fill="#F56F6F" barSize={1000} />
            <Legend
              verticalAlign="top"
              align="center"
              wrapperStyle={{ margin: '-10px' }}
              formatter={(value, entry) => {
                if (entry.dataKey === 'pv') {
                  return 'Features with +ve contribution to POS'; // Customize this sentence for 'pv'
                }
                if (entry.dataKey === 'uv') {
                  return 'Features with -ve contribution to POS'; // Customize this sentence for 'uv'
                }
                return null;
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ClinicalSuccessOutputPage;
