import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../../utils';

const CTP_KOL = 'CTP_KOL';
const CTP_KOL_SUCCESS = 'CTP_KOL_SUCCESS';
const CTP_KOL_FAILURE = 'CTP_KOL_FAILURE';
const CTP_KOL_RESET = 'CTP_KOL_RESET';

export const ctpKolAction = createAction(CTP_KOL);
export const ctpKolResetAction = createAction(CTP_KOL_RESET);

const ctpKolsSuccess = createAction(CTP_KOL_SUCCESS);
const ctpKolFailure = createAction(CTP_KOL_FAILURE);

export const ctpKolEpic = (actions$) => actions$.pipe(
  ofType(CTP_KOL),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_ctp}/kolContributions?${action.payload}`, 'get')).pipe(
    map((res) => ctpKolsSuccess(res)),
    catchError((err) => of(ctpKolFailure(err))),
  )),
);

export const ctpKolReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(ctpKolAction, (state) => {
        state.loading = true;
      })
      .addCase(ctpKolsSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(ctpKolFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(ctpKolResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);
