/* eslint-disable no-undef */
// src/components/MoleculeViewer.js
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

const pdbData = `
HETATM    1  C   UNL X   1     -36.691  11.776 -19.605  1.00  0.00           C
HETATM    2  C   UNL X   1     -35.328  12.255 -19.890  1.00  0.00           C
HETATM    3  C   UNL X   1     -34.843  12.683 -21.098  1.00  0.00           C
HETATM    4  C   UNL X   1     -35.495  12.765 -22.419  1.00  0.00           C
HETATM    5  C   UNL X   1     -34.939  12.125 -23.520  1.00  0.00           C
HETATM    6  C   UNL X   1     -35.582  12.146 -24.745  1.00  0.00           C
HETATM    7  C   UNL X   1     -36.781  12.831 -24.915  1.00  0.00           C
HETATM    8  C   UNL X   1     -37.499  12.857 -26.238  1.00  0.00           C
HETATM    9  N   UNL X   1     -36.584  12.645 -27.343  1.00  0.00           N
HETATM   10  C   UNL X   1     -36.023  13.669 -27.997  1.00  0.00           C
HETATM   11  O   UNL X   1     -36.256  14.795 -27.847  1.00  0.00           O
HETATM   12  C   UNL X   1     -35.041  12.982 -29.149  1.00  0.00           C
HETATM   13  C   UNL X   1     -33.633  13.317 -28.353  1.00  0.00           C
HETATM   14  C   UNL X   1     -32.594  13.798 -29.531  1.00  0.00           C
HETATM   15  O   UNL X   1     -32.513  12.405 -30.337  1.00  0.00           O
HETATM   16  C   UNL X   1     -33.685  14.535 -30.477  1.00  0.00           C
HETATM   17  N   UNL X   1     -34.925  14.248 -30.119  1.00  0.00           N
HETATM   18  C   UNL X   1     -36.057  14.339 -30.871  1.00  0.00           C
HETATM   19  O   UNL X   1     -37.046  13.800 -30.695  1.00  0.00           O
HETATM   20  C   UNL X   1     -35.713  15.280 -32.200  1.00  0.00           C
HETATM   21  N   UNL X   1     -36.679  14.544 -33.218  1.00  0.00           N
HETATM   22  C   UNL X   1     -36.154  14.090 -34.373  1.00  0.00           C
HETATM   23  O   UNL X   1     -34.983  14.127 -34.723  1.00  0.00           O
HETATM   24  C   UNL X   1     -37.193  13.448 -35.317  1.00  0.00           C
HETATM   25  F   UNL X   1     -38.456  13.237 -34.759  1.00  0.00           F
HETATM   26  C   UNL X   1     -37.295  13.779 -36.757  1.00  0.00           C
HETATM   27  C   UNL X   1     -36.897  12.401 -36.318  1.00  0.00           C
HETATM   28  C   UNL X   1     -36.625  16.674 -31.883  1.00  0.00           C
HETATM   29  C   UNL X   1     -36.329  17.496 -33.156  1.00  0.00           C
HETATM   30  C   UNL X   1     -38.021  16.522 -31.663  1.00  0.00           C
HETATM   31  C   UNL X   1     -35.832  17.381 -30.766  1.00  0.00           C
HETATM   32  C   UNL X   1     -37.318  13.503 -23.810  1.00  0.00           C
HETATM   33  O   UNL X   1     -38.575  14.019 -24.042  1.00  0.00           O
HETATM   34  C   UNL X   1     -39.616  13.314 -23.371  1.00  0.00           C
HETATM   35  C   UNL X   1     -40.719  12.871 -24.250  1.00  0.00           C
HETATM   36  O   UNL X   1     -40.604  11.577 -24.768  1.00  0.00           O
HETATM   37  C   UNL X   1     -41.142  11.274 -26.008  1.00  0.00           C
HETATM   38  C   UNL X   1     -41.499  12.327 -26.837  1.00  0.00           C
HETATM   39  C   UNL X   1     -41.982  12.075 -28.086  1.00  0.00           C
HETATM   40  C   UNL X   1     -41.828  10.850 -28.683  1.00  0.00           C
HETATM   41  C   UNL X   1     -41.909  10.787 -30.148  1.00  0.00           C
HETATM   42  N   UNL X   1     -42.717  11.720 -30.782  1.00  0.00           N
HETATM   43  C   UNL X   1     -44.161  11.434 -30.758  1.00  0.00           C
HETATM   44  C   UNL X   1     -44.892  12.333 -31.766  1.00  0.00           C
HETATM   45  N   UNL X   1     -44.567  13.746 -31.567  1.00  0.00           N
HETATM   46  C   UNL X   1     -45.415  14.668 -32.222  1.00  0.00           C
HETATM   47  C   UNL X   1     -44.953  15.536 -33.239  1.00  0.00           C
HETATM   48  C   UNL X   1     -45.845  16.475 -33.819  1.00  0.00           C
HETATM   49  C   UNL X   1     -45.446  17.451 -34.889  1.00  0.00           C
HETATM   50  C   UNL X   1     -44.082  17.548 -35.272  1.00  0.00           C
HETATM   51  C   UNL X   1     -43.640  18.447 -36.258  1.00  0.00           C
HETATM   52  C   UNL X   1     -44.541  19.338 -36.853  1.00  0.00           C
HETATM   53  C   UNL X   1     -45.888  19.307 -36.472  1.00  0.00           C
HETATM   54  C   UNL X   1     -46.347  18.358 -35.534  1.00  0.00           C
HETATM   55  O   UNL X   1     -47.682  18.406 -35.254  1.00  0.00           O
HETATM   56  N   UNL X   1     -47.140  16.495 -33.420  1.00  0.00           N
HETATM   57  N   UNL X   1     -47.599  15.688 -32.466  1.00  0.00           N
HETATM   58  C   UNL X   1     -46.775  14.814 -31.854  1.00  0.00           C
HETATM   59  N   UNL X   1     -47.355  14.073 -30.826  1.00  0.00           N
HETATM   60  C   UNL X   1     -43.133  14.025 -31.559  1.00  0.00           C
HETATM   61  C   UNL X   1     -42.396  13.129 -30.545  1.00  0.00           C
HETATM   62  C   UNL X   1     -41.392   9.790 -27.912  1.00  0.00           C
HETATM   63  C   UNL X   1     -41.143  10.021 -26.564  1.00  0.00           C
HETATM   64  C   UNL X   1     -36.692  13.460 -22.572  1.00  0.00           C
HETATM   65  S   UNL X   1     -33.166  13.127 -20.935  1.00  0.00           S
HETATM   66  C   UNL X   1     -33.273  12.753 -19.265  1.00  0.00           C
HETATM   67  N   UNL X   1     -34.424  12.334 -18.849  1.00  0.00           N
`;
export default function FullScreenPDBViewer({ id }) {
  useEffect(() => {
    const viewer = $3Dmol.createViewer(`viewer-container-${id}`);
    viewer.addModel(pdbData, 'pdb');
    viewer.setBackgroundColor(0x00000000);
    viewer.rotate(90, 'y', 3000);
    viewer.setStyle({ sphere: { colorscheme: 'Jmol', scale: 0.25 }, stick: {} });
    viewer.zoomTo();
    viewer.render();
  }, [pdbData]);

  return <div id={`viewer-container-${id}`} style={{ position: 'absolute', width: '100vw', height: '100vh' }} />;
}
