const sampleData = [
  {
    sample_id: 'GSM8263741',
    sample_tags: ['Homosapiens'],
    sample_title: 'AT2 cells, CD44hi_1',
    sample_published_date: 'May 14, 2024',
    sample_type: 'SRA',
    sample_strain: 'SpC-creER/Rosa-mTmG',
    sample_cell_type: 'Alveolar type 2 cells',
    sample_source_name: 'Alveolar type 2 cells',
    sample_tissue: 'Lung',
    sample_cell_sub_type: 'CD44hi',
    sample_library_strategy: 'RNA-Seq',
    sample_library_source: 'Transcriptomic',
    sample_library_selection: 'cDNA',
    sample_instrument_model: 'Illumina HiSeq 4000',
    sample_extracted_module: 'Total RNA',
    sample_extraction_protocol: 'AT2 cells isolated from SpC-creER/Rosa-mTmG lineage tracing mice were FACS sorted into GFP+CD44hi and GFP+CD44lo populations. Total RNA was extracted using the RNAqueous-Micro Total RNA isolation kit (ThermoFisher). TruSeq mRNA-Seq libraries was prepared and sequenced on the Illumina Hiseq 4000 platform with production of single-end, 50 bp reads. The Illiumina Stranded mRNA-Seq library Preparation Kit was used to prepare sequencing libraries.',
    sample_summary: 'Small-cell lung cancer (SCLC) is the most fatal form of lung cancer. Intra-tumoral heterogeneity, marked by neuroendocrine (NE) and non-neuroendocrine (non-NE) cell states, defines SCLC, but the drivers of SCLC plasticity are poorly understood. To map the landscape of SCLC tumor microenvironment (TME), we apply spatially resolved transcriptomics and quantitative mass spectrometry-based proteomics to metastatic SCLC tumors obtained via rapid autopsy. The phenotype and overall composition of non-malignant cells in the tumor microenvironment (TME) exhibits substantial variability, closely mirroring the tumor phenotype, suggesting TME-driven reprogramming of NE cell states. We identify cancer-associated fibroblasts (CAF) as a crucial element of SCLC TME heterogeneity, contributing to immune exclusion, and predicting exceptionally poor prognosis. Together, our work provides a comprehensive map of SCLC tumor and TME ecosystems, emphasizing their pivotal role in SCLCs adaptable nature, opening possibilities for re-programming the intercellular communications that shape SCLC tumor states.',
  },
  {
    sample_id: 'GSM8263742',
    sample_tags: ['Homosapiens'],
    sample_title: 'AT2 cells, CD44hi_1',
    sample_published_date: 'May 14, 2024',
    sample_type: 'SRA',
    sample_strain: 'SpC-creER/Rosa-mTmG',
    sample_cell_type: 'Alveolar type 2 cells',
    sample_source_name: 'Alveolar type 2 cells',
    sample_tissue: 'Lung',
    sample_cell_sub_type: 'CD44hi',
    sample_library_strategy: 'RNA-Seq',
    sample_library_source: 'Transcriptomic',
    sample_library_selection: 'cDNA',
    sample_instrument_model: 'Illumina HiSeq 4000',
    sample_extracted_module: 'Total RNA',
    sample_extraction_protocol: 'AT2 cells isolated from SpC-creER/Rosa-mTmG lineage tracing mice were FACS sorted into GFP+CD44hi and GFP+CD44lo populations. Total RNA was extracted using the RNAqueous-Micro Total RNA isolation kit (ThermoFisher). TruSeq mRNA-Seq libraries was prepared and sequenced on the Illumina Hiseq 4000 platform with production of single-end, 50 bp reads. The Illiumina Stranded mRNA-Seq library Preparation Kit was used to prepare sequencing libraries.',
    sample_summary: 'Small-cell lung cancer (SCLC) is the most fatal form of lung cancer. Intra-tumoral heterogeneity, marked by neuroendocrine (NE) and non-neuroendocrine (non-NE) cell states, defines SCLC, but the drivers of SCLC plasticity are poorly understood. To map the landscape of SCLC tumor microenvironment (TME), we apply spatially resolved transcriptomics and quantitative mass spectrometry-based proteomics to metastatic SCLC tumors obtained via rapid autopsy. The phenotype and overall composition of non-malignant cells in the tumor microenvironment (TME) exhibits substantial variability, closely mirroring the tumor phenotype, suggesting TME-driven reprogramming of NE cell states. We identify cancer-associated fibroblasts (CAF) as a crucial element of SCLC TME heterogeneity, contributing to immune exclusion, and predicting exceptionally poor prognosis. Together, our work provides a comprehensive map of SCLC tumor and TME ecosystems, emphasizing their pivotal role in SCLCs adaptable nature, opening possibilities for re-programming the intercellular communications that shape SCLC tumor states.',
  },
  {
    sample_id: 'GSM8263743',
    sample_tags: ['Homosapiens'],
    sample_title: 'AT2 cells, CD44hi_1',
    sample_published_date: 'May 14, 2024',
    sample_type: 'SRA',
    sample_strain: 'SpC-creER/Rosa-mTmG',
    sample_cell_type: 'Alveolar type 2 cells',
    sample_source_name: 'Alveolar type 2 cells',
    sample_tissue: 'Lung',
    sample_cell_sub_type: 'CD44hi',
    sample_library_strategy: 'RNA-Seq',
    sample_library_source: 'Transcriptomic',
    sample_library_selection: 'cDNA',
    sample_instrument_model: 'Illumina HiSeq 4000',
    sample_extracted_module: 'Total RNA',
    sample_extraction_protocol: 'AT2 cells isolated from SpC-creER/Rosa-mTmG lineage tracing mice were FACS sorted into GFP+CD44hi and GFP+CD44lo populations. Total RNA was extracted using the RNAqueous-Micro Total RNA isolation kit (ThermoFisher). TruSeq mRNA-Seq libraries was prepared and sequenced on the Illumina Hiseq 4000 platform with production of single-end, 50 bp reads. The Illiumina Stranded mRNA-Seq library Preparation Kit was used to prepare sequencing libraries.',
    sample_summary: 'Small-cell lung cancer (SCLC) is the most fatal form of lung cancer. Intra-tumoral heterogeneity, marked by neuroendocrine (NE) and non-neuroendocrine (non-NE) cell states, defines SCLC, but the drivers of SCLC plasticity are poorly understood. To map the landscape of SCLC tumor microenvironment (TME), we apply spatially resolved transcriptomics and quantitative mass spectrometry-based proteomics to metastatic SCLC tumors obtained via rapid autopsy. The phenotype and overall composition of non-malignant cells in the tumor microenvironment (TME) exhibits substantial variability, closely mirroring the tumor phenotype, suggesting TME-driven reprogramming of NE cell states. We identify cancer-associated fibroblasts (CAF) as a crucial element of SCLC TME heterogeneity, contributing to immune exclusion, and predicting exceptionally poor prognosis. Together, our work provides a comprehensive map of SCLC tumor and TME ecosystems, emphasizing their pivotal role in SCLCs adaptable nature, opening possibilities for re-programming the intercellular communications that shape SCLC tumor states.',
  },
  {
    sample_id: 'GSM8263744',
    sample_tags: ['Homosapiens'],
    sample_title: 'AT2 cells, CD44hi_1',
    sample_published_date: 'May 14, 2024',
    sample_type: 'SRA',
    sample_strain: 'SpC-creER/Rosa-mTmG',
    sample_cell_type: 'Alveolar type 2 cells',
    sample_source_name: 'Alveolar type 2 cells',
    sample_tissue: 'Lung',
    sample_cell_sub_type: 'CD44hi',
    sample_library_strategy: 'RNA-Seq',
    sample_library_source: 'Transcriptomic',
    sample_library_selection: 'cDNA',
    sample_instrument_model: 'Illumina HiSeq 4000',
    sample_extracted_module: 'Total RNA',
    sample_extraction_protocol: 'AT2 cells isolated from SpC-creER/Rosa-mTmG lineage tracing mice were FACS sorted into GFP+CD44hi and GFP+CD44lo populations. Total RNA was extracted using the RNAqueous-Micro Total RNA isolation kit (ThermoFisher). TruSeq mRNA-Seq libraries was prepared and sequenced on the Illumina Hiseq 4000 platform with production of single-end, 50 bp reads. The Illiumina Stranded mRNA-Seq library Preparation Kit was used to prepare sequencing libraries.',
    sample_summary: 'Small-cell lung cancer (SCLC) is the most fatal form of lung cancer. Intra-tumoral heterogeneity, marked by neuroendocrine (NE) and non-neuroendocrine (non-NE) cell states, defines SCLC, but the drivers of SCLC plasticity are poorly understood. To map the landscape of SCLC tumor microenvironment (TME), we apply spatially resolved transcriptomics and quantitative mass spectrometry-based proteomics to metastatic SCLC tumors obtained via rapid autopsy. The phenotype and overall composition of non-malignant cells in the tumor microenvironment (TME) exhibits substantial variability, closely mirroring the tumor phenotype, suggesting TME-driven reprogramming of NE cell states. We identify cancer-associated fibroblasts (CAF) as a crucial element of SCLC TME heterogeneity, contributing to immune exclusion, and predicting exceptionally poor prognosis. Together, our work provides a comprehensive map of SCLC tumor and TME ecosystems, emphasizing their pivotal role in SCLCs adaptable nature, opening possibilities for re-programming the intercellular communications that shape SCLC tumor states.',
  },
  {
    sample_id: 'GSM8263745',
    sample_tags: ['Homosapiens'],
    sample_title: 'AT2 cells, CD44hi_1',
    sample_published_date: 'May 14, 2024',
    sample_type: 'SRA',
    sample_strain: 'SpC-creER/Rosa-mTmG',
    sample_cell_type: 'Alveolar type 2 cells',
    sample_source_name: 'Alveolar type 2 cells',
    sample_tissue: 'Lung',
    sample_cell_sub_type: 'CD44hi',
    sample_library_strategy: 'RNA-Seq',
    sample_library_source: 'Transcriptomic',
    sample_library_selection: 'cDNA',
    sample_instrument_model: 'Illumina HiSeq 4000',
    sample_extracted_module: 'Total RNA',
    sample_extraction_protocol: 'AT2 cells isolated from SpC-creER/Rosa-mTmG lineage tracing mice were FACS sorted into GFP+CD44hi and GFP+CD44lo populations. Total RNA was extracted using the RNAqueous-Micro Total RNA isolation kit (ThermoFisher). TruSeq mRNA-Seq libraries was prepared and sequenced on the Illumina Hiseq 4000 platform with production of single-end, 50 bp reads. The Illiumina Stranded mRNA-Seq library Preparation Kit was used to prepare sequencing libraries.',
    sample_summary: 'Small-cell lung cancer (SCLC) is the most fatal form of lung cancer. Intra-tumoral heterogeneity, marked by neuroendocrine (NE) and non-neuroendocrine (non-NE) cell states, defines SCLC, but the drivers of SCLC plasticity are poorly understood. To map the landscape of SCLC tumor microenvironment (TME), we apply spatially resolved transcriptomics and quantitative mass spectrometry-based proteomics to metastatic SCLC tumors obtained via rapid autopsy. The phenotype and overall composition of non-malignant cells in the tumor microenvironment (TME) exhibits substantial variability, closely mirroring the tumor phenotype, suggesting TME-driven reprogramming of NE cell states. We identify cancer-associated fibroblasts (CAF) as a crucial element of SCLC TME heterogeneity, contributing to immune exclusion, and predicting exceptionally poor prognosis. Together, our work provides a comprehensive map of SCLC tumor and TME ecosystems, emphasizing their pivotal role in SCLCs adaptable nature, opening possibilities for re-programming the intercellular communications that shape SCLC tumor states.',
  },
];
export default sampleData;
