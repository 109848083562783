import { createAction, createReducer } from '@reduxjs/toolkit';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import apiCall from '../../../../utils';

// check status

const DR_CHECK_STATUS = 'DR_CHECK_STATUS';
const DR_CHECK_STATUS_SUCCESS = 'DR_CHECK_STATUS_SUCCESS';
const DR_CHECK_STATUS_FAILURE = 'DR_CHECK_STATUS_FAILURE';
const DR_CHECK_STATUS_RESET = 'DR_CHECK_STATUS_RESET';

export const drCheckStatusAction = createAction(DR_CHECK_STATUS);
export const drCheckStatusResetAction = createAction(DR_CHECK_STATUS_RESET);

const drCheckStatussSuccess = createAction(DR_CHECK_STATUS_SUCCESS);
const drCheckStatusFailure = createAction(DR_CHECK_STATUS_FAILURE);

export const drCheckStatusEpic = (actions$) => actions$.pipe(
  ofType(DR_CHECK_STATUS),
  switchMap(() => ajax(apiCall(`${process.env.apiUrl_dr}/getAllIndications`, 'get')).pipe(
    map((res) => drCheckStatussSuccess(res)),
    catchError((err) => of(drCheckStatusFailure(err))),
  )),
);

export const drCheckStatusReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder.addCase(drCheckStatusAction, (state) => {
      state.loading = true;
    })
      .addCase(drCheckStatussSuccess, (state, action) => {
        state.data = [...action.payload.response.data];
        state.loading = false;
        state.flag = true;
      })
      .addCase(drCheckStatusFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addCase(drCheckStatusResetAction, (state) => {
        state.data = [];
        state.error = false;
        state.flag = false;
      })
      .addDefaultCase((state) => state);
  },
);

// generate indications

const GENERATE_INDICATIONS_DR = 'GENERATE_INDICATIONS_DR';
const GENERATE_INDICATIONS_SUCCESS_DR = 'GENERATE_INDICATIONS_SUCCESS_DR';
const GENERATE_INDICATIONS_FAILURE_DR = 'GENERATE_INDICATIONS_FAILURE_DR';

export const generateIndicationsDRAction = createAction(GENERATE_INDICATIONS_DR);

const generateIndicationsDRSuccess = createAction(GENERATE_INDICATIONS_SUCCESS_DR);

const generateIndicationsDRFailure = createAction(GENERATE_INDICATIONS_FAILURE_DR);
export const generateIndicationsDREpic = (actions$) => actions$.pipe(
  ofType(GENERATE_INDICATIONS_DR),
  switchMap((action) => ajax(apiCall(`${process.env.apiUrl_dr}/generateIndications?${action.payload}`, 'POST')).pipe(
    map((res) => generateIndicationsDRSuccess(res)),
    catchError((err) => of(generateIndicationsDRFailure(err))),
  )),
);

export const generateIndicationsDRReducer = createReducer(
  {
    data: {},
    loading: false,
    error: false,
    flag: false,
  },
  (builder) => {
    builder
      .addCase(generateIndicationsDRAction, (state) => {
        state.loading = true;
      })
      .addCase(generateIndicationsDRSuccess, (state, action) => {
        state.data = { ...action.payload.response.data };
        state.loading = false;
        state.flag = true;
      })
      .addCase(generateIndicationsDRFailure, (state) => {
        state.error = true;
        state.flag = false;
        state.showNotification = true;
      })
      .addDefaultCase((state) => state);
  },
);
