import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RadarChartSAR from '../RadarChartSAR';
import ParameterSelectionView from '../ParameterSelectionView';

function ParameterGraphicalViewContanier({ setIsCompareView, admetData, comparatorData }) {
  const [defaultLabels, setDefaultLabels] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [defaultCompratorValues, setDefaulCompratorValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (admetData) {
      setDefaultLabels(['Log P', 'CYP3A4 Inhibition', 'HIA', 'BBB', 'hERG Block']);
      setDefaultValues([admetData?.logP, admetData?.CYP3A4_Veith, admetData?.HIA_Hou, admetData?.BBB_Martins, admetData?.hERG]);
    }
  }, [admetData]);

  useEffect(() => {
    if (comparatorData) {
      setDefaulCompratorValues([comparatorData?.logP, comparatorData?.CYP3A4_Veith, comparatorData?.HIA_Hou, comparatorData?.BBB_Martins, comparatorData?.hERG]);
    }
  }, [comparatorData]);
  return (
    <div className="parameter-graphical-content">
      <div className="parameter-graphical-header">
        <div className="properties-header container-layout-card-title">Parameter Graphical View</div>
        <div className="edit-compare-btns">
          <div className="edit-molecule-btn" onClick={() => setIsOpen(true)} aria-hidden />
          <div className="compare-molecule-btn" onClick={() => setIsCompareView(true)} aria-hidden />
        </div>
      </div>
      {!isOpen && (
        <RadarChartSAR
          labelsList={defaultLabels}
          valuesList={defaultValues}
          parametersList={[
            { key: 'Log P', value: admetData?.logP },
            { key: 'CYP3A4 Inhibition', value: admetData?.CYP3A4_Veith },
            { key: 'HIA', value: admetData?.HIA_Hou },
            { key: 'BBB', value: admetData?.BBB_Martins },
            { key: 'hERG Block', value: admetData?.hERG },
          ]}
          comparatorValuesList={defaultCompratorValues}
        />
      )}
      <ParameterSelectionView isOpen={isOpen} defaultLabels={defaultLabels} defaultValues={defaultValues} setDefaultLabels={setDefaultLabels} setDefaultValues={setDefaultValues} setIsOpen={setIsOpen} admetData={admetData} defaultComparatorValues={defaultCompratorValues} setDefaultComparatorValues={setDefaulCompratorValues} comparatorData={comparatorData} />
    </div>
  );
}

ParameterGraphicalViewContanier.propTypes = {
  setIsCompareView: PropTypes.func.isRequired,
  admetData: PropTypes.objectOf.isRequired,
  comparatorData: PropTypes.objectOf.isRequired,
};

export default ParameterGraphicalViewContanier;
