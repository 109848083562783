/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, LabelList, CartesianGrid, Legend } from 'recharts';
import Loader from '../../../../../common/components/Loader';
import { useDispatch } from 'react-redux';
import { ctpPhaseDistributionAction } from './logic';
import useAppSelector from '../../../../../utils/redux-hook';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { XFilled } from '@ant-design/icons';

function BarGraph({ indicationName }) {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const ctpPhaseDistributionResponse = useAppSelector((state) => state.ctpPhaseDistributionResponse);

  useEffect(() => {
    const body = {
      indication_name: indicationName,
    };
    dispatch(ctpPhaseDistributionAction(queryString.stringify(body)));
  }, []);

  useEffect(() => {
    if (ctpPhaseDistributionResponse.flag) {
      setData(ctpPhaseDistributionResponse.data);
    }
  }, [ctpPhaseDistributionResponse]);

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    const radius = 10;
    return (
      <g>
        {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
        <text x={x + width / 2} y={y - radius} fill="#" textAnchor="middle" dominantBaseline="middle">
          {value}
        </text>
      </g>
    );
  };

  return (
    <Loader loading={ctpPhaseDistributionResponse.loading}>
      <div
        className="bubble-charts-header"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <div className="bubble-charts-title">
          <XFilled color="#608FF0" />
          <div className="bubble-charts-trialtype">Total Trials</div>
        </div>
        <div className="bubble-charts-title">
          <XFilled />
          <div className="bubble-charts-trialtype">Pass Trials</div>
        </div>
        <div className="bubble-charts-title">
          <XFilled />
          <div className="bubble-charts-trialtype">Fail Trials</div>
        </div>
        <div className="bubble-charts-title">
          <XFilled />
          <div className="bubble-charts-trialtype">Inconclusive Trials</div>
        </div>
      </div>
      <BarChart
        width={1000}
        height={300}
        data={data}
        margin={{
          top: 25,
          right: 20,
          left: 20,
          bottom: 5,
        }}
        maxBarSize={20}
        barCategoryGap={70}
      >
        <CartesianGrid strokeDasharray="5 3" vertical={false} />
        <XAxis dataKey="name" spacing={100} />
        <YAxis
          label={{
            value: 'Number of Trials',
            angle: -90,
            position: 'insideLeft',
            dy: 40,
          }}
        />
        {/* <Tooltip  cursor={{ fill: 'transparent' }} /> */}
        {/* <Legend verticalAlign="top" height={40} /> */}
        <Bar name="Total Trials" dataKey="total_trials" fill="#608FF0" minPointSize={10}>
          <LabelList dataKey="total_trials" content={renderCustomizedLabel} />
        </Bar>
        <Bar name="Pass Trials" dataKey="pass_trials" fill="#42CD9B" minPointSize={10}>
          <LabelList dataKey="pass_trials" content={renderCustomizedLabel} />
        </Bar>
        <Bar name="Fail Trials" dataKey="fail_trials" fill="#F56F6F" minPointSize={10}>
          <LabelList dataKey="fail_trials" content={renderCustomizedLabel} />
        </Bar>
        <Bar name="Inconclusive Trials" dataKey="inconclusive_trials" fill="#EED163" minPointSize={10}>
          <LabelList dataKey="inconclusive_trials" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </Loader>
  );
}

BarGraph.propTypes = {
  indicationName: PropTypes.string.isRequired,
};

export default BarGraph;
