import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { generateCTPInputsAction } from './logic';
import useAppSelector from '../../../../utils/redux-hook';
import Loader from '../../../../common/components/Loader';

const examplesMapping = {
  'Lung Cancer': 'lung cancer',
  Asthma: 'asthma',
};

const { Search } = Input;
function InputDropdown({ changeHandler, indicationName, setDefaultIndication }) {
  const [searchText, setSearchText] = useState('');
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const dispatch = useDispatch();
  const generateCTPInputsSuggestionsResponse = useAppSelector((reduxState) => reduxState.generateCTPInputsResponse || {});

  useEffect(() => {
    if (indicationName !== '') {
      const example = examplesMapping[indicationName] || '';
      if (example !== '') {
        indicationName = example;
      }
      setSearchText(indicationName);
      changeHandler(indicationName);
      setDefaultIndication('');
    }
  }, [indicationName]);

  useEffect(() => {
    if (generateCTPInputsSuggestionsResponse.flag) {
      setAutosuggestOptions(generateCTPInputsSuggestionsResponse.data.data.suggestions);
    }
  }, [generateCTPInputsSuggestionsResponse]);

  const onChangeInput = (e) => {
    if (e.target.value.trim().length > 0) {
      setSearchText(e.target.value);
      const request = {
        indication_name: e.target.value,
      };
      if (e.target.value.trim().length > 2) {
        setAutosuggestOptions([]);
        setDisplayDropdown(true);
        dispatch(generateCTPInputsAction(queryString.stringify(request)));
      }
    } else {
      setSearchText('');
      setDisplayDropdown(false);
      changeHandler('');
    }
  };

  const renderAutosuggestOptions = () => {
    const options = [];

    /* eslint-disable */
    for (let i = 0; i < Math.min(10, autosuggestOptions.length); i++) {
      const itm = autosuggestOptions[i]['preferred_name'];
      options.push(
        <div
          onClick={() => {
            changeHandler(itm);
            setSearchText(itm);
            setDisplayDropdown(false);
          }}
        >
          {itm}
        </div>,
      );
    }
    /* eslint-enable */

    return options;
  };

  const noDataFound = () => <div className="no-data-found">No data found</div>;

  return (
    <div className="searchbar-dashboard-content-search joyride-searchBar">
      <Search value={searchText} size="large" maxLength="256" onChange={onChangeInput} enterButton={null} allowClear placeholder="Enter Specific Indication Name" />
      <div className="search-dropdown-result-content">
        {displayDropdown ? (
          <div className="search-dropdown-result">
            <div className="search-dropdown-result-list">
              <Loader loading={generateCTPInputsSuggestionsResponse.loading} error={generateCTPInputsSuggestionsResponse.error}>
                {renderAutosuggestOptions().length === 0 ? noDataFound() : renderAutosuggestOptions()}
              </Loader>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

InputDropdown.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  indicationName: PropTypes.string.isRequired,
  setDefaultIndication: PropTypes.func.isRequired,
};

export default InputDropdown;
